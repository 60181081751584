import { useState, useRef, useEffect, useCallback } from 'react';
import {
  BackNavBlocker,
  FlexBox,
  Svg,
  VideoPlayer,
  parsers,
  useAPI,
  useAudio,
  useStateWrapper,
} from '@weasyo/react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import {
  CloseRounded as CloseIcon,
  PlayArrowRounded as PlayIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { BeReadyTimer, TopButtons, BottomInfos, Menu, Launcher } from './';
import audio from '~/src/assets/audio/generated';

export const Player = ({ onEnd, items, color, back_nav_path }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const query_client = useQueryClient();
  const { API } = useAPI();
  const { sprites: background_audio_sprites, player: background_audio_player } =
    useAudio('common', {
      ...audio.common,
      autoUnlock: false, // Avoid some unexpected loading behaviors
      html5: true, // Required for iOS
      identifier: 'common',
    });

  const {
    sprites: transition_audio_sprites,
    player: transitions_audio_player,
  } = useAudio(`workout_${i18n.resolvedLanguage}_transitions`, {
    ...audio[i18n.resolvedLanguage],
    autoUnlock: false, // Avoid some unexpected loading behaviors
    html5: true, // Required for iOS
  });
  const [
    {
      is_explain_video_on,
      is_first_video_loaded,
      is_initial_play_triggered,
      is_menu_open,
      is_pause_on,
      is_be_ready_timer_on,
      is_video_can_be_played,
      item_index,
      loop_index,
      player,
      duration,
      exercise_video_id,
    },
    setState,
  ] = useStateWrapper(
    useState({
      is_explain_video_on: false,
      is_first_video_loaded: false,
      is_initial_play_triggered: false,
      is_menu_open: false,
      is_pause_on: false,
      is_be_ready_timer_on: true,
      is_video_can_be_played: false,
      item_index: 0,
      loop_index: 0,
      duration: items[0].duration,
    }),
    ['player']
  );

  const initial_play_ref = useRef();
  const loops = items[item_index].exercise.loops;
  const is_player_ready = player && is_initial_play_triggered;

  const handleOnQuit = () => navigate(-1);

  const handleOnCloseExplainVideo = () =>
    setState({
      is_menu_open: true,
      is_explain_video_on: false,
      is_pause_on: true,
    });

  const getVideoUrl = ({ queryKey: [, { id }] }) =>
    API.get({ path: 'media/${id}', data: { id } }).then(({ data }) => data.url);

  /**
   * Fetch "loop" video url.
   */
  const { data: exercise_video_url } = useQuery(
    ['media', { id: exercise_video_id }],
    getVideoUrl,
    { enabled: !!exercise_video_id, staleTime: 50 * 60 * 1000 }
  );

  /**
   * Fetch "explain" video url.
   */
  const { data: explain_video_url } = useQuery(
    [
      'media',
      {
        id: is_explain_video_on
          ? parsers.getId(items[item_index].exercise.video, true)
          : null,
      },
    ],
    getVideoUrl,
    { enabled: is_explain_video_on, staleTime: 50 * 60 * 1000 }
  );

  /**
   * Change item.
   */
  const setPlayedItem = useCallback(
    (delta) => {
      const index = item_index + delta;
      const is_limit_reached = index == items.length || index < 0;

      setState({
        is_pause_on: false,
        is_video_can_be_played: false,
        ...(!is_limit_reached ? { item_index: index, loop_index: 0 } : {}),
      });

      return !is_limit_reached;
    },
    [item_index]
  );

  /**
   * Change "item" and avoid unwanted sounds repetition triggering .
   */
  const handleOnArrowClick = (delta) => () => {
    transitions_audio_player.mute(true, 'be_ready_sentence');
    setPlayedItem(delta);
  };

  /**
   * Focus the first play button.
   */
  useEffect(
    () => initial_play_ref.current?.focus(),
    [initial_play_ref.current]
  );

  /**
   * Load video.
   */
  useEffect(() => {
    if (!exercise_video_url) return;

    player.src(exercise_video_url);
    player.load();

    if (is_first_video_loaded && is_player_ready && !is_pause_on) {
      player.play();
    }
  }, [item_index, exercise_video_url]);

  /**
   * Handle videos chaining logical.
   */
  useEffect(() => {
    if (player && loop_index >= 0 && item_index >= 0) {
      setState({
        exercise_video_id: parsers.getId(loops[loop_index].medium, true),
      });
      player.currentTime(0);
    }

    const next_loops = items[item_index + 1]?.exercise.loops;
    if (next_loops) {
      const next_video_id = parsers.getId(next_loops[0].medium, true);
      if (next_video_id) {
        query_client.prefetchQuery(
          ['media', { id: next_video_id }],
          getVideoUrl,
          { staleTime: 50 * 60 * 1000 }
        );
      }
    }
  }, [loops, loop_index, item_index, !!player]);

  /**
   * Handle "mirror" player effect.
   */
  useEffect(() => {
    if (!is_player_ready) return;

    player.el().querySelector('video').style.transform = items[item_index]
      .is_mirror
      ? 'rotateY(180deg)'
      : 'rotateY(0deg)';
  }, [items[item_index].is_mirror, is_player_ready]);

  /**
   * Handle "be_ready_timer" on each item "switch".
   */
  useEffect(
    () => is_first_video_loaded && setState({ is_be_ready_timer_on: true }),
    [is_first_video_loaded, item_index]
  );

  /**
   * Manages video/sound play/pause.
   */
  useEffect(() => {
    if (!is_player_ready) return;

    if (is_pause_on) {
      player.pause();
      transitions_audio_player.pause();
      background_audio_player.pause();
      return;
    }

    player.play();
    transitions_audio_player.resume();
    background_audio_player.resume();
  }, [is_player_ready, is_pause_on]);

  return (
    <>
      <VideoPlayer
        mode='fullscreen'
        size='cover'
        loop={true}
        muted={true}
        preload='auto'
        onPlayerReady={({ player }) => setState({ player })}
        onWaiting={() => setState({ is_video_can_be_played: false })}
        onPlaying={() => setState({ is_video_can_be_played: true })}
        onCanPlayThrough={() => {
          setState({
            is_pause_on: false,
            is_first_video_loaded: true,
            is_video_can_be_played: true,
          });
        }}
      >
        <BackNavBlocker
          {...(back_nav_path ? { path: back_nav_path } : {})}
          re_apply={!is_explain_video_on}
          onBackNav={() => setState({ is_pause_on: true })}
          mode='confirmation'
          modal={{
            title: t('modals.quit-patient-workout.title'),
            description: t('modals.quit-patient-workout.description'),
            sub_description: t('modals.quit-patient-workout.sub-description'),
            secondary: {
              label: t('modals.quit-patient-workout.secondary.label'),
            },
            primary: {
              label: t('modals.quit-patient-workout.primary.label'),
              onClick: ({ cancel }) => {
                setState({ is_pause_on: false });
                cancel();
              },
            },
          }}
        />

        <FlexBox
          sx={{
            flexDirection: 'row',
            height: 1,
            left: 0,
            position: 'absolute',
            top: 0,
            width: 1,
          }}
        >
          {!is_initial_play_triggered && (
            <FlexBox
              sx={{
                height: 1,
                justifyContent: 'center',
                width: 1,
                backgroundColor: 'dark.main',
              }}
            >
              <IconButton
                ref={initial_play_ref}
                data-purpose='play_workout'
                onClick={() => {
                  transitions_audio_player.load();
                  background_audio_player.load();

                  transitions_audio_player.reset();
                  background_audio_player.reset();

                  background_audio_player.loop('workout_background_music');
                  background_audio_player.play('workout_background_music');

                  player.play();
                  setState({ is_initial_play_triggered: true });
                }}
              >
                <Svg component={PlayIcon} width='4rem' />
              </IconButton>
            </FlexBox>
          )}

          {is_be_ready_timer_on && is_player_ready && is_first_video_loaded && (
            <BeReadyTimer
              key={item_index}
              color={color}
              duration={10}
              pause={!is_video_can_be_played || is_pause_on}
              sx={{ bottom: 0, mb: 2, mr: 2, position: 'absolute', right: 0 }}
              onTimerChange={(elapsed_time) => {
                background_audio_player.play('workout_background_music');

                if (elapsed_time === 0) {
                  transitions_audio_player.stop();
                }

                if (elapsed_time == 1) {
                  if (transition_audio_sprites.be_ready_sentence.mute) {
                    transitions_audio_player.mute(false, 'be_ready_sentence');
                  } else {
                    transitions_audio_player.play('be_ready_sentence');
                  }
                }

                if (elapsed_time == 10) {
                  transitions_audio_player.play('bell_effect');

                  setState({
                    is_be_ready_timer_on: false,
                    duration: items[item_index].duration,
                  });

                  player.currentTime(0);
                }
              }}
            />
          )}

          {is_player_ready && !is_first_video_loaded && (
            <Launcher sx={{ zIndex: 'front_of_content' }} />
          )}

          {is_player_ready && is_first_video_loaded && (
            <>
              {!is_menu_open && (
                <TopButtons
                  onClose={handleOnQuit}
                  onHelp={() =>
                    setState({ is_pause_on: true, is_explain_video_on: true })
                  }
                />
              )}

              {!is_be_ready_timer_on && (
                <BottomInfos
                  sx={{
                    bottom: 0,
                    left: 0,
                    p: 2,
                    position: 'absolute',
                    width: 1,
                  }}
                  color={color}
                  advise={loops[loop_index].advise}
                  duration={duration}
                  item_index={item_index}
                  items={items}
                  pause={!is_video_can_be_played || is_pause_on}
                  onTimerChange={(remaining_time) => {
                    if (remaining_time == -1) {
                      if (!setPlayedItem(1)) {
                        player.pause();
                        onEnd();
                      }

                      return;
                    }

                    if (remaining_time == 3) {
                      transitions_audio_player.play('countdown_sentence');
                      return;
                    }
                  }}
                />
              )}

              <Menu
                open={is_menu_open}
                capsule_content={`${item_index + 1}/${items.length}`}
                is_pause_on={is_pause_on}
                is_sound_on={
                  !background_audio_sprites.workout_background_music?.mute
                }
                onToggleOpen={(is_open) => setState({ is_menu_open: is_open })}
                onPrevious={item_index > 0 ? handleOnArrowClick(-1) : null}
                onNext={
                  item_index < items.length - 1 ? handleOnArrowClick(1) : null
                }
                onTogglePlay={() =>
                  setState(({ is_pause_on }) => ({ is_pause_on: !is_pause_on }))
                }
                onToggleSoundOn={({ is_sound_on }) =>
                  background_audio_player.mute(
                    is_sound_on,
                    'workout_background_music'
                  )
                }
                onHelp={() =>
                  setState({ is_pause_on: true, is_explain_video_on: true })
                }
                onQuit={handleOnQuit}
              />
            </>
          )}
        </FlexBox>
      </VideoPlayer>

      {/**
       * TODO may be hiding  video player (CSS) it is more performant than recreating it.
       * TODO require programmatically logical.
       **/}
      {is_explain_video_on && explain_video_url && (
        <VideoPlayer
          autoplay
          controls
          mode='fullscreen'
          sources={[explain_video_url]}
          onEnded={handleOnCloseExplainVideo}
        >
          <BackNavBlocker
            mode='disabled'
            onBackNav={handleOnCloseExplainVideo}
          />

          <FlexBox
            color='white'
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 'front_of_content',
            }}
          >
            <IconButton
              aria-label='close'
              color='inherit'
              data-purpose='close_explain_video'
              onClick={handleOnCloseExplainVideo}
            >
              <Svg component={CloseIcon} fontSize='large' />
            </IconButton>
          </FlexBox>
        </VideoPlayer>
      )}
    </>
  );
};

export default Player;
