import { Router } from '~/src/components';
import AppStoreRedirect from './AppStoreRedirect';
import GooglePlayRedirect from './GooglePlayRedirect';

export const Routes = () => (
  <Router>
    <AppStoreRedirect path='install/ios' />
    <GooglePlayRedirect path='install/android' />
  </Router>
);

export default Routes;
