import { useMemo, memo } from 'react';
import Box from '@mui/material/Box';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FlexBox, Typography, formatters, useAPI } from '@weasyo/react';
import { useInView } from 'react-intersection-observer';

import { ProgramCard } from '~/src/components';

/**
 * Define equality criteria between 2 refs.
 */
const isMemoizable = (prev, next) =>
  prev.program?.['@id'] == next.program?.['@id'];

/**
 * React optimization.
 */
const MemoizedProgramCard = memo(ProgramCard, isMemoizable);

export const ProgramsList = ({ category, sx, ...rest }) => {
  const { t } = useTranslation();
  const { API } = useAPI();
  const { data: programs = [null, null, null] } = useQuery(
    ['programs', { category }],
    ({ queryKey: [, { category }] }) =>
      API.get({
        path: 'programs',
        filters: {
          category,
          itemsPerPage: 12,
          exists: { highlighted: true },
          order: { highlighted: 'ASC' },
        },
      }).then(({ data }) => data['hydra:member']),
    { staleTime: 10 * 60 * 1000, enabled: !!category }
  );

  /**
   * !! This doesn't seems to work...
   * TODO: search a solution to optimize time rendering (avoid blocking render).
   */
  const { ref, inView: is_in_view } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });

  const memoized_programs = useMemo(
    () =>
      programs.map((program, index) => (
        /**
         * animate program list
         */

        <FlexBox
          key={index}
          initial={{ opacity: 0, x: 20, rotateY: 0 }}
          animate={
            is_in_view
              ? { opacity: 1, y: 0 }
              : { opacity: 0, y: 20, rotateY: 50 }
          }
          transition={{
            delay: index * 0.12,
            type: 'spring',
            stiffness: 300,
            damping: 15,
          }}
          sx={{
            mb: 1,
            scrollSnapAlign: index == programs.length - 1 ? 'end' : 'start',
          }}
        >
          <MemoizedProgramCard program={program} />
        </FlexBox>
      )),
    [JSON.stringify(programs.map((data) => data?.['@id'])), is_in_view]
  );

  return (
    <>
      {programs?.length > 0 && (
        <FlexBox
          ref={ref}
          sx={[
            { alignItems: 'flex-start' },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          {...rest}
        >
          <Box sx={{ mb: 2 }}>
            <FlexBox sx={{ mb: 1, alignItems: 'inherit' }}>
              <Typography
                size={21}
                color={formatters.slugify(category ?? '')}
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  textTransform: 'capitalize',
                }}
                placeholder='10rem'
              >
                {category &&
                  /* i18next-extract-mark-context-next-line ["health", "sport", "well being"] */
                  t('components.program-list.category-label', {
                    context: category,
                  })}
              </Typography>
            </FlexBox>

            <Typography align='left' placeholder='25rem'>
              {category &&
                /* i18next-extract-mark-context-next-line ["health", "sport", "well being"] */
                t('components.program-list.category-description', {
                  context: category,
                })}
            </Typography>
          </Box>

          <FlexBox
            sx={{
              alignItems: 'inherit',
              columnGap: 1,
              flexDirection: 'row',
              overflowX: 'auto',
              overflowY: 'overlay',
              width: 1,
              zIndex: 'front_of_content',
            }}
          >
            {memoized_programs}
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default ProgramsList;
