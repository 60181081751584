import { useCommonState } from '@weasyo/react';
import { t } from 'i18next';
import { Router } from '~/src/components';
import Index from './Index';
import Result from './Result';

/**
 * Routes.
 */
export const Routes = () => {
  useCommonState({
    page_title: t('content.authenticated.zones.routes.title'),
  });

  return (
    <Router>
      <Index id='/' />
      <Index id='/:parent_zone_slug' />
      <Result id='/:parent_zone_slug/:child_zone_slug' />
    </Router>
  );
};

export default Routes;
