import { css } from '@emotion/react';
import { forwardRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Avatar } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import {
  AlternateEmailRounded as AlternateEmailIcon,
  AssignmentIndOutlined as AssignmentIndIcon,
  ContactSupportOutlined as ContactSupportIcon,
  DeleteForeverOutlined as DeleteForeverIcon,
  DescriptionOutlined as DescriptionIcon,
  DirectionsRunRounded as DirectionsRunIcon,
  HealingRounded as HealingIcon,
  LockOutlined as LockIcon,
  MoveToInbox as CommunicationIcon,
  ReportProblemOutlined as ReportProblemIcon,
  Settings as SettingsIcon,
  StarBorderRounded as StarBorderIcon,
  VisibilityOutlined as VisibilityIcon,
} from '@mui/icons-material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CardItem,
  EmailToButton,
  FlexBox,
  Modal,
  Pane,
  PaneItem,
  Svg,
  Thumbnail,
  Typography,
  parsers,
  useAPI,
  useCommonState,
  useCore,
  useDevice,
  useDialog,
  useStateWrapper,
  useTemplate,
  withTemplateCheckpoint,
} from '@weasyo/react';
import { MedalIcon } from '@weasyo/react/lib/images';

import { version as web_app_version } from '~/package.json';
import avatar_cover_url from '~/src/assets/images/pictures/avatar_cover.webp';
import { queries } from '~/src/utilities';
import {
  Navigation,
  ErrorEmailToButton,
  Header as DefaultHeader,
} from '~/src/components';

/**
 * Returns cache refresh {item} & handles cache refresh.
 */
const RefreshCacheItem = ({ open, hide, onClose, children, ...rest }) => {
  const [core, setCore] = useCore();
  const [, setDialog] = useDialog();
  const device = useDevice();
  const query_client = useQueryClient();

  const default_modal_props = {
    title: t('modals.technical-pb.refresh-cache.title'),
    description: t('modals.technical-pb.refresh-cache.description'),
    sub_description: t('modals.technical-pb.refresh-cache.sub-description'),
    secondary: (
      <ErrorEmailToButton
        label={t('emails.to-support.problem.label')}
        color='text.primary'
        fullWidth
        sx={{ p: 2 }}
      />
    ),
    primary: {
      label: t('modals.technical-pb.refresh-cache.primary.label'),
      onClick: () => {
        /**
         * Clears query cache.
         */
        query_client.clear();

        /**
         * Disables Service Worker for session duration.
         */
        setCore({ web_app: { sw: 0 } });
      },
    },
  };

  let modal_initial_props = default_modal_props;

  /**
   * Asks for video stream protocol modification on all browsers except Safari.
   */
  if (!/safari/i.test(device.browser.name)) {
    modal_initial_props = {
      title: t('modals.technical-pb.is-video-stream.title'),
      description: t('modals.technical-pb.is-video-stream.description'),
      sub_description: undefined,
      primary: {
        label: t('modals.technical-pb.is-video-stream.primary.label'),
        onClick: () => {
          setModal({
            title: t(
              'modals.technical-pb.video-stream.protocol-modification.title'
            ),
            description: t(
              'modals.technical-pb.video-stream.protocol-modification.description'
            ),
            sub_description: t(
              'modals.technical-pb.video-stream.protocol-modification.sub-description'
            ),
            secondary: (
              <EmailToButton
                fullWidth
                variant='text'
                sx={{ p: 2 }}
                context={t('emails.to-support.problem-video-stream.context')}
                label={t('emails.to-support.problem-video-stream.label')}
                subject={t('emails.to-support.problem-video-stream.subject')}
                color='text.primary'
              />
            ),
            primary: {
              label: t(
                'modals.technical-pb.video-stream.protocol-modification.primary.label'
              ),
              onClick: () => {
                /**
                 * Clears query cache related to video.
                 */
                query_client.invalidateQueries('video');
                query_client.invalidateQueries('media', {
                  category: 'teaser_weasyo_app_program_video',
                });
                query_client.invalidateQueries('media', {
                  category: 'teaser_weasyo_app_subscription_video',
                });

                /**
                 * Switch video stream protocol.
                 */
                const protocol =
                  core.web_app.video_stream_protocol === 'dash'
                    ? 'hls'
                    : 'dash';

                /**
                 * Apply video stream protocol.
                 */
                setCore({ web_app: { video_stream_protocol: protocol } });

                /**
                 * Open "success" dialog.
                 */
                setDialog({
                  open: true,
                  severity: 'success',
                  duration: 0,
                  message: t(
                    'modals.technical-pb.video-stream.protocol-modified-to.dialog.message',
                    { protocol: protocol.toUpperCase() }
                  ),
                });

                /**
                 * Updates displayed modal.
                 */
                setModal({
                  title: t(
                    'modals.technical-pb.video-stream.protocol-modified-to.title'
                  ),
                  description: t(
                    'modals.technical-pb.video-stream.protocol-modified-to.description',
                    { protocol }
                  ),
                  sub_description: t(
                    'modals.technical-pb.video-stream.protocol-modified-to.sub-description',
                    {
                      protocol: (protocol === 'dash'
                        ? 'hls'
                        : 'dash'
                      ).toUpperCase(),
                    }
                  ),
                  secondary: undefined,
                  // i18next-extract-disable-next-line
                  primary: { label: t('glossary.ok'), onClick: close },
                });
              },
            },
          });
        },
      },
      secondary: {
        label: t('modals.technical-pb.is-video-stream.secondary.label'),
        onClick: () => setModal(default_modal_props),
      },
    };
  }

  const close = () => {
    onClose && onClose();
    setModal({ open: false });
  };

  const [modal, setModal] = useStateWrapper(
    useState({ open: false, onClose: close })
  );

  const onClick = () => setModal({ ...modal_initial_props, open: true });

  useEffect(() => {
    if (!open) return;

    setModal({ open });
  }, [open]);

  return (
    <>
      {!hide && (
        <PaneItem {...rest} onClick={onClick}>
          {children}
        </PaneItem>
      )}

      <Modal {...modal} />
    </>
  );
};

/**
 * Return "account deletion" {item} & handle default email content.
 */
const AskForAccountDeletionItem = ({ children, profile, ...rest }) => {
  const [{ modal }, setState] = useStateWrapper(
    useState({ modal: { open: false } })
  );
  const close = () => setState({ modal: { open: false } });

  const onClick = () => {
    setState({
      modal: {
        open: true,
        title: t('modals.delete-account.title'),
        description: t('modals.delete-account.description'),
        sub_description: t('modals.delete-account.sub-description'),
        secondary: { label: t('modals.delete-account.secondary.label') },
        primary: (
          <EmailToButton
            color='primary'
            cc={t('emails.to-support.user-account-deletion-request.cc')}
            content={t(
              'emails.to-support.user-account-deletion-request.content',
              { email: profile?.email }
            )}
            context={t(
              'emails.to-support.user-account-deletion-request.context'
            )}
            subject={t(
              'emails.to-support.user-account-deletion-request.subject'
            )}
            label={t('emails.to-support.user-account-deletion-request.label')}
            fullWidth
            sx={{ p: 2 }}
            variant='text'
          />
        ),
        onClose: close,
      },
    });
  };

  return (
    <>
      <Modal {...modal} />

      <PaneItem {...rest} onClick={onClick}>
        {children}
      </PaneItem>
    </>
  );
};

/**
 * Return "contact support" {item}.
 */
const ConcatSupportItem = ({ children, ...rest }) => {
  const [{ modal }, setState] = useStateWrapper(
    useState({ modal: { open: false } })
  );
  const close = () => setState({ modal: { open: false } });

  const onClick = () => {
    setState({
      modal: {
        open: true,
        title: t('modals.contact-support.title'),
        description: t('modals.contact-support.description'),
        sub_description: t('modals.contact-support.sub-description'),
        secondary: { label: t('modals.contact-support.secondary.label') },
        primary: (
          <EmailToButton
            fullWidth
            sx={{ p: 2 }}
            color='primary'
            variant='text'
            context={t('emails.to-support.contact.context')}
            subject={t('emails.to-support.contact.subject')}
            label={t('emails.to-support.contact.label')}
          />
        ),
        onClose: close,
      },
    });
  };

  return (
    <>
      <Modal {...modal} />

      <PaneItem {...rest} onClick={onClick}>
        {children}
      </PaneItem>
    </>
  );
};

const computeAge = (date) => {
  const diff = Date.now() - new Date(date).getTime();
  const ageDate = new Date(diff);
  return `${Math.abs(ageDate.getUTCFullYear() - 1970)} ans`;
};

const Header = ({ profile }) => {
  const [core] = useCore();
  const location = useLocation();
  const navigate = useNavigate();
  const [open_pane, setOpenPane] = useState(
    location.pathname.search(/settings(\/)?$/) != -1
  );
  const date_birth = profile?.date_birth
    ? computeAge(profile.date_birth)
    : null;

  /**
   * Pane items.
   */
  let pane_lists_items = {
    [t('content.authenticated.profile.show.settings.profile.label')]: {
      'edit-profile': {
        label: t(
          'content.authenticated.profile.show.settings.profile.edit-profile'
        ),
        Icon: AssignmentIndIcon,
        link: '/profile/edit',
      },
      'edit-sport-frequence-activity': {
        label: t(
          'content.authenticated.profile.show.settings.profile.edit-sport-frequence-activity'
        ),
        Icon: DirectionsRunIcon,
        link: '/profile/edit/physical-activity',
      },
      'edit-zone-painful': {
        label: t(
          'content.authenticated.profile.show.settings.profile.edit-zone-painful'
        ),
        Icon: HealingIcon,
        link: '/profile/edit/pain-zone',
      },
      'edit-password': {
        label: t(
          'content.authenticated.profile.show.settings.profile.edit-password'
        ),
        Icon: LockIcon,
        link: '/profile/edit/password',
      },
      'edit-email': {
        label: t(
          'content.authenticated.profile.show.settings.profile.edit-email'
        ),
        Icon: AlternateEmailIcon,
        link: '/profile/edit/email',
      },
      'edit-communication-email-acceptance': {
        label: t(
          'content.authenticated.profile.show.settings.profile.edit-communication-email-acceptance'
        ),
        Icon: CommunicationIcon,
        link: '/profile/edit/communication',
      },
      'edit-subscription': {
        label: t(
          'content.authenticated.profile.show.settings.profile.edit-subscription'
        ),
        Icon: StarBorderIcon,
        link: '/profile/subscription',
      },
    },
    [t('content.authenticated.profile.show.settings.help.label')]: {
      'contact-support': {
        label: t(
          'content.authenticated.profile.show.settings.help.contact-support'
        ),
        Icon: ContactSupportIcon,
        Component: ConcatSupportItem,
      },
      'technical-problem': {
        label: t(
          'content.authenticated.profile.show.settings.help.technical-problem'
        ),
        Component: RefreshCacheItem,
        Icon: ReportProblemIcon,
      },
      'term-of-services': {
        label: t(
          'content.authenticated.profile.show.settings.help.term-of-services'
        ),
        Icon: DescriptionIcon,
        target: '_blank',
        link: `${window.location.origin}/${web_app_version}/docs/terms_of_service.pdf`,
      },
      'privacy-policy': {
        label: t(
          'content.authenticated.profile.show.settings.help.privacy-policy'
        ),
        Icon: VisibilityIcon,
        target: '_blank',
        link: `${window.location.origin}/${web_app_version}/docs/privacy_policy.pdf`,
      },
      'account-deletion': {
        label: t(
          'content.authenticated.profile.show.settings.help.account-deletion'
        ),
        Component: AskForAccountDeletionItem,
        Icon: DeleteForeverIcon,
        profile,
      },
    },
    '': {
      logout: {
        label: t('content.authenticated.profile.show.settings.unnamed.logout'),
        Component: ({ children }) => (
          <FlexBox sx={{ mb: 2, width: 1 }}>
            <FlexBox sx={{ pl: 3, width: 1, alignItems: 'flex-start' }}>
              <Typography
                transform='none'
                color='text.secondary'
                variant='body2'
              >
                {
                  // i18next-extract-mark-context-next-line ["with-prefix"]
                  t(
                    'content.authenticated.profile.show.settings.unnamed.version',
                    {
                      context:
                        core.container?.version ?? core.container?.mode
                          ? 'with-prefix'
                          : undefined,
                      prefix: core.container?.version ?? core.container?.mode,
                      version: core.web_app.version,
                    }
                  )
                }
              </Typography>
            </FlexBox>

            <Button variant='text' onClick={() => navigate('/logout')}>
              {children}
            </Button>
          </FlexBox>
        ),
      },
    },
  };

  const pane_props = {
    back_button: true,
    icon: (
      <Avatar sx={{ backgroundColor: 'white.main', boxShadow: 1 }}>
        <Svg color='text.secondary' component={SettingsIcon} />
      </Avatar>
    ),
    lists: pane_lists_items,
    open: open_pane,
    pathname: ['/profile', '/profile/settings'],
    onToggleOpen: () => setOpenPane((is_opened) => !is_opened),
  };

  return (
    <DefaultHeader
      height='14rem'
      title={t('content.authenticated.profile.show.header.scrolled.text')}
      scrolled={{
        text: (
          <Typography sx={{ ml: 2 }}>
            {t('content.authenticated.profile.show.header.scrolled.text')}
          </Typography>
        ),
        extra: (
          <Pane
            {...pane_props}
            icon={<Svg color='text.secondary' component={SettingsIcon} />}
          />
        ),
      }}
      extra={<Pane {...pane_props} />}
    >
      <FlexBox
        sx={{
          borderRadius: 2,
          boxShadow: 1,
          height: '6rem',
          mb: 1,
          p: 0.5,
          width: '6rem',
        }}
        background='white'
      >
        <Thumbnail
          sx={{ borderRadius: 2, height: 1, width: 1 }}
          background='white'
          onClick={() => navigate('/profile/edit')}
          image={{ src: avatar_cover_url }}
        />
      </FlexBox>

      <Typography
        variant='h3'
        sx={{ textAlign: 'center' }}
        gutterBottom
        placeholder='6rem'
      >
        {profile
          ? t(
              'content.authenticated.profile.show.header.content.profile-name',
              { first_name: profile.first_name, last_name: profile.last_name }
            )
          : null}
      </Typography>

      <Typography variant='body2' sx={{ textAlign: 'center' }} gutterBottom>
        {[
          profile?.gender &&
            t('content.authenticated.profile.show.header.content.gender', {
              gender: profile.gender,
            }),
          date_birth,
        ]
          .filter((x) => x)
          .join(' | ')}
      </Typography>
    </DefaultHeader>
  );
};

const PersonalInfo = ({ label, value, onClick, sx, ...rest }) => (
  <CardItem
    arrow='flex-end'
    border={0.75}
    color='lightGrey'
    onClick={onClick}
    sx={[
      { borderRadius: 2, width: 1, height: '5rem' },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
  >
    <FlexBox
      sx={{
        alignItems: 'flex-start',
        flexDirection: 'row',
        height: 1,
        justifyContent: 'space-between',
        p: 2,
        width: 1,
      }}
      color='text.primary'
    >
      <Typography variant='h5' sx={{ textAlign: 'left' }}>
        {label}
      </Typography>

      <FlexBox
        sx={{
          alignItems: 'flex-end',
          height: 1,
          justifyContent: 'flex-end',
          minWidth: '50%',
        }}
      >
        <Typography
          variant='h3'
          placeholder={'3rem'}
          sx={{ textAlign: 'right' }}
        >
          {value}
        </Typography>
      </FlexBox>
    </FlexBox>
  </CardItem>
);

const Score =
  // eslint-disable-next-line react/display-name
  forwardRef(({ count, label, color }, ref) => (
    <FlexBox ref={ref}>
      <FlexBox
        sx={{
          borderRadius: 100,
          height: '3rem',
          justifyContent: 'center',
          position: 'relative',
          width: '3rem',
        }}
        background={`${color}.light`}
      >
        <Typography align='center' variant='h5' placeholder='1rem'>
          {count ? count : '-'}
        </Typography>

        <FlexBox position='absolute' left='-1rem' top='1rem'>
          <Svg
            component={MedalIcon}
            height='2rem'
            sx={{
              'path:not(:first-of-type)': {
                fill: (theme) =>
                  parsers.theme(theme).palette.get({ path: color }),
              },
            }}
          />
        </FlexBox>
      </FlexBox>

      <Typography align='center' variant='h5' placeholder='3rem'>
        {label}
      </Typography>
    </FlexBox>
  ));

/**
 * No-dynamic variable for motion properties
 */
const variants_score_parent = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0,
      staggerDirection: 1,
      when: 'beforeChildren',
      staggerChildren: 0.12,
    },
  },
};
const variants_score = { hidden: { y: 50 }, show: { y: 0 } };

export const Show = () => {
  const [
    { pending_first_enabling_confirmation_message_displayed },
    setCommonState,
  ] = useCommonState();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { API } = useAPI();
  const setTemplate = useTemplate();
  const [, setDialog] = useDialog();
  const [mapped_questions_responses, setState] = useState();

  /**
   * Fetch {profile}.
   */
  const { data: profile } = useQuery(['profile'], () =>
    API.get({ path: 'profile' }).then(({ data }) => data)
  );

  /**
   * Fetch {questions_responses}.
   */
  const { data: questions_responses } = useQuery(
    ['questions_responses', { 'question.item_type': 'onboarding' }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions_responses', filters }).then(
        ({ data }) => data['hydra:member']
      )
  );

  const { data: zone } = useQuery(
    ['zones', { slug: mapped_questions_responses?.['zone-painful'] }],
    ({ queryKey: [, filters] }) =>
      mapped_questions_responses['zone-painful']
        ? API.get({ path: 'zones', filters }).then(
            ({ data }) => data['hydra:member'][0] ?? null
          )
        : null,
    { enabled: !!mapped_questions_responses }
  );

  /**
   * Fetch {nb_programs_in_progress}.
   */
  const { data: nb_programs_in_progress } = useQuery(
    [
      queries.names.patient_programs,
      { itemsPerPage: 0, status: 'in progress' },
    ],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'patient/programs', filters }).then(
        ({ data }) => data['hydra:totalItems']
      )
  );

  /**
   * Fetch {nb_programs_completed_health}.
   */
  const { data: nb_programs_completed_health } = useQuery(
    [
      queries.names.patient_programs,
      { itemsPerPage: 0, status: 'completed', 'program.category': 'health' },
    ],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'patient/programs', filters }).then(
        ({ data }) => data['hydra:totalItems']
      )
  );

  /**
   * Fetch {nb_programs_completed_sport}.
   */
  const { data: nb_programs_completed_sport } = useQuery(
    [
      queries.names.patient_programs,
      { itemsPerPage: 0, status: 'completed', 'program.category': 'sport' },
    ],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'patient/programs', filters }).then(
        ({ data }) => data['hydra:totalItems']
      )
  );

  /**
   * Fetch {nb_programs_completed_well_being}.
   */
  const { data: nb_programs_completed_well_being } = useQuery(
    [
      queries.names.patient_programs,
      {
        itemsPerPage: 0,
        status: 'completed',
        'program.category': 'well being',
      },
    ],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'patient/programs', filters }).then(
        ({ data }) => data['hydra:totalItems']
      )
  );

  /**
   * Map each response to the question slug.
   */
  useEffect(() => {
    if (!questions_responses) return;
    let mapped_values = {};
    questions_responses.map((question_response) => {
      let value = {};
      value[question_response.question.slug] = question_response.response;

      mapped_values = { ...mapped_values, ...value };
    });

    setState(mapped_values);
  }, [questions_responses]);

  /**
   * Update {header}.
   */
  useEffect(() => {
    if (!profile) return;

    setTemplate({ header: <Header profile={profile} /> });
  }, [!!profile]);

  /**
   * Warns for "pending confirmation account".
   */
  useEffect(() => {
    if (
      profile?.status != 'pending_first_enabling_confirmation' ||
      pending_first_enabling_confirmation_message_displayed
    )
      return;

    setCommonState({
      pending_first_enabling_confirmation_message_displayed: true,
    });

    setDialog({
      open: true,
      message: t(
        'content.authenticated.profile.show.dialogs.account-pending-first-enabling-confirmation.message',
        { email: profile.email }
      ),
    });
  }, [profile?.status]);

  return (
    <>
      <Box mb={2} width={1}>
        <Typography
          align='left'
          color='text.secondary'
          transform='uppercase'
          variant='h5'
        >
          {t('content.authenticated.profile.show.resume.label')}
        </Typography>
      </Box>

      <PersonalInfo
        mb={1}
        onClick={() => navigate('/profile/edit/physical-activity')}
        label={t(
          'content.authenticated.profile.show.resume.personal-info.sport-frequence-activity.label'
        )}
        value={
          mapped_questions_responses == undefined
            ? null
            : mapped_questions_responses['sport-frequence-activity']
            ? // i18next-extract-disable
              t(
                `questions.sport-frequence-activity.proposal.${mapped_questions_responses['sport-frequence-activity']}.label`
              )
            : t('glossary.to-complete')
          // i18next-extract-enable
        }
      />

      <PersonalInfo
        mb={1}
        label={t(
          'content.authenticated.profile.show.resume.personal-info.zone.label'
        )}
        onClick={() => navigate('/profile/edit/pain-zone')}
        value={
          zone === undefined
            ? null
            : zone
            ? zone.translations.find(
                (tr) => tr.language.code_alpha_2 === i18n.resolvedLanguage
              )?.name
            : t(
                'content.authenticated.profile.show.resume.personal-info.zone.none'
              )
        }
      />

      <Box mb={3} width={1}>
        <Typography variant='caption' component='div' align='left'>
          {t('content.authenticated.profile.show.resume.user-data-purpose')}
        </Typography>
      </Box>

      <Box mb={2} width={1}>
        <Typography
          align='left'
          color='text.secondary'
          transform='uppercase'
          variant='h5'
        >
          {t('content.authenticated.profile.show.stats.label')}
        </Typography>
      </Box>

      <PersonalInfo
        label={t(
          'content.authenticated.profile.show.stats.personal-info.programs-in-progress.label'
        )}
        mb={1}
        onClick={() => navigate('/my-programs')}
        value={
          nb_programs_in_progress === 0
            ? t(
                'content.authenticated.profile.show.stats.personal-info.programs-in-progress.none'
              )
            : nb_programs_in_progress
        }
      />

      <FlexBox
        variants={variants_score_parent}
        initial='hidden'
        animate={
          (nb_programs_completed_health &&
            nb_programs_completed_sport &&
            nb_programs_completed_well_being) !== undefined
            ? 'show'
            : 'hidden'
        }
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          mb: 2,
          width: 1,
        }}
      >
        <Score
          variants={variants_score}
          color='health'
          count={nb_programs_completed_health}
          // i18next-extract-disable
          label={t(
            'content.authenticated.profile.show.stats.score.nb-programs-completed',
            { count: nb_programs_completed_health, context: 'health' }
          )}
        />

        <Score
          variants={variants_score}
          color='sport'
          count={nb_programs_completed_sport}
          label={t(
            'content.authenticated.profile.show.stats.score.nb-programs-completed',
            { count: nb_programs_completed_sport, context: 'sport' }
          )}
        />

        <Score
          variants={variants_score}
          color='well_being'
          count={nb_programs_completed_well_being}
          label={t(
            'content.authenticated.profile.show.stats.score.nb-programs-completed',
            {
              count: nb_programs_completed_well_being,
              context: 'well being',
            }
          )}
          // i18next-extract-enable
        />
      </FlexBox>
    </>
  );
};

export default withTemplateCheckpoint(
  Show,
  { inheritance: 'default', id: () => window.location.pathname },
  {
    header: <Header />,
    footer: <Navigation />,
    content_style: (theme) => css`
      justify-content: start;
      padding-top: ${theme.spacing(2)};
      box-shadow: ${theme.shadows[5]};
      border-radius: ${theme.shape.borderRadius * 2}px
        ${theme.shape.borderRadius * 2}px 0 0;
    `,
    page_style: (theme) => css`
      background-color: ${theme.palette.primary.light};
    `,
  }
);
