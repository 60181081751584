import { Typography, InputGuesser, CheckableInput } from '@weasyo/react';
import { t } from 'i18next';

import { QuestionTemplate } from './';

export const RadioButtonsListQuestion = ({ question, value, onChange }) => (
  <QuestionTemplate
    content={
      <Typography variant='h2' sx={{ fontAlign: 'center' }}>
        {/* i18next-extract-disable-next-line */}
        {question ? t(`questions.${question.slug}.question`) : null}
      </Typography>
    }
    form={
      <InputGuesser
        data={question}
        value={value}
        onChange={onChange}
        placeholder={[null, null, null].map((option, index) => (
          <CheckableInput key={index} option={option} />
        ))}
      />
    }
  />
);

export default RadioButtonsListQuestion;
