import { FlexBox } from '@weasyo/react';

export const QuestionTemplate = ({ content, form }) => (
  <>
    <FlexBox
      sx={{
        flexGrow: 1,
        width: 1,
        justifyContent: 'flex-end',
        maxWidth: '27rem',
      }}
    >
      {content}
    </FlexBox>

    <FlexBox
      sx={{
        flexGrow: 1,
        justifyContent: 'center',
        maxWidth: '23rem',
        rowGap: 2,
        width: 1,
      }}
    >
      {form}
    </FlexBox>
  </>
);

export default QuestionTemplate;
