import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { t } from 'i18next';
import {
  BackNavBlocker,
  Button,
  parsers,
  useAPI,
  useDialog,
  useStateWrapper,
  withTemplateCheckpoint,
} from '@weasyo/react';

import { BodySelect, Header as DefaultHeader } from '~/src/components';

const PainZone = () => {
  const navigate = useNavigate();
  const [, setDialog] = useDialog();
  const query_client = useQueryClient();
  const [
    { is_submitted, question, selection, selected_parent_zone },
    setState,
  ] = useStateWrapper(useState({}));
  const { API } = useAPI();

  /**
   * Fetch onboarding questions.
   */
  const { data: questions } = useQuery(
    ['questions', { 'question.item_type': 'onboarding' }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions', filters }).then(
        ({ data }) => data?.['hydra:member']
      )
  );

  /**
   * Fetch onboarding questions responses.
   */
  const { data: questions_responses } = useQuery(
    ['questions_responses', { 'question.item_type': 'onboarding' }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions_responses', filters }).then(
        ({ data }) => data?.['hydra:member']
      )
  );

  /**
   * Define current selection.
   */
  useEffect(() => {
    if (!questions_responses) return;
    questions_responses.map((question_response) => {
      if (question_response.question.slug != 'zone-painful') return;

      setState({ selection: question_response.response });
    });
  }, [questions_responses]);

  /**
   * Define question.
   */
  useEffect(() => {
    if (!questions) return;

    questions.map((question) => {
      if (question.slug != 'zone-painful') return;

      setState({ question: question });
    });
  }, [questions]);

  /**
   * Update response.
   */
  useEffect(() => {
    if (!is_submitted || !question) return;

    API.post({
      path: 'questions_responses',
      data: {
        question: parsers.getId(question),
        response: selection ? selection['slug'] : 'none',
      },
    })
      .then(() => {
        setDialog({
          severity: 'success',
          open: true,
          message: t(
            'content.authenticated.profile.edit.pain-zone.dialogs.success.message'
          ),
        });
        navigate('/profile');
      })
      .then(() => query_client.invalidateQueries('questions_responses'));
  }, [is_submitted, selection, question]);

  const onChange = (selection) => setState({ selection, is_submitted: true });

  return (
    <>
      {selected_parent_zone && (
        <BackNavBlocker
          mode='disabled'
          onBackNav={() => setState({ selected_parent_zone: undefined })}
        />
      )}

      <BodySelect
        selected_parent_zone={selected_parent_zone}
        onZoneChange={onChange}
        onParentZoneChange={(zone) =>
          setState({ selected_parent_zone: zone.slug })
        }
      />

      {!selected_parent_zone && (
        <Button
          sx={{ my: 2 }}
          data-purpose='select_no_one_pain'
          onClick={() => onChange(null)}
          label={t(
            'content.authenticated.profile.edit.pain-zone.buttons.no-one.label'
          )}
        />
      )}
    </>
  );
};

const Header = () => (
  <DefaultHeader
    back_button
    overtitle={t(
      'content.authenticated.profile.edit.pain-zone.header.overtitle'
    )}
    title={t('content.authenticated.profile.edit.pain-zone.header.title')}
    subtitle={t('content.authenticated.profile.edit.pain-zone.header.subtitle')}
    scrolled={{
      text: t(
        'content.authenticated.profile.edit.pain-zone.header.scrolled.text'
      ),
    }}
  />
);

export default withTemplateCheckpoint(
  PainZone,
  { inheritance: 'default' },
  {
    header: <Header />,
    page_style: (theme) => css`
      background-color: ${theme.palette.health.light};
    `,
    content_style: (theme) => css`
      background-color: ${theme.palette.health.light};
    `,
  }
);
