import { css } from '@emotion/react';
import { Avatar, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { withTemplateCheckpoint, Svg } from '@weasyo/react';

import { Header, QuarterCircleIllustration, Router } from '~/src/components';

import Program from './Program';
import PatientProgram from './PatientProgram';
import PatientPrograms from './PatientPrograms';
import PatientWorkout from './PatientWorkout';
import WorkoutPlayer from './workout_player/Index';

export const TunnelRoutes = () => (
  <Router>
    <Program path='/programs/:program_id' />
    <PatientProgram path='/my-programs/:patient_program_id' />
    <PatientProgram path='/my-programs/:patient_program_id/email/:email' />
    <PatientWorkout path='/my-programs/:patient_program_id/steps/:patient_program_step_id/workouts/:patient_workout_id' />
  </Router>
);

/**
 * Share template.
 */
const TunnelRoutesWithTemplate = withTemplateCheckpoint(
  TunnelRoutes,
  { inheritance: 'default', id: 'patient-program' },
  {
    cover: {
      height: '27rem',
      background: 'lightGrey',
      position: 'relative',
      children: <QuarterCircleIllustration color='midGrey' size={1.15} />,
      layer:
        'linear-gradient(180deg, rgba(0,0,0,0.19681375968356096) 0%, rgba(0,0,0,0.04555325548188027) 15%, rgba(0,0,0,0) 35%, rgba(0,0,0,0) 100%)',
    },
    header: (
      <Header
        back_button
        title={null}
        sx={{ position: 'absolute', top: 0 }}
        color='white'
        extra={
          <IconButton color='inherit'>
            <Avatar sx={{ backgroundColor: 'white.main', boxShadow: 1 }}>
              <Svg color='text.secondary' component={ShareIcon} />
            </Avatar>
          </IconButton>
        }
      />
    ),
    content_style: css`
      justify-content: flex-start;
      padding: 0;
    `,
  }
);

/**
 * Routes.
 */
export const Routes = () => (
  <Router>
    <PatientPrograms path='/my-programs/' />
    <PatientPrograms path='/my-programs/lists/:status' />
    <WorkoutPlayer path='/my-programs/:patient_program_id/steps/:patient_program_step_id/workouts/:patient_workout_id/video' />
    <TunnelRoutesWithTemplate path='*' />
  </Router>
);

export default Routes;
