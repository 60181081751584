import { css } from '@emotion/react';
import { useState, useEffect, forwardRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Avatar, Box } from '@mui/material';
import {
  Share as ShareIcon,
  Done as DoneIcon,
  PlayCircleFilledRounded as PlayIcon,
} from '@mui/icons-material';
import { useQueryClient, useQuery } from 'react-query';
import { t } from 'i18next';
import ReactPlaceholder from 'react-placeholder';
import {
  Button,
  FlexBox,
  Svg,
  Typography,
  VideoPlayer,
  formatters,
  parsers,
  useAPI,
  useCommonState,
  useStateWrapper,
  useTemplate,
  Duration,
} from '@weasyo/react';

import {
  Block,
  CTAButton,
  Capsule,
  Header,
  ProgramStepsList,
  QuarterCircleIllustration,
  ResumeItem,
  Share,
} from '~/src/components';
import program_player_teaser_poster_url from '~/src/assets/images/pictures/program_player_teaser_poster.webp';
import { getRandomSentence, queries } from '~/src/utilities';
import { version as web_app_version } from '~/package.json';

const ellipsis_style = css`
  display: block;
  position: absolute;
  height: 100%;
  background: linear-gradient(0deg, white, rgba(255, 255, 255, 0));
  width: 100%;
  top: 0;
  left: 0;
`;

const GoToPatientProgramButton =
  // eslint-disable-next-line react/display-name
  forwardRef(({ program, patient_program, ...rest }, ref) => {
    const navigate = useNavigate();
    const { API } = useAPI();
    const is_restricted = program?.is_restricted;
    const query_client = useQueryClient();

    const onClickHandler = () => {
      if (patient_program) {
        navigate(
          is_restricted
            ? '/subscriptions'
            : `/my-programs/${parsers.getId(patient_program, true)}`
        );
        return;
      }

      queries
        .createPatientProgram({ API, program, query_client })
        .then((patient_program) => {
          navigate(
            is_restricted
              ? '/subscriptions'
              : `/my-programs/${parsers.getId(patient_program, true)}`
          );
        });
    };

    return (
      // eslint-disable-next-line no-undef
      <FlexBox
        animate={{ x: [0, -10, 10, -10, 10, 0], transition: { delay: 2 } }}
        ref={ref}
        width={1}
        {...rest}
      >
        <CTAButton
          disabled={patient_program === undefined}
          label={t(
            'content.authenticated.programs.program.go-to-patient-program-button.label.is-in-progress',
            { context: patient_program ? 'true' : 'false' }
          )}
          onClick={onClickHandler}
          data-purpose='navigate'
          data-details={
            is_restricted
              ? 'subscriptions'
              : patient_program
              ? 'new_patient_program'
              : 'patient_program'
          }
        />
      </FlexBox>
    );
  });

const Resume = ({ program, patient_program, ...rest }) => {
  const [{ motivation_sentence }, setCommonState] = useCommonState();
  const capsule_content =
    program &&
    program['@id'] === motivation_sentence?.id &&
    motivation_sentence.sentence
      ? motivation_sentence.sentence
      : null;
  const progression = program?.professional
    ? null
    : patient_program
    ? (patient_program.nb_patient_workouts_completed /
        patient_program.nb_patient_workouts) *
      100
    : null;

  useEffect(() => {
    if (
      !program ||
      (motivation_sentence?.id === program['@id'] &&
        motivation_sentence?.progression === progression)
    )
      return;

    const tag = program.tags.find((tag) => tag.type == 'access');
    const sentence = patient_program
      ? getRandomSentence({
          type: formatters.slugify(program.category),
          progression,
          sentences: t('common.motivational-phrases', { returnObjects: true }),
        })
      : tag
      ? // i18next-extract-disable-next-line
        t('tags.program', { context: tag.slug })
      : undefined;

    setCommonState({
      motivation_sentence: { id: program['@id'], sentence, progression },
    });
  }, [program, patient_program]);

  return (
    <ResumeItem program={program} capsule_content={capsule_content} {...rest}>
      <GoToPatientProgramButton
        mt={2}
        program={program}
        patient_program={patient_program}
      />
    </ResumeItem>
  );
};

const MainInfos = ({ program, sx, ...rest }) => (
  <FlexBox
    color='midGrey'
    sx={[
      {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: 1,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
  >
    {[
      {
        label: program
          ? t(
              'content.authenticated.programs.program.main-infos.workouts.label.nb-without-count',
              { count: program?.nb_workouts }
            )
          : null,
        value: program?.nb_workouts,
      },
      {
        label: program
          ? t(
              'content.authenticated.programs.program.main-infos.exercises.label.nb-without-count',
              { count: program?.nb_exercises }
            )
          : null,
        value: program?.nb_exercises,
      },
      {
        label: t(
          'content.authenticated.programs.program.main-infos.duration-per-workout.label'
        ),
        value: (
          <FlexBox sx={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Duration
              data={program?.duration ?? {}}
              components={{
                first_part: (
                  <Typography size={36} weight='900' placeholder='2rem' />
                ),
                unity: (
                  <Typography
                    placeholder='1rem'
                    transform='none'
                    variant='body2'
                    weight='bold'
                  />
                ),
              }}
              is_abbreviation
            />
          </FlexBox>
        ),
      },
    ].map(
      (data, index) =>
        (!program || data.value !== 0) && (
          <Block
            key={index}
            color={program ? formatters.slugify(program.category) : 'midGrey'}
            value={program ? data.value : null}
            label={program ? data.label : null}
          />
        )
    )}
  </FlexBox>
);

const Goals = ({ program, sx, ...rest }) =>
  (!program || program.goals.length > 0) && (
    <FlexBox
      sx={[
        { alignItems: 'inherit', width: 1 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant='h3' color='midGrey' sx={{ textAlign: 'left' }}>
          {t('content.authenticated.programs.program.goals.first-text')}
        </Typography>
      </Box>

      <Capsule
        color={program ? formatters.slugify(program.category) : 'midGrey'}
        sx={{ alignItems: 'inherit', borderRadius: 2, px: 2, py: 3 }}
      >
        <FlexBox>
          {(program ? program?.goals : [{}, {}, {}])?.map(({ item }, index) => (
            <FlexBox key={index} sx={{ flexDirection: 'row', width: 1 }}>
              <DoneIcon fontSize='small' />

              <FlexBox sx={{ ml: 1 }}>
                <Typography
                  variant='body2'
                  color='text.primary'
                  placeholder={'15em'}
                  sx={{ textAlign: 'left', fontWeight: 'bold' }}
                >
                  {item}
                </Typography>
              </FlexBox>
            </FlexBox>
          ))}
        </FlexBox>
      </Capsule>
    </FlexBox>
  );

const Description = ({ program, sx, ...rest }) => {
  const [show_description, showDescription] = useState(false);

  return (
    <FlexBox
      sx={[
        { alignItems: 'inherit', width: 1 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant='h3' color='midGrey' sx={{ textAlign: 'left' }}>
          {t('content.authenticated.programs.program.description.first-text')}
        </Typography>
      </Box>

      <FlexBox sx={{ alignItems: 'inherit', position: 'relative', width: 1 }}>
        <ReactPlaceholder
          firstLaunchOnly={true}
          type='text'
          ready={!!program}
          rows={4}
          showLoadingAnimation
        >
          {
            <>
              <Typography
                sx={{
                  textAlign: 'justify',
                  position: 'relative',
                  whiteSpace: 'pre-line',
                }}
                onClick={() =>
                  showDescription((show_description) => !show_description)
                }
                trunc={
                  show_description
                    ? false
                    : {
                        lines: 4,
                        ellipsis: <span css={ellipsis_style} />,
                      }
                }
              >
                {program?.description}
              </Typography>

              {show_description && (
                <FlexBox
                  color='midGrey'
                  sx={{ width: 1, py: 1, alignItems: 'flex-end' }}
                >
                  <Typography sx={{ textAlign: 'right' }} variant='caption'>
                    <a
                      data-purpose='navigate_to_scientific_sources'
                      href={`${window.location.origin}/${web_app_version}/docs/scientific_sources.pdf`}
                      sx={{ color: 'inherit' }}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t(
                        'content.authenticated.programs.program.description.link-to-sources'
                      )}
                    </a>
                  </Typography>
                </FlexBox>
              )}

              <Button
                variant='link'
                onClick={() =>
                  showDescription((show_description) => !show_description)
                }
              >
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant='captionz'
                  color='midGrey'
                >
                  {t(
                    'content.authenticated.programs.program.description.buttons.toggle.label.is-opened',
                    {
                      context: show_description ? 'true' : 'false',
                    }
                  )}
                </Typography>
              </Button>
            </>
          }
        </ReactPlaceholder>
      </FlexBox>
    </FlexBox>
  );
};

const TeaserPlayer = ({ sx, ...rest }) => {
  const { API } = useAPI();
  const { data: teaser_video_url } = useQuery(
    ['media', { category: 'teaser_weasyo_app_program_video', itemsPerPage: 1 }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'media', filters }).then(({ data }) =>
        data['hydra:member'] ? data['hydra:member'][0].url : null
      )
  );

  return (
    <FlexBox
      sx={[
        { width: 1, alignItems: 'inherit' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Typography
        variant='h3'
        color='midGrey'
        sx={{ mb: 2, textAlign: 'center' }}
      >
        {t('content.authenticated.programs.program.teaser.first-text')}
      </Typography>

      <VideoPlayer
        preload
        controls
        fluid
        big_play_icon={<Svg component={PlayIcon} width='4rem' />}
        poster={program_player_teaser_poster_url}
        sources={teaser_video_url ? [teaser_video_url] : undefined}
        sx={{
          backgroundColor: 'lightGrey.main',
          borderRadius: 2,
          boxShadow: 2,
        }}
      />
    </FlexBox>
  );
};

const Program = () => {
  useCommonState({
    page_title: t('content.authenticated.programs.program.title'),
  });
  const navigate = useNavigate();
  const { program_id } = useParams();
  const query_client = useQueryClient();
  const setTemplate = useTemplate();
  const { API } = useAPI();
  const [{ is_share_clicked }, setState] = useStateWrapper(
    useState({ is_share_clicked: false })
  );

  /**
   * Fetch the {program}.
   */
  const { data: program } = useQuery(
    ['programs', { id: program_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({
        path: 'programs/${id}',
        data: { id },
        onError: ({ error }) => {
          if (error.response.status == 404) {
            navigate('/404_not_found', { replace: true });
            return;
          }
        },
      }).then(({ data }) => data)
  );

  const { data: patient_program } = useQuery(
    [
      queries.names.patient_programs_of,
      { id: program_id, last_in_progress: true },
    ],
    ({ queryKey: [, { id }] }) =>
      API.get({
        path: 'patient/programs',
        filters: { program: id, status: 'in progress' },
      }).then(({ data }) => {
        const patient_program = data['hydra:member'][0] ?? null;

        if (!patient_program) return patient_program;

        queries.setQueriesData({
          API,
          query_client,
          patient_program,
          clear: false,
        });

        queries.preFetchRelatedOf({
          API,
          query_client,
          patient_program,
        });

        return patient_program;
      }),
    { staleTime: 10 * 60 * 1000 }
  );
  const color = program ? formatters.slugify(program.category) : 'midGrey';

  /**
   * Update template {header}.
   */
  useEffect(() => {
    if (!program) return;

    setTemplate(({ cover }) => ({
      cover: {
        ...cover,
        image: { src: program?.cover?.url, require_authentication: true },
        children: <QuarterCircleIllustration size={1.15} color={color} />,
      },
      header: (
        <Header
          back_button
          title={null}
          sx={{ position: 'absolute', top: 0 }}
          color='white'
          border_color={color}
          extra={
            <IconButton
              color='inherit'
              data-purpose='share'
              onClick={() => setState({ is_share_clicked: true })}
            >
              <Avatar sx={{ backgroundColor: 'white.main', boxShadow: 1 }}>
                <Svg color='text.secondary' component={ShareIcon} />
              </Avatar>
            </IconButton>
          }
          scrolled={{
            color: 'text.primary',
            extra: (
              <IconButton
                color='inherit'
                data-purpose='share'
                onClick={() => setState({ is_share_clicked: true })}
              >
                <Svg color='text.secondary' component={ShareIcon} />
              </IconButton>
            ),
            children: <Typography trunc>{program.name}</Typography>,
          }}
        />
      ),
    }));
  }, [program?.['@id']]);

  return (
    <>
      {program && (
        <Share
          url={`${window.location.origin}/programs/${parsers.getId(
            program,
            true
          )}`}
          text={t('content.authenticated.programs.share.program.text')}
          title={t('content.authenticated.programs.share.program.title')}
          onEnd={() => setState({ is_share_clicked: false })}
          open={is_share_clicked}
        />
      )}

      <Resume program={program} patient_program={patient_program} mb={2} />

      <FlexBox sx={{ px: 2, width: 1, alignItems: 'flex-start' }}>
        <MainInfos program={program} sx={{ mb: 3 }} />

        <Goals program={program} sx={{ mb: 3 }} />

        <Description program={program} sx={{ mb: 3 }} />

        <TeaserPlayer sx={{ mb: 3 }} />

        <ProgramStepsList
          program={program}
          patient_program={patient_program}
          detailed
          title={t(
            'content.authenticated.programs.program.program-step-list.first-text'
          )}
          sx={{ mb: 4 }}
        />
      </FlexBox>
    </>
  );
};

export default Program;
