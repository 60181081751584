import { useCommonState } from '@weasyo/react';
import { t } from 'i18next';

import { Router } from '~/src/components';
import Show from './Show';
import Subscription from './subscription/Routes';
import Edit from './edit/Routes';

export const Routes = () => {
  useCommonState({
    page_title: t('content.authenticated.profile.routes.title'),
  });

  return (
    <Router>
      <Subscription id='/subscription/*' />
      <Edit id='edit/*' />
      <Show id='/' />
      <Show id='/:open_settings' />
    </Router>
  );
};

export default Routes;
