import { useURLParams } from '@weasyo/react';

export const GooglePlayRedirect = () => {
  const {
    params: { utm_source, utm_medium, utm_campaign, utm_content },
  } = useURLParams();
  const trackers = { utm_source, utm_medium, utm_campaign, utm_content };
  const url = new URL('https://play.google.com/store/apps/details');
  // eslint-disable-next-line no-undef
  url.searchParams.set('id', process.env.W_APP_ID);

  const referrer = Object.keys(trackers)
    .map((key) => trackers[key] && `${key}=${trackers[key]}`)
    .filter((val) => val)
    .join('&');

  referrer && url.searchParams.set('referrer', referrer);

  window.location.replace(url.toString());

  return null;
};

export default GooglePlayRedirect;
