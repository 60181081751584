import { useAPI } from '@weasyo/react';

import { Router } from '~/src/components';
import PasswordUpdate from './PasswordUpdate';
import EmailUpdate from './EmailUpdate';
import SignUp from './sign-up/Routes';
import { useEffect } from 'react';

export const Routes = () => {
  const { API, authenticated } = useAPI();

  /**
   * Log out authenticated user to avoid confusion.
   */
  useEffect(() => {
    if (!authenticated) return;

    API.post({ path: 'logout', is_token_related: true });
  }, []);

  return (
    <Router>
      <PasswordUpdate path='password/:email/:token' />
      <EmailUpdate path='email/:email/:token/:new_email' />
      <SignUp path='sign-up/*' />
    </Router>
  );
};

export default Routes;
