import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import {
  Button,
  FlexBox,
  PasswordInput,
  Typography,
  parsers,
  useAPI,
  useDialog,
  usePrefill,
} from '@weasyo/react';

import { FlexForm, Terms, MotionTextField } from '~/src/components';
import { Trans } from 'react-i18next';

export const Form = () => {
  const navigate = useNavigate();
  const { set: prefill, get: getPrefill, clear: clearPrefill } = usePrefill();
  const [, setDialog] = useDialog();
  const { API } = useAPI();

  const onSubmit = (data, form) =>
    API.post({
      path: 'anonymous/demands/sign-up',
      is_token_related: true,
      data,
      onError: (api_response) => {
        /**
         * Set form errors messages otherwise keep throwing exception.
         */
        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            formik,
            violations: api_response.error.data?.violations,
            t,
          });

          if (errors.all.new_email?.code == 'email-found') {
            setDialog({
              open: true,
              message: t(
                'content.anonymous.sign-up.dialogs.demand.account-already-created.message',
                { email: data.new_email }
              ),
              label: t(
                'content.anonymous.sign-up.dialogs.demand.account-already-created.label'
              ),
              onClick: () => {
                setDialog({ open: false });

                prefill({
                  identifier: '/login',
                  data: { email: data.new_email },
                });

                navigate('/login');
              },
            });

            return;
          }

          if (Object.entries(errors.out_scope).length == 0) return;
        }

        throw api_response;
      },
    })
      .then(() => {
        clearPrefill('/sign-up/form');

        navigate('/');
      })
      .finally(() => form.setSubmitting(false));

  // i18next-extract-disable
  const validation_schema = Yup.object().shape({
    first_name: Yup.string()
      .required(t('glossary.required'))
      .matches(/[a-zA-Z]+/, t('validation.regex./[a-zA-Z]+/')),
    last_name: Yup.string()
      .required(t('glossary.required'))
      .matches(/[a-zA-Z]+/, t('validation.regex./[a-zA-Z]+/')),
    new_email: Yup.string().email().required(t('glossary.required')),
    confirm_new_email: Yup.string()
      .oneOf([Yup.ref('new_email'), null], t('validation.do-not-match'))
      .required(t('glossary.required')),
    new_password: Yup.string()
      .required(t('glossary.required'))
      .min(8, t('validation.length.min', { count: 8 }))
      .matches(/[0-9]+/, t('validation.regex./[0-9]+/')),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], t('validation.do-not-match'))
      .required(t('glossary.required')),
  });
  // i18next-extract-enable

  const formik = useFormik({
    onSubmit,
    validationSchema: validation_schema,
    initialValues: getPrefill({
      identifier: '/sign-up/form',
      keys: Object.keys(validation_schema.fields),
      initial: Object.fromEntries(
        Object.keys(validation_schema.fields).map((name) => [name, ''])
      ),
    }),
  });

  /**
   * TODO define next config as an H.O.C option.
   */
  const field_variants = {
    error: {
      x: [-10, 10, -10, 0],
      transition: { type: 'spring', duration: 0.3 },
    },
    default: { x: 0 },
  };

  return (
    <>
      <Typography
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          type: 'spring',
          damping: 15,
          stiffness: 100,
        }}
        variant='h1'
        paragraph
      >
        {t('content.anonymous.sign-up.form.first-text', {
          defaultValue: "s'inscrire gratuitement",
        })}
      </Typography>

      {/* i18next-extract-disable */}
      <FlexForm sx={{ my: 2, width: 1 }} onSubmit={formik.handleSubmit}>
        <MotionTextField
          variants={field_variants}
          animate={
            formik.errors.first_name && formik.touched.first_name
              ? 'error'
              : 'default'
          }
          id='first-name-input'
          autoComplete='given-name'
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          label={t('glossary.first-name')}
          type='text'
          name='first_name'
          fullWidth
          margin='normal'
          helperText={formik.errors.first_name}
          error={formik.errors.first_name && formik.touched.first_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.first_name}
        />

        <MotionTextField
          variants={field_variants}
          animate={
            formik.errors.last_name && formik.touched.last_name
              ? 'error'
              : 'default'
          }
          id='last-name-input'
          autoComplete='family-name'
          label={t('glossary.last-name')}
          type='text'
          name='last_name'
          fullWidth
          margin='normal'
          helperText={formik.errors.last_name}
          error={formik.errors.last_name && formik.touched.last_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.last_name}
        />

        <MotionTextField
          variants={field_variants}
          animate={
            formik.errors.new_email && formik.touched.new_email
              ? 'error'
              : 'default'
          }
          id='new_email-input'
          autoComplete='email'
          label={t('glossary.email')}
          type='email'
          name='new_email'
          fullWidth
          margin='normal'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={
            formik.errors.new_email ??
            t('content.anonymous.sign-up.form.email.help')
          }
          error={formik.errors.new_email && formik.touched.new_email}
          value={formik.values.new_email}
        />

        <MotionTextField
          variants={field_variants}
          animate={
            formik.errors.confirm_new_email && formik.touched.confirm_new_email
              ? 'error'
              : 'default'
          }
          id='confirm_new_email-input'
          autoComplete='email'
          label={t('common.confirm-email')}
          type='email'
          name='confirm_new_email'
          fullWidth
          margin='normal'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={formik.errors.confirm_new_email}
          error={
            formik.errors.confirm_new_email && formik.touched.confirm_new_email
          }
          value={formik.values.confirm_new_email}
        />

        <PasswordInput
          variants={field_variants}
          animate={
            formik.errors.new_password && formik.touched.new_password
              ? 'error'
              : 'default'
          }
          id='new_password-input'
          autoComplete='new-password'
          label={t('common.new-password')}
          name='new_password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.new_password}
          error={formik.errors.new_password && formik.touched.new_password}
          helperText={formik.errors.new_password}
        />

        <PasswordInput
          variants={field_variants}
          animate={
            formik.errors.confirm_new_password &&
            formik.touched.confirm_new_password
              ? 'error'
              : 'default'
          }
          id='confirm_new_password-input'
          autoComplete='new-password'
          label={t('common.confirm-password')}
          name='confirm_new_password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirm_new_password}
          error={
            formik.errors.confirm_new_password &&
            formik.touched.confirm_new_password
          }
          helperText={formik.errors.confirm_new_password}
        />
        {/* i18next-extract-enable */}

        <Button
          sx={{ my: 2 }}
          disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
          fullWidth
          type='submit'
          size='large'
          label={t(
            'content.anonymous.sign-up.form.buttons.sign-up.label.is-in-progress',
            { context: formik.isSubmitting ? 'true' : 'false' }
          )}
        />

        <Typography paragraph variant='body2'>
          <Trans
            i18nKey='content.anonymous.sign-up.form.second-text'
            components={{
              link_to_login: (
                <Link to='/login' data-purpose='navigate_to_login' />
              ),
              link_to_account_activation_demand: (
                <Link
                  to='/demands/account/activation'
                  data-purpose='navigate_to_demands_account_activation'
                />
              ),
            }}
          />
        </Typography>
      </FlexForm>

      <FlexBox sx={{ my: 2 }}>
        <Terms />
      </FlexBox>
    </>
  );
};

export default Form;
