import { FlexBox } from '@weasyo/react';

export const QuarterCircleIllustration = ({
  width = '12rem',
  height = '12rem',
  size = 1,
  sx,
  ...rest
}) => (
  <FlexBox
    sx={[
      {
        borderRadius: 100,
        height: ` calc(${height}*${size})`,
        left: `calc(${width} / 2 * ${size} * -1)`,
        position: 'absolute',
        top: `calc(${height} / 2 * ${size} * -1)`,
        width: `calc(${width}*${size})`,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    border={15}
    {...rest}
  />
);

export default QuarterCircleIllustration;
