import { isValidElement } from 'react';
import { FlexBox, Typography } from '@weasyo/react';

import { Capsule } from '~/src/components';

export const Block = ({ color, value, label, sx, ...rest }) => (
  <Capsule
    color={color}
    sx={[
      { minWidth: '6.5rem', minHeight: '6.5rem' },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
  >
    <FlexBox sx={{ width: 1, height: 1, justifyContent: 'flex-end', py: 1 }}>
      {isValidElement(value) ? (
        value
      ) : (
        <FlexBox sx={{ height: 1, justifyContent: 'flex-end' }}>
          <Typography size={36} weight='900' placeholder={'2rem'}>
            {value}
          </Typography>
        </FlexBox>
      )}

      <FlexBox sx={{ mb: 1 }}>
        <Typography variant='body2' placeholder={'4rem'}>
          {label}
        </Typography>
      </FlexBox>
    </FlexBox>
  </Capsule>
);

export default Block;
