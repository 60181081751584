import { Link, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, TextField } from '@mui/material';
import {
  Button,
  FlexBox,
  OAuthForm,
  PasswordInput,
  Typography,
  parsers,
  useAPI,
  useCommonState,
  useDialog,
  usePrefill,
} from '@weasyo/react';

import { FlexForm, Terms } from '~/src/components';

/**
 * List required oauth ids.
 */
const oauth_ids = {
  // eslint-disable-next-line no-undef
  app: process.env.W_APP_ID,
  // eslint-disable-next-line no-undef
  ...JSON.parse(process.env.W_OAUTH_CLIENTS_IDS),
};

const Login = () => {
  useCommonState({ page_title: t('content.anonymous.login.title') });
  const navigate = useNavigate();
  const { get: getPrefill, clear: clearPrefill } = usePrefill();
  const [, setDialog] = useDialog();
  const { API } = useAPI();
  const onSubmit = (data, form) =>
    API.post({
      path: 'authenticate',
      data,
      is_token_related: true,
      with_authorization: false,
      onError: (api_response) => {
        if (api_response?.error?.response?.status == 401) {
          setDialog({
            open: true,
            message: t(
              'content.anonymous.login.dialogs.authentication.invalid-credentials.message'
            ),
          });

          return;
        }

        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            formik,
            violations: api_response.error.data?.violations,
            t,
          });

          if (
            errors.all.status?.data?.value ==
            '"pending_first_enabling_confirmation"'
          ) {
            setDialog({
              open: true,
              message: t(
                'content.anonymous.login.dialogs.authentication.account-pending-first-enabling-confirmation.message',
                { email: data.email }
              ),
            });

            return;
          }

          if (Object.entries(errors.out_scope).length == 0) return;
        }

        throw api_response;
      },
    })
      .then(() => {
        clearPrefill('/login');

        navigate('/', { replace: true });
      })
      .finally(() => form.setSubmitting(false));

  // i18next-extract-disable
  const validation_schema = Yup.object().shape({
    email: Yup.string().email().required(t('glossary.required')),
    password: Yup.string().required(t('glossary.required')),
  });
  // i18next-extract-enable

  const formik = useFormik({
    onSubmit,
    validationSchema: validation_schema,
    initialValues: getPrefill({
      identifier: '/login',
      keys: Object.keys(validation_schema.fields),
      initial: Object.fromEntries(
        Object.keys(validation_schema.fields).map((name) => [name, ''])
      ),
    }),
  });

  return (
    <>
      <Typography
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          type: 'spring',
          damping: 15,
          stiffness: 100,
        }}
        variant='h1'
        paragraph
      >
        {t('content.anonymous.login.first-text')}
      </Typography>

      <FlexBox width={1} height={'14rem'}>
        <OAuthForm
          identifiers={oauth_ids}
          // eslint-disable-next-line no-undef
          api_base_url={process.env.W_API_URL}
        />
      </FlexBox>

      <Typography gutterBottom={false}>
        {t('content.anonymous.login.second-text')}
      </Typography>

      <FlexForm sx={{ width: 1 }} onSubmit={formik.handleSubmit}>
        {/* i18next-extract-disable */}

        <TextField
          id='email-input'
          autoComplete='email'
          label={t('glossary.email')}
          type='email'
          name='email'
          fullWidth
          margin='normal'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />

        <PasswordInput
          animate={{
            x:
              formik.errors.password && formik.touched.password
                ? [-10, 10, -10, 0]
                : 0,
          }}
          transition={{ type: 'spring', duration: 0.3 }}
          style={{ width: '100%' }}
          id='password-input'
          autoComplete='current-password'
          label={t('glossary.password')}
          name='password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.errors.password && formik.touched.password}
          helperText={formik.errors.password}
        />

        {/* i18next-extract-enable */}

        <Typography variant='body2' sx={{ alignSelf: 'flex-end' }}>
          <Link
            data-purpose='navigate_to_demands_password'
            to='/demands/password'
          >
            {t('content.anonymous.login.link-to-password-demand')}
          </Link>
        </Typography>

        <FlexBox sx={{ width: 1, my: 2 }}>
          <Button
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            fullWidth
            type='submit'
            size='large'
            data-purpose='login'
            label={t(
              'content.anonymous.login.buttons.login.label.is-in-progress',
              { context: formik.isSubmitting ? 'true' : 'false' }
            )}
          />
        </FlexBox>

        <Typography paragraph variant='body2'>
          <Trans
            i18nKey='content.anonymous.login.third-text'
            components={{
              link_to_sign_up: (
                <Link to='/sign-up' data-purpose='navigate_to_sign_up' />
              ),
            }}
          />
        </Typography>
      </FlexForm>

      <Box my={2}>
        <Terms />
      </Box>
    </>
  );
};

export default Login;
