/**
 * Add custom styles here.
 *
 * Material UI theme definition
 * https://material-ui.com/customization/theming/
 */

export const theme = (default_theme) => ({
  breakpoints: {
    values: { xs: 600, sm: 600, md: 600, lg: 600, xl: 600 },
  },
  palette: {
    yellow: {
      main: '#fae254',
      light: '#fae254',
      lightest: '#fae254',
      contrastText: default_theme.palette.black.main,
    },
    pink: {
      main: '#ffa498',
      light: '#ffa498',
      lightest: '#ffa498',
      contrastText: default_theme.palette.white.main,
    },
  },
  components: {
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: { '&.Mui-selected': { color: default_theme.palette.black.main } },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: { height: '0.75rem' },
        track: {
          height: 'inherit',
          borderRadius: 6,
          color: '#fd7775',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        mark: {
          width: 2,
          height: 'inherit',
          color: default_theme.palette.white.main,
        },
        rail: {
          height: 'inherit',
          borderRadius: 6,
          color: default_theme.palette.lightGrey.main,
        },
        thumb: {
          height: '1.75rem',
          width: '1.75rem',
          backgroundColor: '#fd8A78',
          '&.Mui-disabled': { visibility: 'hidden' },
        },
      },
    },
  },
});

export default theme;
