import { useState } from 'react';
import { t } from 'i18next';
import {
  EmailToButton,
  FlexBox,
  Modal,
  Typography,
  formatters,
  useStateWrapper,
} from '@weasyo/react';

import { SubscriptionCard } from '~/src/components';
import { Trans } from 'react-i18next';

export const UserSubscriptionProduct = ({
  highlighted,
  product,
  date_switch,
  // eslint-disable-next-line no-unused-vars
  date_expiration,
  is_active,
  required_platform,
}) => {
  const [modal, setModal] = useStateWrapper(
    useState({
      open: false,
      onClose: () => setModal({ open: false }),
    })
  );

  const handleOnClick = () => {
    switch (required_platform) {
      case 'web':
      case 'internal':
        setModal({
          open: true,
          title: t(
            'modals.user-subscription.related-to-external-platform.title'
          ),
          description: t(
            'modals.user-subscription.related-to-external-platform.description'
          ),
          sub_description: (
            <Trans
              i18nKey='modals.user-subscription.related-to-external-platform.sub-description'
              components={{
                C_A: <Typography color='text.secondary' />,
                button: (
                  <FlexBox sx={{ width: 1 }}>
                    <EmailToButton
                      variant='text'
                      color='text.primary'
                      context={t(
                        'emails.to-support.user-subscription-question.context'
                      )}
                      subject={t(
                        'emails.to-support.user-subscription-question.subject'
                      )}
                      debug={{
                        subscription: {
                          product: product.slug,
                          date_switch,
                          is_active,
                          required_platform,
                        },
                      }}
                    />
                  </FlexBox>
                ),
              }}
            />
          ),
          secondary: {
            label: t(
              'modals.user-subscription.related-to-external-platform.secondary.label'
            ),
            onClick: setModal({ open: false }),
          },
          primary: (
            <EmailToButton
              fullWidth
              sx={{ p: 2 }}
              variant='text'
              context={t(
                'emails.to-support.user-subscription-question.context'
              )}
              subject={t(
                'emails.to-support.user-subscription-question.subject'
              )}
              label={t('emails.to-support.user-subscription-question.label')}
              debug={{
                subscription: {
                  date_switch,
                  is_active,
                  product: product.slug,
                  required_platform,
                },
              }}
            />
          ),
        });

        return;

      default:
        setModal({
          open: true,
          title: t(
            'modals.user-subscription.related-to-another-platform.title'
          ),
          description: t(
            'modals.user-subscription.related-to-another-platform.description',
            { platform: required_platform }
          ),
          sub_description: t(
            'modals.user-subscription.related-to-another-platform.sub-description'
          ),
          primary: {
            label: t(
              'modals.user-subscription.related-to-another-platform.primary.label'
            ),
            onClick: () => setModal({ open: false }),
          },
          secondary: undefined,
        });

        return;
    }
  };

  return (
    <>
      <Modal {...modal} />

      <SubscriptionCard
        highlighted={product && highlighted}
        product={product}
        is_active={is_active}
        onClick={handleOnClick}
        sx={{ opacity: 0.9 }}
      />

      <FlexBox sx={{ width: 1, pl: 1, alignItems: 'flex-start' }}>
        <FlexBox
          sx={{
            alignItems: 'inherit',
            borderRadius: 2,
            borderTop: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            boxShadow: 1,
            p: 1,
            textAlign: 'left',
          }}
          color='text.secondary'
          background='white'
        >
          <Typography variant='body2' placeholder='6rem'>
            {/* i18next-extract-disable-next-line */}
            {t('components.user-subscription-product.purchase-platform', {
              context: required_platform,
            })}
          </Typography>

          {date_switch && (
            <Typography variant='body2'>
              {t('components.user-subscription-product.date-switch', {
                date: formatters.date(date_switch, true),
              })}
            </Typography>
          )}
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default UserSubscriptionProduct;
