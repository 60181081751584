import { parsers } from '@weasyo/react';

const queries_names = {
  programs: 'programs',
  patient_programs: 'patient/programs',
  patient_programs_of: 'patient/programs_of',
  patient_program_steps: 'patient/program_steps',
  patient_program_steps_of: 'patient/program_steps_of',
  patient_workouts: 'patient/workouts',
  patient_workouts_of: 'patient/workouts_of',
};

/**
 * Fetch patient program and pre-fetch some related specific data
 * to reduce loading time on next queries.
 */
const fetchPatientPrograms = async ({
  query_client,
  API,
  program,
  patient_program,
  params,
  last_in_progress,
}) => {
  let query_conf, query;

  if (last_in_progress !== undefined && !program) {
    throw new Error(
      '"program" argument must be passed when "last_in_progress" is set.'
    );
  }

  if (patient_program !== undefined && program !== undefined) {
    throw new Error(
      '"program" and "patient_program" arguments can\'t be passed at the same time.'
    );
  }

  /**
   * Define query based on {program}.
   */
  if (program) {
    switch (last_in_progress) {
      case true:
        if (params) {
          throw new Error(
            '"params" and "last_in_progress" arguments can\'t be passed at the same time.'
          );
        }

        query_conf = [
          queries_names.patient_programs_of,
          { id: parsers.getId(program, true), last_in_progress },
        ];

        query = ({ id }) =>
          API.get({
            path: 'patient/programs',
            filters: {
              program: id,
              order: { id: 'desc' },
              status: 'in progress',
              itemsPerPage: 1,
            },
          }).then(({ data }) => data['hydra:member'][0] ?? null);

        break;

      default:
        query_conf = [
          queries_names.patient_programs_of,
          { ...params, id: parsers.getId(program, true) },
        ];

        query = ({ id, ...filters }) =>
          API.get({
            path: 'patient/programs',
            filters: {
              order: { id: 'desc' },
              ...(filters ?? {}),
              program: id,
            },
          }).then(({ data }) => data['hydra:member']);
        break;
    }
  }

  /**
   * Define query based on {patient_program}.
   */
  if (patient_program) {
    if (params) {
      throw new Error(
        '"params" and "patient_program" arguments can\'t be passed at the same time.'
      );
    }
    query_conf = [
      queries_names.patient_programs,
      { id: parsers.getId(patient_program, true) },
    ];

    query = ({ id }) =>
      API.get({ path: 'patient/programs/${id}', data: { id } }).then(
        ({ data }) => data
      );
  }

  /**
   * Define query based on {params} only.
   */
  if (!program && !patient_program) {
    query_conf = [queries_names.patient_programs, params];
    query = (filters) =>
      API.get({
        path: 'patient/programs',
        filters: {
          order: { id: 'desc' },
          itemsPerPage: '100',
          ...(filters ?? {}),
        },
      }).then(({ data }) => data['hydra:member']);
  }

  /**
   * Fetch results.
   */
  const result = await query_client.fetchQuery(
    query_conf,
    ({ queryKey: [, filters] }) =>
      query(filters).then((data) => {
        if (!data) return null;

        (!Array.isArray(data) ? [data] : data).forEach((patient_program) => {
          setQueriesData({
            API,
            query_client,
            patient_program,
            clear: false,
          });
        });

        return data;
      })
  );

  /**
   * Only pre-fetch related data on single result for performance purposes.
   */
  if (result && !Array.isArray(result)) {
    /**
     * Fetch {patient_program_steps}.
     */
    preFetchRelatedOf({ query_client, API, patient_program: result });
  }

  return result;
};

const preFetchRelatedOf = ({ API, query_client, patient_program }) => {
  /**
   * Fetch {patient_program_steps}.
   */
  query_client.fetchQuery(
    [
      queries_names.patient_program_steps_of,
      { id: parsers.getId(patient_program, true) },
    ],
    ({ queryKey: [, { id }] }) =>
      API.get({
        path: 'patient/program_steps',
        filters: { patient_program: id },
      })
        .then(({ data }) => data['hydra:member'])
        .then((patient_program_steps) =>
          patient_program_steps.map((patient_program_step) => {
            setQueriesData({
              API,
              query_client,
              patient_program_step,
              clear: false,
            });

            return patient_program_step;
          })
        )
  );
};

const createPatientProgram = ({ API, query_client, program }) =>
  API.post({
    path: 'patient/programs',
    data: { program: parsers.getId(program) },
  }).then(({ data: patient_program }) => {
    setQueriesData({ query_client, patient_program });

    return patient_program;
  });

const removeQueriesOf = ({ query_client, patient_program }) => {
  if (!patient_program) return;

  query_client.removeQueries([
    queries.names.patient_programs,
    { id: parsers.getId(patient_program, true) },
  ]);

  query_client.removeQueries([
    queries.names.patient_program_steps_of,
    { id: parsers.getId(patient_program, true) },
  ]);

  patient_program.patient_program_steps.map((patient_program_step) => {
    query_client.removeQueries([
      queries.names.patient_program_steps,
      { id: parsers.getId(patient_program_step, true) },
    ]);

    query_client.removeQueries([
      queries.names.patient_workouts_of,
      { id: parsers.getId(patient_program_step, true) },
    ]);
  });
};

const orderObject = (obj) =>
  Object.keys(obj)
    .sort()
    .reduce((accumulator, key) => {
      accumulator[key] = obj[key];

      return accumulator;
    }, {});

const invalidateQueriesOf = ({
  query_client,
  patient_program,
  patient_program_step,
  patient_workout,
  exclude = [],
}) =>
  query_client.invalidateQueries({
    predicate: ({ queryKey: [name, params = {}] }) => {
      /**
       * Do not invalidate those matching {exclude}.
       */
      for (const [name_ref, params_ref] of exclude) {
        if (name === name_ref) {
          if (
            JSON.stringify(orderObject(params ?? {})) ===
            JSON.stringify(orderObject(params_ref ?? {}))
          ) {
            return false;
          }
        }
      }

      switch (name) {
        case queries_names.patient_programs:
          if (
            params.id &&
            params.id == parsers.getId(patient_program ?? '-1', true)
          ) {
            return true;
          }
          if (params.id) return false;

          if (
            (params['program.category'] ?? '-1') ===
            patient_program?.program.category
          ) {
            return true;
          }

          if ((params['program.category'] ?? '-1') === '-1' && params.status) {
            return true;
          }

          break;

        case queries_names.patient_programs_of:
          if (
            parsers.getId(patient_program?.program ?? '-1', true) == params.id
          ) {
            return true;
          }
          break;

        case queries_names.patient_program_steps:
          if (parsers.getId(patient_program_step ?? '-1', true) == params.id) {
            return true;
          }

          if (
            parsers.getId(
              patient_workout?.patient_program_step ?? '-1',
              true
            ) == params.id
          ) {
            return true;
          }

          break;

        case queries_names.patient_program_steps_of:
          if (
            parsers.getId(
              patient_program_step?.patient_program ?? '-1',
              true
            ) == params.id
          ) {
            return true;
          }

          if (parsers.getId(patient_program ?? '-1', true) == params.id) {
            return true;
          }

          break;

        case queries_names.patient_workouts:
          if (parsers.getId(patient_workout ?? '-1', true) == params.id) {
            return true;
          }
          break;

        case queries_names.patient_workouts_of:
          if (parsers.getId(patient_program_step ?? '-1', true) == params.id) {
            return true;
          }

          if (
            parsers.getId(
              patient_workout?.patient_program_step ?? '-1',
              true
            ) == params.id
          ) {
            return true;
          }

          if (
            parsers.getId(
              patient_program?.next_patient_workout_todo
                ?.patient_program_step ?? '-1',
              true
            ) == params.id
          ) {
            return true;
          }

          break;
      }

      return false;
    },
  });

const setQueriesDataAndGetQueryKey = ({ query_client, query_key, value }) => {
  query_client.setQueryData(query_key, value);
  return query_key;
};

const setQueriesData = ({
  API,
  query_client,
  patient_program,
  patient_program_step,
  patient_workout,
  clear = true,
}) => {
  if (patient_program) {
    let exclude = [];

    /**
     * Theoretically, API can't return several {patient_program.program.status = true}.
     * So we can handle it.
     */
    if (patient_program.status == 'in progress') {
      exclude.push(
        setQueriesDataAndGetQueryKey({
          query_client,
          query_key: [
            queries_names.patient_programs_of,
            {
              id: parsers.getId(patient_program.program, true),
              last_in_progress: true,
            },
          ],
          value: patient_program,
        })
      );
    }

    /**
     * Prepare the cache for next related queries.
     */
    exclude.push(
      setQueriesDataAndGetQueryKey({
        query_client,
        query_key: [
          queries_names.patient_programs,
          { id: parsers.getId(patient_program, true) },
        ],
        value: patient_program,
      })
    );

    exclude.push(
      setQueriesDataAndGetQueryKey({
        query_client,
        query_key: [
          queries_names.patient_programs_of,
          { id: parsers.getId(patient_program.program, true) },
        ],
        value: patient_program,
      })
    );

    exclude.push(
      setQueriesDataAndGetQueryKey({
        query_client,
        query_key: [
          queries_names.programs,
          { id: parsers.getId(patient_program.program, true) },
        ],
        value: patient_program.program,
      })
    );

    if (clear) {
      invalidateQueriesOf({ query_client, patient_program, exclude });
    }
  }

  if (patient_program_step) {
    let exclude = [];

    /**
     * Fetch {patient_workouts} of "in progress" step.
     */
    if (patient_program_step.status == 'in progress') {
      query_client.fetchQuery(
        [
          queries_names.patient_workouts_of,
          { id: parsers.getId(patient_program_step, true) },
        ],
        ({ queryKey: [, { id }] }) =>
          API.get({
            path: 'patient/workouts',
            filters: { patient_program_step: id },
          }).then(({ data }) =>
            data['hydra:member'].map((patient_workout) => {
              setQueriesData({
                API,
                query_client,
                patient_workout,
                clear: false,
              });

              return patient_workout;
            })
          )
      );
    }

    exclude.push(
      setQueriesDataAndGetQueryKey({
        query_client,
        query_key: [
          queries_names.patient_program_steps,
          { id: parsers.getId(patient_program_step, true) },
        ],
        value: patient_program_step,
      })
    );

    if (clear) {
      invalidateQueriesOf({ query_client, patient_program_step, exclude });
    }
  }

  if (patient_workout) {
    let exclude = [];
    exclude.push(
      setQueriesDataAndGetQueryKey({
        query_client,
        query_key: [
          queries_names.patient_workouts,
          { id: parsers.getId(patient_workout, true) },
        ],
        value: patient_workout,
      })
    );

    if (clear) {
      invalidateQueriesOf({ query_client, patient_workout, exclude });
    }
  }
};

export const queries = {
  createPatientProgram,
  fetchPatientPrograms,
  invalidateQueriesOf,
  names: queries_names,
  preFetchRelatedOf,
  removeQueriesOf,
  setQueriesData,
};
