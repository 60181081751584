import { Typography, EmailToButton } from '@weasyo/react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { version as web_app_version } from '~/package.json';

export const Terms = () => (
  <Typography variant='h6' color='text.secondary'>
    <Trans
      i18nKey='components.terms.text'
      components={{
        link_to_terms_of_service: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            data-purpose='navigate_to_terms_of_service'
            href={`${window.location.origin}/${web_app_version}/docs/terms_of_service.pdf`}
            rel='noreferrer'
            target='_blank'
          />
        ),
        link_to_privacy_policy: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            data-purpose='navigate_to_privacy_policy'
            href={`${window.location.origin}/${web_app_version}/docs/privacy_policy.pdf`}
            rel='noreferrer'
          />
        ),
        contact_button: (
          <EmailToButton
            variant='text'
            color='text.primary'
            context={t('emails.to-support.terms-question.context')}
            subject={t('emails.to-support.terms-question.subject')}
            sx={{
              display: 'inline',
              p: 0,
              '& p': { userSelect: 'text', fontSize: '0.75rem' },
            }}
          />
        ),
      }}
    />
  </Typography>
);

export default Terms;
