import { withStrictFramework, ErrorFallback } from '@weasyo/react';
import { fr } from 'date-fns/locale';

import Index from '~/src/content/Index';
import Page500 from '~/src/content/error/500';
import { Template, Loader } from '~/src/components';
import { theme, browserlist_definition } from '~/src/utilities';
import { version as package_version } from '~/package.json';

// eslint-disable-next-line no-undef
const current_env = process.env.W_ENV;
const prod_env = 'prod';
const is_debug_on = current_env !== prod_env;
//console.log({sw_url:new URL('~/src/service-worker.js', import.meta.url)}); 
export default withStrictFramework(Index, {
  native_container: false,
  localization: { adapterLocale: fr },
  // eslint-disable-next-line no-undef
  api: { entrypoint: process.env.W_API_URL },
  adjust: true,
  core: {
    web_app: {
      // eslint-disable-next-line no-undef
      builded_at: new Date(process.env.BUILD_TIMESTAMP * 1000).toUTCString(),
      env: current_env,
      version: package_version,
    },
  },
  device: { supported_devices: browserlist_definition },
  error_boundary: {
    FallbackComponent: is_debug_on ? ErrorFallback : Page500,
    onError({ error, defaultHandler }) {
      console.log({ error, defaultHandler });
      // Do something with the error
      // E.g. log to an error logging client here
    },
  },
  firebase: true,
  firewall: {
    Loader: (
      <Template>
        <Loader />
      </Template>
    ),
  },
  /**
   * GTM is used to analyze user activities through :
   * - events creation (button click, called URI, etc.)
   * - merge same native GTM / web app GTM user through {client_id}
   * - create audiences depending on user language
   * - create audiences depending on user "premium" status
   *
   * But it's also used for U.I logical,
   * - sync container translations with user language
   * - hide "subscriptions offers" button
   * - fix iPad workout player error
   * - set "subscriptions offers" design
   * TODO : handle iPad workout player error
   */
  // eslint-disable-next-line no-undef
//  gtm: process.env.W_GOOGLE_TAG_MANAGER,
  loader_indicator: { delay: 550 },
  service_worker: {
    enabled: true, // current_env !== 'local',
    debug: is_debug_on,
    activation: { skip_waiting_confirmation: { enabled: false } },
    register: () =>
      navigator.serviceWorker.register(
       new URL('~/src/service-worker.js', import.meta.url),
        { type: 'module', updateViaCache: 'none' }
      ),
  },
  storage: { throw_error: is_debug_on },
  theme: { theme, debug: is_debug_on },
  translation: {
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'en',
    nonExplicitSupportedLngs: true,
    ns: ['weasyo.app'],
    debug: is_debug_on,
    cache: { version: package_version, identifier: 'weasyo_trans_' },
  },
});
