import { version as web_app_version } from '~/package.json';
import common from './common/';
import fr from './fr/';
import en from './en/';

const sprites = { common, fr, en };

/**
 * Generates "public" relative resource URI.
 */
const tmp = [];
Object.entries(sprites).map(([key, value]) => {
  /**
   * Updates {src} with expected "public" relative resource URI.
   */
  sprites[key].src = value.src.map((file_path) => {
    const public_endpoint = `/${web_app_version}/audio/${key}/${file_path.replace(
      /(.*[\\/])+/,
      ''
    )}`;

    tmp.unshift(public_endpoint);

    return public_endpoint;
  });
});

export const resources_mapping = tmp;

export default sprites;
