import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Slider, IconButton } from '@mui/material';
import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  BackNavBlocker,
  Button,
  FlexBox,
  Svg,
  Typography,
  parsers,
  useAPI,
  useTemplate,
  useCommonState,
} from '@weasyo/react';

import { Header } from '~/src/components';

const Quiz = ({ patient_workout_id, onNavigate }) => {
  const { t } = useTranslation();
  useCommonState({
    page_title: t('content.authenticated.programs.workout-player.quizz.title'),
  });

  const navigate = useNavigate();
  const { API } = useAPI();
  const [values, setValues] = useState({});
  const setTemplate = useTemplate();

  /**
   * Fetch {patient_program}.
   */
  let { data: questions } = useQuery(
    ['questions', { item_type: 'workout_completed' }],
    ({ queryKey }) =>
      API.get({ path: 'questions', filters: queryKey[1] }).then(
        (response) => response.data['hydra:member']
      )
  );

  const handleOnSubmit = () =>
    Promise.all(
      questions.map((question, index) =>
        API.post({
          path: 'questions_responses',
          data: {
            question: parsers.getId(question),
            response: [values[index] ?? 0],
            reference: patient_workout_id,
          },
        })
      )
    ).then(onNavigate);

  useEffect(() => {
    setTemplate({
      header: (
        <Header
          title={t(
            'content.authenticated.programs.workout-player.quizz.header.title'
          )}
          extra={
            <IconButton
              aria-label='close'
              onClick={() => navigate(-1)}
              size='large'
            >
              <Svg color='lightGrey' component={CloseIcon} />
            </IconButton>
          }
        />
      ),
    });
  }, []);

  return (
    <>
      <BackNavBlocker
        mode='confirmation'
        modal={{
          title: t('modals.quit-patient-workout-quizz.title'),
          description: t('modals.quit-patient-workout-quizz.description'),
          sub_description: t(
            'modals.quit-patient-workout-quizz.sub-description'
          ),
          primary: {
            label: t('modals.quit-patient-workout-quizz.primary.label'),
          },
          secondary: {
            label: t('modals.quit-patient-workout-quizz.secondary.label'),
            onClick: ({ cancel }) => {
              cancel();
              onNavigate?.();
            },
          },
        }}
      />

      <FlexBox
        sx={{ flexGrow: 1, justifyContent: 'space-around', width: 1, p: 2 }}
      >
        <FlexBox sx={{ width: 1, rowGap: 2, maxWidth: '25rem' }}>
          {(questions ?? [null, null, null]).map((question, index) => (
            <FlexBox sx={{ width: 1 }} key={index}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                gutterBottom
                placeholder={`calc(15rem + ${Math.ceil(
                  Math.random() * index * 2
                )}rem )`}
              >
                {question?.title}
              </Typography>

              <Slider
                step={1}
                min={0}
                max={5}
                disabled={!question}
                value={values[index] === undefined ? 0 : values[index]}
                onChange={(event, value) => {
                  setValues((prev) => {
                    const res = { ...prev };
                    res[index] = value;
                    return res;
                  });
                }}
                marks={true}
              />
            </FlexBox>
          ))}
        </FlexBox>

        <Button
          data-purpose='submit_responses'
          fullWidth
          size='large'
          disabled={(questions ?? []).length == 0}
          onClick={handleOnSubmit}
          label={t(
            'content.authenticated.programs.workout-player.quizz.buttons.submit.label'
          )}
        />
      </FlexBox>
    </>
  );
};

export default Quiz;
