import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
  Redirect,
  useCore,
  useStorage,
  useSubscriptions,
  withDestinationURLParamHandler,
  withServiceWorkerUpdateCheck,
  withSoftwareUpdateCheck,
  withSubscriptions,
} from '@weasyo/react';

import { Router } from '~/src/components';
import Index from './Index';
import Profile from './profile/Routes';
import Programs from './programs/Routes';
import Zones from './zones/Routes';
import Onboarding from './Onboarding';
import Share from './Share';
import Subscriptions from './subscriptions/Routes';

/**
 * Make routes "aware" of service worker and software updates.
 */
const IndexWithNotifications = withSoftwareUpdateCheck(
  withServiceWorkerUpdateCheck(Index)
);
const ProfileWithNotifications = withSoftwareUpdateCheck(
  withServiceWorkerUpdateCheck(Profile)
);

/**
 * Makes "home" listening "destination" URL parameter.
 */
const IndexWithDestinationHandler = withDestinationURLParamHandler(
  IndexWithNotifications,
  { listen: true }
);

/**
 * Routes available for authenticated user.
 */
export const Routes = () => {
  const [core] = useCore();
  const { actives } = useSubscriptions();
  const storage = useStorage({ type: 'local' });
  const query_client = useQueryClient();

  /**
   * On "premium" state update :
   * - invalidate concerned queries cache
   * - update "is_premium" stored variable value
   */
  useEffect(() => {
    if (actives == undefined) return;

    if (core.user?.is_premium != !!actives.length) {
      query_client.invalidateQueries({
        predicate: ({ queryKey: [name] }) =>
          !!['workout', 'program', 'patient'].find((needle) =>
            name.startsWith(needle)
          ),
      });
    }

    /**
     * @deprecated, handled by {@weasyo/react} under "core" data.
     * TODO remove when container dev will handled update.
     */
    storage.set('is_premium', !!actives.length);
  }, [actives?.length]);

  return (
    <Router>
      <IndexWithDestinationHandler path='/' />
      <Redirect from='login' to='/' replace />
      <Redirect from='oauth' to='/' replace />
      <Onboarding path='/onboarding' />
      <Onboarding path='/onboarding/:step_id' />
      <ProfileWithNotifications path='/profile/*' />
      <Zones path='/zones/*' />
      <Share path='/share' />
      <Subscriptions path='/subscriptions/*' />
      <Programs path='*' />
    </Router>
  );
};

export default withSubscriptions(Routes);
