import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Lock from '@mui/icons-material/Lock';
import {
  CheckRounded as DoneIcon,
  StarRounded as StarIcon,
} from '@mui/icons-material';
import {
  CardItem,
  Duration,
  FlexBox,
  Modal,
  Svg,
  Typography,
  useStateWrapper,
} from '@weasyo/react';
import { MedalIcon } from '@weasyo/react/lib/images';

import MeditationWomanIllustration from 'jsx:/src/assets/images/svg/illustrations/meditation_woman.svg';
import YogaManGreenIllustration from 'jsx:/src/assets/images/svg/illustrations/yoga_man_green.svg';
import YogaManWhiteIllustration from 'jsx:/src/assets/images/svg/illustrations/yoga_man_white.svg';

export const WorkoutListItem = ({
  background,
  is_restricted,
  onClick,
  position,
  status,
  duration,
  level,
  sx,
  ...rest
}) => {
  const { t } = useTranslation();
  const is_loading = status === undefined;
  status = is_restricted ? 'restricted' : status;
  const is_highlighted = ['todo', 'available'].includes(status);
  const navigate = useNavigate();
  const [lock_modal, setModal] = useStateWrapper(useState({ open: false }));

  /**
   * Handle {workout} click event.
   */
  const handleOnClick = () => {
    switch (status) {
      case 'restricted':
        return navigate('/subscriptions');

      case 'locked':
        return setModal({
          open: true,
          title: t('modals.by-pass-patient-workout.title'),
          description: t('modals.by-pass-patient-workout.description'),
          sub_description: t('modals.by-pass-patient-workout.sub-description'),
          primary: { label: t('modals.by-pass-patient-workout.primary.label') },
          onClose: () => setModal({ open: false }),
        });

      default:
        onClick && onClick();
    }
  };

  return (
    <>
      <Modal {...lock_modal} />

      <CardItem
        onClick={handleOnClick}
        arrow='center'
        data-purpose={
          status == 'restricted'
            ? 'navigate_to_subscriptions'
            : 'navigate_to_patient_workout_details'
        }
        sx={[{ borderRadius: 2, width: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
        color={is_highlighted ? 'white' : 'lightGrey'}
        {...(status == 'restricted' ? { arrow_color: background } : {})}
        {...(is_highlighted ? { background } : { border: 1 })}
        {...rest}
      >
        <FlexBox
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            p: 1.5,
            pr: 0,
            width: 1,
          }}
        >
          <FlexBox
            sx={{ flexDirection: 'row', flexGrow: 1, flexBasis: '15rem' }}
          >
            <FlexBox
              sx={{
                flexBasis: '3rem',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <FlexBox
                border={1.5}
                sx={{
                  borderRadius: 20,
                  flexDirection: 'row',
                  height: '2rem',
                  justifyContent: 'center',
                  width: '2rem',
                }}
              >
                {status == 'restricted' && (
                  <Svg component={Lock} height='1rem' />
                )}
                {status == 'completed' && (
                  <Svg component={DoneIcon} height={'1rem'} />
                )}
                {['locked', 'todo', 'available'].includes(status) && (
                  <Typography
                    color='inherit'
                    variant={'body2'}
                    weight={is_highlighted ? 'bold' : undefined}
                  >
                    {position}
                  </Typography>
                )}
              </FlexBox>
            </FlexBox>

            <FlexBox sx={{ alignItems: 'self-start' }}>
              <Typography
                color='inherit'
                noWrap
                placeholder='6rem'
                variant='body2'
                sx={{ fontWeight: is_highlighted ? 'bold' : undefined }}
              >
                {!is_loading && (
                  /* i18next-extract-disable-next-line */
                  <Trans
                    i18nKey='components.workout-list-item.position'
                    values={{ position }}
                    context={
                      duration && is_highlighted
                        ? 'with-duration'
                        : 'without-duration'
                    }
                    components={{
                      duration: <Duration data={duration} is_abbreviation />,
                    }}
                  />
                )}
              </Typography>

              <Typography
                color='inherit'
                component='span'
                placeholder='4rem'
                size={12}
                line_height={12}
              >
                {!is_loading &&
                  t('components.workout-list-item.level', { level })}
              </Typography>
            </FlexBox>
          </FlexBox>

          <FlexBox
            sx={{
              flexBasis: '10rem',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {status == 'restricted' && (
              <Svg component={StarIcon} color='yellow' fontSize='large' />
            )}

            {status == 'completed' && (
              <Svg component={MedalIcon} color='lightGrey' fontSize='large' />
            )}

            {status == 'locked' && (
              <Svg component={MeditationWomanIllustration} fontSize='large' />
            )}

            {is_highlighted && background == 'health' && (
              <Svg component={YogaManWhiteIllustration} fontSize='large' />
            )}

            {is_highlighted && background != 'health' && (
              <Svg component={YogaManGreenIllustration} fontSize='large' />
            )}

            <FlexBox
              sx={{ flexDirection: 'row', justifyContent: 'flex-end' }}
              {...(status == 'restricted' ? { color: background } : {})}
            >
              <Typography
                placeholder='10rem'
                variant='body2'
                sx={{ fontWeight: is_highlighted ? 'bold' : undefined }}
              >
                {!is_loading &&
                  /* i18next-extract-disable-next-line */
                  t('components.workout-list-item.status', { context: status })}
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </CardItem>
    </>
  );
};

export default WorkoutListItem;
