{
  "name": "weasyo_public_app",
  "version": "2.2.0",
  "description": "Weasyo Public Application",
  "author": "safouan.matmati@weasyo.com",
  "license": "UNLICENSED",
  "private": true,
  "scripts": {
    "audio-sprites": "./npm.sh run audio-common-sound; ./npm.sh run audio-lng-sounds; ./npm.sh run audio-indexes; DIR=$LNG ./npm.sh run audio-sub-indexes; DIR=common ./npm.sh run audio-sub-indexes",
    "audio-common-sound": "audiosprite --silence 5 --gap 3 --loop --output ./src/assets/audio/generated/common/audio -f howler2 -e mp3 ./src/assets/audio/common/*.mp3",
    "audio-lng-sounds": "audiosprite --silence 5 --gap 3 --loop --output ./src/assets/audio/generated/$LNG/audio -f howler2 -e mp3 ./src/assets/audio/languages/common/*.mp3 ./src/assets/audio/languages/$LNG/*.mp3",
    "audio-indexes": "cp ./src/assets/audio/templates/index.jsx ./src/assets/audio/generated/index.jsx;",
    "audio-sub-indexes": "cp ./src/assets/audio/templates/sub_index.jsx ./src/assets/audio/generated/$DIR/index.jsx",
    "browserlist-def": "echo \"// Generated at $(eval date) by './npm.sh run browserlist-def'\nexport default $(browserslist-useragent-regexp --allowHigherVersions);\" > src/utilities/browserlist.def.js",
    "build": "parcel build ./src/index.html --detailed-report --no-source-maps --no-cache --no-scope-hoist --log-level verbose --reporter @parcel/reporter-bundle-buddy; ./npm.sh run rename-version-folder",
    "build:production": "parcel build ./src/index.html --no-source-maps --detailed-report --no-cache --no-scope-hoist --log-level verbose; ./npm.sh run rename-version-folder",
    "docker": "parcel ./src/index.html --port 3060 --hmr-port 443 --watch-for-stdin --no-autoinstall",
    "eslint": "eslint --format stylish",
    "favicon": "real-favicon generate ./src/assets/favicon/realfavicon.json ./src/assets/auto_generated/data.json ./src/assets/auto_generated; ./npm.sh run favicon-inject",
    "favicon-inject": "real-favicon inject ./src/assets/auto_generated/data.json ./ ./src/index.html",
    "local": "parcel ./src/index.html --watch-for-stdin --no-autoinstall --no-hmr",
    "prepare": "husky install",
    "prettier": "prettier --write",
    "rename-version-folder": "mkdir -p ./dist/$(awk -F'\"' '/\"version\": \".+\"/{ print $4; exit; }' ./package.json); cp -R -f ./dist/_replaced-by-version-at-build_/* ./dist/$(awk -F'\"' '/\"version\": \".+\"/{ print $4; exit; }' ./package.json)",
    "start": "concurrently --restart-tries 2 --restart-after 2000 --kill-others --names \"./npm.sh run local, npm-watch\" \"./npm.sh run local\" \"npm-watch\"",
    "docker-start": "concurrently --restart-tries 2 --restart-after 2000 --kill-others --names \"./npm.sh run docker, npm-watch\" \"./npm.sh run docker\" \"npm-watch\""
  },
  "browserslist": "> 0.5%, last 2 versions, not dead, chrome >= 80, samsung >= 13, safari >= 13, firefox esr",
  "engines": {
    "node": "v20.3.1",
    "npm": "9.6.7"
  },
  "staticFiles": {
    "staticPath": [
      {
        "staticPath": "src/assets/docs",
        "staticOutDir": "dist/_replaced-by-version-at-build_/docs"
      },
      {
        "staticPath": "src/assets/.well-known",
        "staticOutDir": "dist/.well-known"
      },
      {
        "staticPath": "node_modules/@weasyo/react/lib/translations",
        "staticOutDir": "dist/_replaced-by-version-at-build_/translations"
      },
      {
        "staticPath": "src/assets/translations",
        "staticOutDir": "dist/_replaced-by-version-at-build_/translations"
      },
      {
        "staticPath": "src/assets/audio/generated",
        "staticOutDir": "dist/_replaced-by-version-at-build_/audio"
      }
    ]
  },
  "watch": {
    "rename-version-folder": {
      "patterns": [
        "dist/_replaced-by-version-at-build_/**"
      ],
      "extensions": "*",
      "runOnChangeOnly": true
    }
  },
  "lint-staged": {
    "*.{js,jsx}": [
      "npm run eslint",
      "npm run prettier"
    ]
  },
  "@parcel/resolver-default": {
    "packageExports": true
  },
  "dependencies": {
    "@emotion/react": "11.11.1",
    "@emotion/styled": "11.11.0",
    "@mui/icons-material": "5.10.3",
    "@mui/material": "5.10.3",
    "@mui/x-date-pickers": "5.0.0",
    "@parcel/service-worker": "2.10.2",
    "@uppy/core": "3.5.1",
    "@uppy/dashboard": "3.0.1",
    "@uppy/drag-drop": "3.0.0",
    "@uppy/file-input": "3.0.0",
    "@uppy/progress-bar": "3.0.0",
    "@uppy/react": "3.0.1",
    "@uppy/status-bar": "3.0.1",
    "@uppy/tus": "3.0.1",
    "@uppy/xhr-upload": "3.2.0",
    "@weasyo/react": "file:.yalc/@weasyo/react",
    "formik": "2.2.9",
    "framer-motion": "4.0.0",
    "html-react-parser": "1.4.8",
    "i18next": "21.6.14",
    "querystring-es3": "0.2.1",
    "react": "17.0.2",
    "react-confetti": "6.1.0",
    "react-dom": "17.0.2",
    "react-gtm-module": "2.0.11",
    "react-i18next": "11.16.2",
    "react-intersection-observer": "9.4.0",
    "react-placeholder": "4.1.0",
    "react-query": "3.34.16",
    "react-router": "6.3.0",
    "react-router-dom": "6.3.0",
    "stream-browserify": "3.0.0",
    "tslib": "2.5.0",
    "util": "0.12.4",
    "workbox-cacheable-response": "6.5.4",
    "workbox-expiration": "6.5.4",
    "workbox-range-requests": "6.5.4",
    "workbox-recipes": "6.5.4",
    "workbox-routing": "6.5.4",
    "workbox-strategies": "6.5.4",
    "yup": "0.32.11"
  },
  "devDependencies": {
    "@babel/core": "7.17.5",
    "@emotion/babel-plugin": "11.11.0",
    "@emotion/eslint-plugin": "11.7.0",
    "@parcel/compressor-gzip": "2.10.2",
    "@parcel/packager-raw-url": "2.10.2",
    "@parcel/packager-xml": "2.10.2",
    "@parcel/reporter-bundle-buddy": "2.10.2",
    "@parcel/transformer-svg-react": "2.10.2",
    "@parcel/transformer-webmanifest": "2.10.2",
    "@parcel/transformer-xml": "2.10.2",
    "audiosprite": "0.7.2",
    "babel-plugin-direct-import": "1.0.0",
    "babel-plugin-i18next-extract": "0.9.0",
    "browserslist-useragent-regexp": "3.0.2",
    "cli-real-favicon": "0.0.8",
    "concurrently": "7.3.0",
    "eslint": "8.10.0",
    "eslint-config-prettier": "8.4.0",
    "eslint-import-resolver-parcel": "1.10.6",
    "eslint-plugin-eslint-snake-case": "0.0.6",
    "eslint-plugin-i18next": "6.0.0-2",
    "eslint-plugin-import": "2.25.4",
    "eslint-plugin-jsx-a11y": "6.5.1",
    "eslint-plugin-prettier": "4.0.0",
    "eslint-plugin-react": "7.29.2",
    "eslint-plugin-react-hooks": "4.3.0",
    "husky": "7.0.4",
    "lint-staged": "12.3.7",
    "npm-watch": "0.4.0",
    "parcel": "2.10.2",
    "parcel-reporter-copystatic": "1.1.0",
    "prettier": "2.5.1"
  }
}
