import { useQuery } from 'react-query';
import { useState } from 'react';
// import Confetti from 'react-confetti';
import {
  BackNavBlocker,
  Button,
  FlexBox,
  Modal,
  Svg,
  Typography,
  useAPI,
  useCommonState,
  useStateWrapper,
  withTemplateCheckpoint,
} from '@weasyo/react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import CongratulationIllustration from 'jsx:/src/assets/images/svg/illustrations/congratulations.svg';
import FiestaIllustration from 'jsx:/src/assets/images/svg/illustrations/fiesta.svg';
import { getRandomSentence } from '~/src/utilities';

const PatientProgramStepCompleted = ({ onNavigate, next_position }) => {
  const i18next_key_part = `patient-program${
    next_position ? '-step' : ''
  }-completed`;

  // i18next-extract-disable
  const i18next_key = `content.authenticated.programs.workout-player.${i18next_key_part}`;

  useCommonState({ page_title: t(`${i18next_key}.title`) });
  const { API } = useAPI();
  const [{ motivation_sentence, modal }, setState] = useStateWrapper(
    useState({
      modal: { open: false },
      motivation_sentence: getRandomSentence({
        type: 'congratulations',
        progression: 100,
        sentences: t('common.motivational-phrases', { returnObjects: true }),
      }),
    })
  );

  const onClick = () => {
    const i18n_key = `modals.${i18next_key_part}`;

    setState({
      modal: {
        open: true,
        title: t(`${i18n_key}.title`),
        description: t(`${i18n_key}.description`),
        sub_description: t(`${i18n_key}.sub-description`),
        primary: {
          label: t(`${i18n_key}.primary.label`),
          onClick: onNavigate,
        },
      },
    });
  };

  /**
   * Fetch {profile}.
   */
  const { data: profile } = useQuery(['profile'], () =>
    API.get({ path: 'profile' }).then(({ data }) => data)
  );

  return (
    <>
      <BackNavBlocker mode='disabled' onBackNav={onNavigate} />

      {/* <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={2000}
        tweenDuration={10000}
      /> */}

      <Modal {...modal} />

      <FlexBox
        sx={{
          flexGrow: 1,
          justifyContent: 'space-between',
          mt: 3,
          p: 2,
          width: 1,
        }}
      >
        <FlexBox>
          <Typography
            sx={{ textAlign: 'center', textTransform: 'capitalize' }}
            variant='h1'
            gutterBottom
          >
            {t(`${i18next_key}.first-text`, {
              context: profile?.first_name && 'with-user-name',
              user_name: profile?.first_name,
            })}
          </Typography>

          <Trans
            i18nKey={`${i18next_key}.second-text`}
            values={{ next_level: next_position }}
            components={{
              C_A: (
                <Typography
                  sx={{ textAlign: 'center' }}
                  size={16}
                  gutterBottom
                />
              ),
              C_B: (
                <Typography
                  sx={{ textAlign: 'center' }}
                  size={16}
                  gutterBottom
                />
              ),
            }}
          />
        </FlexBox>

        <Svg
          height='45vh'
          component={
            next_position ? CongratulationIllustration : FiestaIllustration
          }
        />
      </FlexBox>

      <FlexBox
        sx={{
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'space-evenly',
          p: 2,
          width: 1,
        }}
      >
        <FlexBox sx={{ my: 2 }}>
          <Typography size={18}>{motivation_sentence}</Typography>
        </FlexBox>

        <Button
          data-purpose='continue'
          fullWidth
          size='large'
          onClick={onClick}
          label={t(`${i18next_key}.buttons.continue.label`)}
        />
      </FlexBox>
    </>
  );

  // i18next-extract-enable
};

export default withTemplateCheckpoint(
  PatientProgramStepCompleted,
  { inheritance: 'default' },
  { header: null }
);
