import { Fragment, useEffect, useState } from 'react';
import {
  BackNavBlocker,
  Button,
  CardItem,
  Duration,
  FlexBox,
  Modal,
  Svg,
  TabPanel,
  Typography,
  formatters,
  parsers,
  useAPI,
  useStateWrapper,
} from '@weasyo/react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { TipsIcon } from '@weasyo/react/lib/images';
import { useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { Divider, Drawer, Tab, Tabs, Slider } from '@mui/material';

import { WorkoutListItem, ListItems, WorkoutExercisesList } from './';
import { queries } from '~/src/utilities';

const ProgramStepResume = ({ children, Component, ...rest }) => {
  switch (Component) {
    case CardItem:
      return (
        <CardItem width={1} height={1} arrow='center' color='midGrey' {...rest}>
          {children}
        </CardItem>
      );

    default:
      return (
        <Component>
          <FlexBox
            sx={{
              alignItems: 'flex-start',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 1,
            }}
          >
            {children}
          </FlexBox>
        </Component>
      );
  }
};

const SwitchModal = ({
  patient_steps,
  onClose,
  onChange,
  open = false,
  current_index = 0,
}) => {
  const query_client = useQueryClient();
  const { API } = useAPI();
  const current_step = patient_steps.find(
    (patient_step) => patient_step.status === 'in progress'
  );
  const [{ selected_tab, confirm_modal }, setState] = useStateWrapper(
    useState({
      selected_tab: current_index,
      confirm_modal: {
        open: false,
        title: t('modals.change-patient-program-step.title'),
        description: t('modals.change-patient-program-step.description'),
        sub_description: t(
          'modals.change-patient-program-step.sub-description'
        ),
        primary: {
          label: t('modals.change-patient-program-step.primary.label'),
        },
        secondary: {
          label: t('modals.change-patient-program-step.secondary.label'),
        },
        onClose: () => setState({ confirm_modal: { open: false } }),
      },
    })
  );

  const selected_step = patient_steps[selected_tab];

  const switch_infos = selected_step.program_step.switch_infos.sort((a, b) => {
    return parseInt(a.position) > parseInt(b.position) ? 1 : -1;
  });

  const handleOnClose = () => {
    onClose?.();
    setState({ selected_tab: current_index });
  };

  useEffect(() => {
    setState({ selected_tab: current_index });
  }, [current_index]);

  return (
    <>
      {open && (
        <BackNavBlocker
          re_apply={!confirm_modal.open}
          mode='disabled'
          onBackNav={handleOnClose}
        />
      )}

      <Modal {...confirm_modal} />

      <Drawer
        open={open}
        anchor='bottom'
        onClose={handleOnClose}
        sx={{
          '.MuiDrawer-paper': {
            borderRadius: 2,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          },
        }}
      >
        <FlexBox sx={{ p: 2 }}>
          <Typography
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
            gutterBottom
          >
            {t('components.program-step-list.switch-modal.first-text')}
          </Typography>

          <FlexBox sx={{ flexDirection: 'row' }}>
            <Typography align='center' variant='body2'>
              <Trans
                i18nKey='components.program-step-list.switch-modal.second-text'
                components={{
                  C_A: (
                    <Typography
                      sx={{ fontWeight: 'bold', textAlign: 'center' }}
                      component='span'
                      variant='inherit'
                      transform={false}
                    />
                  ),
                }}
              />
            </Typography>
          </FlexBox>
        </FlexBox>

        <Divider width='100%' />

        <FlexBox sx={{ px: 2, width: 1 }}>
          <Tabs
            value={selected_tab}
            onChange={(event, index) => setState({ selected_tab: index })}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            sx={{ width: 1 }}
          >
            {patient_steps.map((patient_step) => (
              <Tab
                key={parsers.getId(patient_step)}
                sx={{ textTransform: 'none' }}
                label={t(
                  'components.program-step-list.switch-modal.third-text',
                  { level: patient_step.position }
                )}
              />
            ))}
          </Tabs>

          {patient_steps.map((patient_step, index) => (
            <TabPanel
              key={parsers.getId(patient_step)}
              value={selected_tab}
              index={index}
              sx={{ px: 2, textAlign: 'center', width: 1 }}
            >
              {switch_infos.map((info, infos_index) => {
                const value = info.value;
                const isTextValue = isNaN(value.toString().trim());

                return (
                  <FlexBox width={1} py={1} key={index + '.' + infos_index}>
                    <Typography
                      align='center'
                      weight='bold'
                      variant='body2'
                      gutterBottom={isTextValue}
                    >
                      {info.title}
                    </Typography>

                    {isTextValue ? (
                      <Typography align='center' variant='body2'>
                        {value}
                      </Typography>
                    ) : (
                      <Slider
                        patient_step={1}
                        min={0}
                        max={5}
                        value={parseInt(value)}
                        marks={true}
                        disabled={true}
                      />
                    )}
                  </FlexBox>
                );
              })}
            </TabPanel>
          ))}
        </FlexBox>

        {(!current_step ||
          selected_step.position !== current_step.position) && (
          <>
            <Divider sx={{ width: 1 }} />

            <FlexBox sx={{ position: 'relative' }}>
              <FlexBox
                sx={{ position: 'absolute', left: 0, height: 1, width: 1 }}
              >
                <FlexBox color='lightGrey' sx={{ border: 0.1, height: 1 }} />
              </FlexBox>

              <FlexBox sx={{ p: 1, flexDirection: 'row', width: 1 }}>
                <Button
                  color='text.primary'
                  data-purpose='close'
                  fullWidth
                  label={t(
                    'components.program-step-list.switch-modal.buttons.cancel.label'
                  )}
                  onClick={onClose}
                  size='large'
                  variant='text'
                />

                <Button
                  data-purpose='confirm'
                  fullWidth
                  label={t(
                    'components.program-step-list.switch-modal.buttons.switch.label'
                  )}
                  size='large'
                  variant='text'
                  onClick={() =>
                    setState({
                      confirm_modal: {
                        open: true,
                        primary: {
                          onClick: () => {
                            API.patch({
                              path: 'patient/program_steps/${id}',
                              data: {
                                id: parsers.getId(selected_step, true),
                                status: 'in progress',
                              },
                            })
                              .then(({ data: patient_program_step }) => {
                                queries.setQueriesData({
                                  API,
                                  query_client,
                                  patient_program_step,
                                });

                                queries.invalidateQueriesOf({
                                  API,
                                  query_client,
                                  patient_program_step: current_step,
                                });
                              })
                              .then(() => {
                                setState({ confirm_modal: { open: false } });
                                onChange?.();
                              });
                          },
                        },
                      },
                    })
                  }
                />
              </FlexBox>
            </FlexBox>
          </>
        )}
      </Drawer>
    </>
  );
};

const AdviseButton = ({ patient_program_step, color }) => {
  const [advise_modal, setState] = useStateWrapper(
    useState({
      open: false,
      title: (
        <FlexBox>
          <Svg component={TipsIcon} height='6rem' />
        </FlexBox>
      ),
    })
  );

  return (
    <>
      <Modal {...advise_modal} />

      <Button
        size='large'
        color={color}
        variant='outlined'
        disabled={!patient_program_step}
        onClick={() => {
          setState({
            open: true,
            description: (
              <Typography
                paragraph
                variant='body2'
                align='justify'
                sx={{ whiteSpace: 'pre-line' }}
              >
                {patient_program_step?.program_step.advise}
              </Typography>
            ),
            primary: {
              label: t(
                'components.program-step-list.buttons.advise.buttons.close.label'
              ),
            },
            onClose: () => setState({ open: false }),
          });
        }}
      >
        <Typography
          variant='caption'
          sx={{ fontWeight: 'bold', borderRadius: 100 }}
        >
          {t('components.program-step-list.buttons.advise.label')}
        </Typography>
      </Button>
    </>
  );
};

export const ProgramStepsList = ({
  program,
  patient_program,
  detailed,
  title,
  sx,
  ...rest
}) => {
  const { API } = useAPI();
  const query_client = useQueryClient();
  const color = program ? formatters.slugify(program.category) : 'midGrey';
  const [{ step_switch_modal }, setState] = useStateWrapper(
    useState({ step_switch_modal: { open: false } })
  );

  /**
   * Fetch {patient_program_steps} only if there is {patient_program}.
   */
  const { data: patient_program_steps } = useQuery(
    [
      queries.names.patient_program_steps_of,
      { id: patient_program ? parsers.getId(patient_program, true) : null },
    ],
    ({ queryKey: [, { id }] }) =>
      id
        ? API.get({
            path: 'patient/program_steps',
            filters: { patient_program: id },
          })
            .then(({ data }) => data['hydra:member'])
            .then((patient_program_steps) =>
              patient_program_steps.map((patient_program_step) => {
                /**
                 * Prepare the cache for next queries.
                 */
                queries.setQueriesData({
                  API,
                  query_client,
                  patient_program_step,
                  clear: false,
                });

                return patient_program_step;
              })
            )
        : null
  );

  /**
   * Fetch {program_steps} only if there is no {patient_program}.
   */
  const { data: program_steps } = useQuery(
    [
      'program_steps_of',
      { id: patient_program === null ? program?.['@id'] : null },
    ],
    ({ queryKey: [, { id }] }) =>
      id
        ? API.get({ path: 'program_steps', filters: { program: id } }).then(
            ({ data }) => data['hydra:member']
          )
        : null
  );

  /**
   * Format rows.
   */
  const rows = patient_program_steps?.map((patient_program_step) => ({
    patient_program_step,
    program_step: patient_program_step.program_step,
  })) ??
    program_steps?.map((program_step) => ({ program_step })) ?? [{}, {}, {}];

  return (
    <>
      {(patient_program_steps ?? []).length > 1 && (
        <SwitchModal
          patient_steps={patient_program_steps}
          open={step_switch_modal.open}
          onChange={() => setState({ step_switch_modal: { open: false } })}
          onClose={() => setState({ step_switch_modal: { open: false } })}
          current_index={step_switch_modal.index}
        />
      )}

      <FlexBox
        sx={[
          { alignItems: 'inherit', width: 1 },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
      >
        <FlexBox sx={{ alignItems: 'inherit', mb: 1 }}>
          <Typography variant='h3' color='midGrey' sx={{ textAlign: 'left' }}>
            {title}
          </Typography>
        </FlexBox>

        <FlexBox sx={{ maxWidth: 1, width: 1, alignItems: 'inherit' }}>
          {rows.map(({ patient_program_step, program_step }, index) => {
            const partially_detailed =
              patient_program_step?.status == 'in progress' && !detailed;

            const onClick = !(partially_detailed || detailed)
              ? () => setState({ step_switch_modal: { open: true, index } })
              : undefined;

            return (
              <FlexBox
                key={index}
                sx={{ mb: detailed ? 4 : 0, width: 1, alignItems: 'inherit' }}
              >
                <ProgramStepItem
                  background={color}
                  detailed={detailed}
                  hide_position={rows.length == 1}
                  onClick={onClick}
                  patient_program_step={patient_program_step}
                  position={index + 1}
                  program={program}
                  program_step={program_step}
                  sx={{
                    mb: partially_detailed ? 2 : 0,
                    mt: partially_detailed && index > 0 ? 2 : 0,
                  }}
                  advise={
                    partially_detailed &&
                    patient_program_step?.program_step.advise && (
                      <AdviseButton
                        color={color}
                        patient_program_step={patient_program_step}
                      />
                    )
                  }
                />

                {!detailed && <Divider sx={{ width: 1 }} />}
              </FlexBox>
            );
          })}
        </FlexBox>
      </FlexBox>
    </>
  );
};

const ProgramStepItem = ({
  advise,
  background,
  detailed,
  hide_position,
  onClick,
  patient_program_step,
  position,
  program,
  program_step,
  sx,
  ...rest
}) => {
  const is_premium = !program?.is_restricted;
  const navigate = useNavigate();
  const query_client = useQueryClient();
  const { API } = useAPI();

  /**
   * Fetch {patient_workouts} only for {patient_program_step} in progress.
   */
  const { data: patient_workouts } = useQuery(
    [
      queries.names.patient_workouts_of,
      {
        id: patient_program_step
          ? parsers.getId(patient_program_step, true)
          : null,
      },
    ],
    ({ queryKey: [, { id }] }) =>
      id
        ? API.get({
            path: 'patient/workouts',
            filters: { patient_program_step: id },
          })
            .then(({ data }) => data['hydra:member'])
            .then((patient_workouts) =>
              patient_workouts.map((patient_workout) => {
                /**
                 * Prepare the cache for next queries.
                 */
                queries.setQueriesData({
                  API,
                  query_client,
                  patient_workout,
                  clear: false,
                });

                return patient_workout;
              })
            )
        : null
  );

  /**
   * Fetch {workouts} only when there is no {patient_program_step}.
   */
  const { data: workouts = [null, null, null] } = useQuery(
    [
      'workouts_of',
      {
        id:
          !patient_program_step && program_step
            ? parsers.getId(program_step, true)
            : null,
      },
    ],
    ({ queryKey: [, { id }] }) =>
      id
        ? API.get({ path: 'workouts', filters: { program_step: id } }).then(
            ({ data }) => data['hydra:member']
          )
        : [null, null, null]
  );

  const rows =
    patient_workouts?.map((patient_workout, index) => (
      <WorkoutListItem
        key={index}
        duration={patient_workout.workout.duration}
        level={position}
        status={
          patient_workout.status == 'todo' &&
          new Date(patient_workout.status_date).getTime() > Date.now()
            ? 'available'
            : patient_workout.status
        }
        is_restricted={patient_workout.is_restricted}
        position={index + 1}
        background={background}
        data-purpose={`navigate_to_${
          !is_premium ? 'subscriptions' : 'patient_workout_details'
        }`}
        onClick={() =>
          navigate(
            !is_premium
              ? '/subscriptions'
              : `/my-programs/${parsers.getId(
                  patient_program_step.patient_program,
                  true
                )}/steps/${parsers.getId(
                  patient_program_step,
                  true
                )}/workouts/${parsers.getId(patient_workout, true)}`,
            { replace: true }
          )
        }
      />
    )) ??
    workouts.map((workout, index) => (
      <WorkoutListItem
        key={index}
        duration={workout?.duration}
        level={position}
        status={
          !workout
            ? undefined
            : position == 1 && index + 1 == 1
            ? 'available'
            : 'locked'
        }
        is_restricted={workout?.is_restricted}
        background={background}
        data-purpose={`navigate_to_${
          !is_premium ? 'subscriptions' : 'new_patient_program_details'
        }`}
        position={index + 1}
        onClick={() =>
          workout &&
          queries
            .createPatientProgram({
              API,
              query_client,
              program: program_step.program,
            })
            .then((patient_program) =>
              navigate(
                !is_premium
                  ? '/subscriptions'
                  : `/my-programs/${parsers.getId(
                      patient_program,
                      true
                    )}/steps/${parsers.getId(
                      patient_program.next_patient_workout_todo
                        .patient_program_step,
                      true
                    )}/workouts/${parsers.getId(
                      patient_program.next_patient_workout_todo,
                      true
                    )}`,
                { replace: true }
              )
            )
        }
      />
    ));

  const as_card = onClick ?? null;

  return (
    <FlexBox
      sx={[
        { alignItems: 'inherit', width: 1 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <ProgramStepResume
        Component={as_card ? CardItem : Fragment}
        onClick={onClick}
      >
        <FlexBox
          sx={{
            width: 1,
            flexDirection: 'row',
            ...(as_card ? { pb: 2, pt: position > 1 ? 2 : 0 } : { mb: 2 }),
          }}
        >
          {!hide_position && (
            <FlexBox sx={{ alignItems: 'flex-start' }}>
              <FlexBox sx={{ alignItems: 'baseline', flexDirection: 'row' }}>
                {patient_program_step && (
                  <Trans
                    i18nKey='components.program-steps-list.program-step-item.first-text'
                    values={{ position, total_workouts: rows.length ?? 10 }}
                    components={{
                      C_A: (
                        <Typography
                          component='span'
                          size='16'
                          placeholder='10em'
                          sx={{ fontWeight: 'bold', textAlign: 'left' }}
                        />
                      ),
                      C_B: (
                        <Typography
                          component='span'
                          weight='normal'
                          size='16'
                          placeholder='8rem'
                        />
                      ),
                    }}
                  />
                )}

                {!patient_program_step && (
                  <Typography size='16' placeholder='12rem' />
                )}
              </FlexBox>

              {(!program_step || program_step.duration) && (
                <FlexBox sx={{ alignItems: 'inherit' }}>
                  <Typography variant='caption' placeholder='11em'>
                    <Duration data={program_step?.duration ?? {}} />
                  </Typography>
                </FlexBox>
              )}
            </FlexBox>
          )}

          {advise ? (
            <FlexBox sx={{ alignItems: 'flex-end', flexGrow: 1 }}>
              {advise}
            </FlexBox>
          ) : null}
        </FlexBox>
      </ProgramStepResume>

      {detailed && (
        <WorkoutExercisesList
          workout={workouts?.[0]}
          patient_workout={patient_workouts?.[0]}
          sx={{ mb: 2 }}
        />
      )}

      {!as_card &&
        (detailed && patient_program_step ? (
          <CardItem
            arrow='center'
            border={1}
            color='lightGrey'
            sx={{ borderRadius: 2, width: 1 }}
            data-purpose={`navigate_to_${
              !is_premium ? 'subscriptions' : 'patient_workout_details'
            }`}
            onClick={() =>
              navigate(
                !is_premium
                  ? '/subscriptions'
                  : `/my-programs/${parsers.getId(
                      patient_program_step.patient_program,
                      true
                    )}`
              )
            }
          >
            <FlexBox
              color='midGrey'
              sx={{ alignItems: 'flex-start', width: 1, p: 2 }}
            >
              <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                {t(
                  'components.program-steps-list.program-step-item.second-text'
                )}
              </Typography>
            </FlexBox>
          </CardItem>
        ) : (
          <ListItems items={rows} />
        ))}
    </FlexBox>
  );
};

export default ProgramStepsList;
