import CancelIcon from '@mui/icons-material/Cancel';
import { t } from 'i18next';

import {
  Button,
  FlexBox,
  Svg,
  Typography,
  useCommonState,
} from '@weasyo/react';
import { service_worker } from '@weasyo/react/lib/utilities';

import { ErrorEmailToButton, Template } from '~/src/components';

const Page500 = () => {
  useCommonState({ page_title: t('content.error.500.title') });

  /**
   * Redirect.
   * Call {s.w} error handler & use vanilla JS redirection instead of ones from the Router.
   */
  const redirect = () => {
    /**
     * TODO should handled by {@weasyo/react.error_boundary}.
     */
    navigator?.serviceWorker?.ready.then((registration) =>
      registration.active.postMessage({
        action: service_worker.ACTIONS.ON_ERROR,
      })
    );

    const url = new URL('/', window.location.href);
    window.location.replace(url);
  };

  return (
    <Template>
      <Svg component={CancelIcon} color='primary' width='6rem' />

      <Typography variant='h1'>{t('content.error.500.first-text')}</Typography>

      <Button
        size='large'
        fullWidth
        onClick={redirect}
        data-purpose='navigate_to_home'
        data-details='from_page_error_500'
        label={t('content.error.500.buttons.navigate-to-home.label')}
      />

      <FlexBox>
        <Typography>{t('content.error.500.second-text')}</Typography>

        <Typography>
          <ErrorEmailToButton debug={{ error: '500' }} />
        </Typography>
      </FlexBox>
    </Template>
  );
};

export default Page500;
