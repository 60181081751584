import { EmailToButton } from '@weasyo/react';
import { t } from 'i18next';

export const ErrorEmailToButton = ({ sx, ...props }) => (
  <EmailToButton
    variant='text'
    color='primary'
    content={t('emails.to-support.problem.content')}
    context={t('emails.to-support.problem.context')}
    subject={t('emails.to-support.problem.subject')}
    sx={[{ fontSize: 'inherit' }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}
  />
);

export default ErrorEmailToButton;
