import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect, Fragment } from 'react';
import { Divider } from '@mui/material';
import {
  StarRounded as StarIcon,
  CheckRounded as CheckIcon,
} from '@mui/icons-material';
import {
  Button,
  CardItem,
  FlexBox,
  Svg,
  Thumbnail,
  Typography,
  useAPI,
  useCommonState,
  useSubscriptions,
  withSubscriptions,
  withTemplateCheckpoint,
} from '@weasyo/react';
import { WeasyoFullLogo } from '@weasyo/react/lib/images';

import girl_on_swiss_ball_picture_url from '~/src/assets/images/pictures/girl_on_swiss_ball.webp';
import body_select_cover_url from '~/src/assets/images/pictures/body_select_cover.webp';
import {
  Header as DefaultHeader,
  ProgramsList,
  Navigation,
  HtmlParser,
} from '~/src/components';

const waves_illustration = new URL(
  '~/src/assets/images/svg/illustrations/waves.svg',
  import.meta.url
);

const expected_questions = [
  'is-onboarding-done',
  'is-communication-email-accepted',
];

const PremiumButtonWithSubscription = () => {
  const { t } = useTranslation();
  const { actives } = useSubscriptions();
  const is_premium = actives ? !!actives.length : undefined;
  const navigate = useNavigate();

  return (
    <Button
      initial={{ y: -200 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', delay: 0.1, stiffness: 120 }}
      whileHover={{ scale: 1.0 }}
      whileTap={{ scale: 0.9 }}
      onClick={() =>
        navigate(is_premium ? '/profile/subscription' : '/subscriptions')
      }
      startIcon={
        <Svg component={StarIcon} color='yellow' fontSize={'medium'} />
      }
      variant={is_premium ? 'outlined' : 'contained'}
      color={is_premium ? 'primary' : undefined}
      background={is_premium ? undefined : 'cta'}
      disabled={is_premium === undefined}
      data-purpose={`navigate_to_${
        is_premium ? 'subscriptions' : 'profile_subscription'
      }`}
    >
      <Typography
        variant='caption'
        color={is_premium ? undefined : 'cta.contrastText'}
        sx={{ fontWeight: 'bold' }}
      >
        {t('content.authenticated.index.premium-button.label.is-premium', {
          context: is_premium ? 'true' : 'false',
        })}
      </Typography>
    </Button>
  );
};

const PremiumCard = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { matching_platform, actives, internals } = useSubscriptions();
  const first_highlighted_subscription = (matching_platform ?? internals)?.[0];

  if (
    actives?.length ||
    (!first_highlighted_subscription && !internals?.length)
  )
    return null;

  /**
   * TODO add new {product.resolved_translation} prop logical into {useSubscriptions}.
   * Retrieve data related to first "highlighted subscription" matching current language.
   */
  const resolved_translation = (
    first_highlighted_subscription?.product?.translations ?? []
  ).find(({ language }) => language.code_alpha_2 === i18n.resolvedLanguage);

  return (
    <FlexBox {...props}>
      <Thumbnail
        sx={{
          borderRadius: 2,
          width: 1,
          boxShadow: 3,
          height: 1,
          '.WeasyoCardItem-media img': {
            width: 'calc(100% + 11rem)',
            maxWidth: 'min-content',
          },
        }}
        background='primary.light'
        data-purpose='navigate_to_subscriptions'
        image={girl_on_swiss_ball_picture_url}
        onClick={() => navigate(`/subscriptions`)}
      >
        <FlexBox sx={{ height: 1, width: 1, justifyContent: 'space-between' }}>
          <FlexBox sx={{ p: 2, width: 1, alignItems: 'flex-start' }}>
            <FlexBox sx={{ mb: 2, alignItems: 'inherit' }}>
              {resolved_translation?.description?.slogan ? (
                <Typography
                  sx={{ textAlign: 'left', fontWeight: 'bold' }}
                  size={34}
                  color='primary'
                >
                  {resolved_translation.description.slogan}
                </Typography>
              ) : (
                <Trans
                  i18nKey='cards.become-premium.slogan'
                  components={{
                    C_A: <Typography size={22} />,
                    C_B: (
                      <Typography
                        sx={{ fontWeight: 900 }}
                        size={34}
                        line_height={34}
                        color='primary'
                      />
                    ),
                  }}
                />
              )}
            </FlexBox>

            <FlexBox sx={{ alignItems: 'self-start' }}>
              {resolved_translation?.description?.short_resume ? (
                <HtmlParser
                  data={resolved_translation.description.short_resume}
                  sx={{
                    '*': {
                      fontSize: (theme) => theme.typography.pxToRem(14),
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    },
                  }}
                />
              ) : (
                t('cards.become-premium.short-resume', {
                  returnObjects: true,
                }).map((sentence, index) => (
                  <FlexBox sx={{ flexDirection: 'row' }} key={index}>
                    <FlexBox sx={{ mr: 1 }}>
                      <Svg
                        component={CheckIcon}
                        width='1rem'
                        color='primary'
                        stroke_width={3}
                      />
                    </FlexBox>

                    <Typography variant='body1'>{sentence}</Typography>
                  </FlexBox>
                ))
              )}
            </FlexBox>
          </FlexBox>

          {resolved_translation?.offers?.days_trial_period && (
            <FlexBox
              background='cta'
              color='white'
              sx={{
                flexDirection: 'row',
                justifyContent: 'center',
                px: 1.5,
                py: 1,
                width: 1,
              }}
            >
              <FlexBox sx={{ flexDirection: 'row' }}>
                <Svg component={StarIcon} fontSize='large' color='yellow' />

                <FlexBox sx={{ mx: 0.5 }}>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                    variant='h2'
                  >
                    {t('cards.become-premium.nb-trial-period', {
                      count: resolved_translation.offers.days_trial_period,
                    })}
                  </Typography>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>
      </Thumbnail>
    </FlexBox>
  );
};

const BodySelectCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <FlexBox
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ delay: 0.7, duration: 0.7 }}
      sx={{ mt: 2, mb: 4, height: '24rem', width: 1 }}
    >
      <CardItem
        sx={{ borderRadius: 2, width: 1, height: 1, boxShadow: 3 }}
        arrow='none'
        background='pink'
        data-purpose='navigate_to_zones'
        onClick={() => navigate('/zones')}
      >
        <FlexBox
          color='white'
          sx={{
            alignItems: 'start',
            backgroundImage: `url(${waves_illustration.toString()})`,
            backgroundPosition: 'center 85%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60em',
            flexDirection: 'row',
            height: 'inherit',
            justifyContent: 'space-between',
            width: 1,
          }}
        >
          <FlexBox sx={{ p: 2, pr: 0, alignItems: 'flex-start' }}>
            <Typography
              color='inherit'
              size={27}
              gutterBottom
              sx={{
                textAlign: 'left',
                textTransform: 'capitalize',
                fontWeight: '400',
              }}
            >
              {t('cards.body-select.first-text')}
            </Typography>

            <Typography sx={{ textAlign: 'left', color: 'inherit' }}>
              {t('cards.body-select.second-text')}
            </Typography>

            <Typography
              sx={{ textAlign: 'left', whiteSpace: 'pre', color: 'inherit' }}
            >
              {t('cards.body-select.third-text')}
            </Typography>
          </FlexBox>

          <FlexBox
            sx={{
              alignSelf: 'flex-end',
              backgroundImage: `url(${body_select_cover_url})`,
              backgroundPosition: 'left 2rem',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: 1,
              width: 1,
            }}
          />
        </FlexBox>
      </CardItem>
    </FlexBox>
  );
};

const Header = withSubscriptions(() => (
  <DefaultHeader
    title={
      <Svg
        component={WeasyoFullLogo}
        viewBox='0 0 24 12'
        width='5.5rem'
        height='2.65rem'
      />
    }
    sx={{
      '.Header-container': { px: 2, pt: 2 },
      '.Header-hideable-content': { px: 2 },
      '.Header-content': { p: 0, width: 'auto' },
    }}
    extra={<PremiumButtonWithSubscription />}
    scrolled={{
      text: (
        <Svg
          component={WeasyoFullLogo}
          viewBox='0 0 24 12'
          width='5.5rem'
          height='2.65rem'
        />
      ),
      extra: <PremiumButtonWithSubscription />,
    }}
  />
));

const Index = () => {
  const { t } = useTranslation();
  const { API } = useAPI();
  const navigate = useNavigate();
  useCommonState({ page_title: t('glossary.home') }); // i18next-extract-disable-line

  /**
   * Fetch {program_categories}.
   */
  const { data: program_categories } = useQuery(['program_categories'], () =>
    API.get({ path: 'program_categories' }).then(({ data }) =>
      data?.['hydra:member'].map((category) => category.value)
    )
  );

  /**
   * Fetch {questions_responses}.
   */
  const { data: questions_responses, isStale: is_question_responses_stale } =
    useQuery(
      ['questions_responses', { 'question.slug': expected_questions }],
      ({ queryKey: [, filters] }) =>
        API.get({ path: 'questions_responses', filters }).then(
          ({ data }) => data?.['hydra:member']
        )
    );

  /**
   * Check if expected questions has been answered,
   * otherwise trigger redirection logical.
   */
  useEffect(() => {
    if (!questions_responses || is_question_responses_stale) return;

    let redirect_to_onboarding =
      questions_responses.length < expected_questions.length;

    if (!redirect_to_onboarding) {
      questions_responses.forEach(({ question, response }) => {
        switch (question.slug) {
          case 'is-onboarding-done':
            if (response !== 1) {
              redirect_to_onboarding = true;
            }
            break;

          default:
            if (response === undefined) {
              redirect_to_onboarding = true;
            }
            break;
        }
      });
    }

    if (redirect_to_onboarding) {
      navigate('/onboarding', { replace: true });
      return;
    }
  }, [questions_responses, is_question_responses_stale]);

  return (
    <>
      <FlexBox sx={{ my: 2, alignItems: 'flex-start', width: 1 }}>
        <Trans
          i18nKey='content.authenticated.index.catch-phrases.app-creators'
          components={{
            C_A: (
              <Typography
                sx={{ textAlign: 'left', fontWeight: 'light' }}
                component='span'
                size={30}
                line_height={30}
              />
            ),
            C_B: (
              <Typography
                component='span'
                transform='none'
                color='primary'
                size={30}
                line_height={30}
                sx={{ textAlign: 'left', fontWeight: 'bold' }}
              />
            ),
          }}
        />
      </FlexBox>

      <Divider sx={{ width: 1 }} />

      <BodySelectCard />

      {(program_categories ?? [null, null, null]).map((category, index) => (
        <Fragment key={index}>
          <ProgramsList {...{ category }} sx={{ mb: 3, width: 1 }} />

          {(index === 1 ||
            (program_categories ?? [null, null, null]).length == 1) && (
            <PremiumCard sx={{ mb: 4, height: '24rem', width: 1 }} />
          )}
        </Fragment>
      ))}
    </>
  );
};

export default withTemplateCheckpoint(
  Index,
  { inheritance: 'default', id: 'index-authenticated' },
  { header: <Header />, footer: <Navigation /> }
);
