import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import {
  Button,
  FlexBox,
  Modal,
  Typography,
  device,
  useDevice,
  useDialog,
  useProduct,
  useSubscriptions,
  withProductModal,
  withSubscriptions,
} from '@weasyo/react';

import { UserSubscriptionProduct } from '~/src/components';

const Subscription = () => {
  /**
   * TODO: Use {localStorage.data} logical only, instead of {data} URL parameter.
   * !! {data} doesn't respect http max length.
   * !! It currently works, thanks to Service Worker that catch the request.
   * !! Its a trick until Android app handle {localStorage} (cf require Webview instead of TWA).
   */
  const { action, data } = useParams();
  const [product_ctx, productDispatch] = useProduct();
  const {
    actives: active_subscriptions,
    is_fresh_data,
    refetch: refetchUserSubscriptions,
  } = useSubscriptions();
  const [modal, setModal] = useState({ open: false });
  const [, setDialog] = useDialog();
  const navigate = useNavigate();
  const current_device = useDevice();
  const platform_with_store = [
    device.os.ios.toLowerCase(),
    device.os.android.toLowerCase(),
  ].includes(current_device.os.name.toLowerCase());

  const handleRestore = () =>
    window.location.assign(
      product_ctx.helpers.restoreURI({
        success_uri: '/profile/subscription/restore',
      })
    );

  /**
   * TODO Remove when {data} URL parameter will be deprecated for localStorage.
   * Removes {data} from the URL.
   */
  useEffect(() => {
    if (!data) return;

    productDispatch('store_data', { data });
    window.history.replaceState(null, null, '/profile/subscription');
  }, []);

  /**
   * Dispatches "restore" action.
   */
  useEffect(() => {
    if (product_ctx.error || !product_ctx.data) return;

    switch (action) {
      case 'restore':
        productDispatch('restore');
        break;
    }
  }, [action, product_ctx.data]);

  /**
   * Handles "restoration" dialog.
   */
  useEffect(() => {
    if (!product_ctx.success || !product_ctx.restore) return;

    refetchUserSubscriptions();

    setDialog({
      open: true,
      severity: product_ctx.success.has_restored_purchases ? 'success' : 'info',
      /* i18next-extract-mark-context-next-line ["true", "false"] */
      message: t(
        'content.authenticated.profile.subscription.subscription.dialogs.restore.subscription-found',
        {
          context: product_ctx.success.has_restored_purchases
            ? 'true'
            : 'false',
        }
      ),
    });
  }, [product_ctx.success, product_ctx.restore]);

  /**
   * Displays "no one subscription" modal.
   */
  useEffect(() => {
    if (
      !is_fresh_data ||
      product_ctx.is_loading ||
      active_subscriptions.length ||
      product_ctx.error
    )
      return;

    setModal({
      open: true,
      disable_nav_blocker: true,
      title: t('modals.no-one-active-user-subscription.title'),
      description: t('modals.no-one-active-user-subscription.description'),
      primary: {
        label: t('modals.no-one-active-user-subscription.primary.label'),
        onClick: () => navigate('/subscriptions'),
      },
      onClose: () => navigate('/'),
      ...(platform_with_store
        ? {
            sub_description: t(
              'modals.no-one-active-user-subscription.sub-description'
            ),
            secondary: {
              label: t(
                'modals.no-one-active-user-subscription.secondary.label'
              ),
              onClick: handleRestore,
            },
          }
        : {}),
    });
  }, [
    active_subscriptions,
    is_fresh_data,
    product_ctx.error,
    product_ctx.is_loading,
  ]);

  return (
    <>
      <Modal {...modal} />

      <FlexBox
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          maxWidth: '25em',
          width: 1,
        }}
      >
        {(active_subscriptions ?? [{}]).map((subscription, index) => (
          <FlexBox sx={{ mb: 2, width: 1 }} key={index}>
            <UserSubscriptionProduct highlighted={true} {...subscription} />
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox sx={{ mt: 2, mb: 1 }}>
        <Button
          background='white'
          color='text.secondary'
          size='large'
          data-purpose='navigate_to_subscriptions'
          onClick={() => navigate('/subscriptions')}
          sx={{ boxShadow: 1 }}
        >
          <Typography variant='body2'>
            {t(
              'content.authenticated.profile.subscription.subscription.buttons.display-subscriptions.label'
            )}
          </Typography>
        </Button>
      </FlexBox>

      {platform_with_store && (
        <FlexBox sx={{ my: 2 }}>
          <Button
            variant='link'
            color='text.secondary'
            background='white'
            size='small'
            data-purpose='restore_purchases'
            onClick={handleRestore}
          >
            <Typography variant='caption'>
              {t(
                'content.authenticated.profile.subscription.subscription.buttons.restore.label'
              )}
            </Typography>
          </Button>
        </FlexBox>
      )}
    </>
  );
};

export default withProductModal(
  withSubscriptions(Subscription, { fresh_data: true })
);
