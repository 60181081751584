import { useTheme } from '@emotion/react';
import { Typography, FlexBox, parsers } from '@weasyo/react';

/**
 * Handle color logical between "background", "font", and border
 */
export const Capsule = ({ background, children, color, text, sx, ...rest }) => {
  const has_content = text || children;
  const theme = useTheme();
  const tmp_color = color
    ? parsers.theme(theme).palette.get({ path: color, main: false })
    : undefined;

  /**
   * Guess font color depending on {color/background}.
   */
  const final_background_color = background
    ? parsers.theme(theme).palette.get({ path: background })
    : tmp_color && 'string' !== typeof tmp_color
    ? tmp_color.light
    : 'currentColor';

  return (
    <FlexBox
      background={final_background_color}
      sx={[
        {
          borderRadius: has_content ? 1 : 20,
          justifyContent: 'center',
          overflow: 'hidden',
          py: 0,
          px: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...(color ? { color } : {})}
      {...rest}
    >
      {has_content && (
        <>
          {typeof text == 'string' && (
            <Typography
              sx={{ textAlign: 'center', fontWeight: 'bold' }}
              variant='caption'
            >
              {text}
            </Typography>
          )}

          {children}
        </>
      )}
    </FlexBox>
  );
};

export default Capsule;
