import { t } from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FlexBox,
  Typography,
  parsers,
  useAPI,
  useCommonState,
  useDialog,
  usePrefill,
} from '@weasyo/react';

import { FlexForm } from '~/src/components';

export const AccountActivation = () => {
  useCommonState({
    page_title: t('content.anyone.demands.account-activation.title'),
  });
  const navigate = useNavigate();
  const [, setDialog] = useDialog();
  const { API } = useAPI();
  const { set: prefill, get: getPrefill } = usePrefill();

  const onSubmit = ({ email }, form) =>
    API.post({
      path: 'anonymous/demands/status',
      data: { email, status: 'enabled' },
      onError: (api_response) => {
        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            formik,
            violations: api_response.error.data?.violations,
            t,
          });

          if (errors.all.status?.data?.value == '"enabled"') {
            setDialog({
              open: true,
              message: t(
                'content.anyone.demands.account-activation.dialogs.account-already-enabled.message',
                { email }
              ),
            });

            prefill({ identifier: '/login', data: { email } });

            navigate('/logout');
            return;
          }

          if (errors.all.email?.code == 'email-not-found') {
            setDialog({
              severity: 'warning',
              open: true,
              message: t(
                'content.anyone.demands.account-activation.dialogs.email-not-found.message',
                { email }
              ),
            });

            return;
          }

          if (Object.entries(errors.out_scope).length == 0) return;
        }

        throw api_response;
      },
    })
      .then(() => {
        setDialog({
          open: true,
          message: t(
            'content.anyone.demands.account-activation.dialogs.success.message',
            { email }
          ),
        });

        prefill({ identifier: '/login', data: { email } });

        navigate('/logout');
      })
      .finally(() => form.setSubmitting(false));

  const validation_schema = Yup.object().shape({
    // i18next-extract-disable-next-line
    email: Yup.string().email().required(t('glossary.required')),
  });

  const formik = useFormik({
    onSubmit,
    validationSchema: validation_schema,
    initialValues: getPrefill({
      identifier: '/demands/account/activation',
      keys: Object.keys(validation_schema.fields),
      initial: Object.fromEntries(
        Object.keys(validation_schema.fields).map((name) => [name, ''])
      ),
    }),
  });

  return (
    <>
      <Typography variant='h1' paragraph>
        {t('content.anyone.demands.account-activation.first-text')}
      </Typography>

      <FlexBox>
        <Typography paragraph>
          {t('content.anyone.demands.account-activation.second-text')}
        </Typography>

        <FlexForm sx={{ width: 1 }} onSubmit={formik.handleSubmit}>
          <TextField
            id='email-input'
            autoComplete='email'
            label={t('glossary.email')} //i18next-extract-disable-line
            type='email'
            name='email'
            fullWidth
            margin='normal'
            helperText={formik.errors.email}
            error={formik.errors.email && formik.touched.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />

          <Button
            sx={{ my: 2 }}
            data-purpose='submit'
            disabled={!formik.isValid || formik.isSubmitting}
            fullWidth
            size='large'
            type='submit'
            label={t(
              'content.anyone.demands.account-activation.buttons.submit.label.is-in-progress',
              { context: formik.isSubmitting ? 'true' : 'false' }
            )}
          />
        </FlexForm>
      </FlexBox>
    </>
  );
};

export default AccountActivation;
