import { LinearProgress } from '@mui/material';
import { t } from 'i18next';
import { FlexBox, Svg, Typography } from '@weasyo/react';
import { WeasyoLogo } from '@weasyo/react/lib/images';

export const Launcher = ({ sx, ...rest }) => (
  <FlexBox
    color='primary'
    background='white'
    sx={[
      { width: 1, height: 1, justifyContent: 'center' },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
  >
    <Svg component={WeasyoLogo} height={'5rem'} />

    <FlexBox sx={{ mt: 2 }}>
      <Typography sx={{ fontWeight: 'medium' }}>
        {t('components.workout-player.launcher.label')}
      </Typography>

      <FlexBox sx={{ mt: 2, width: 1 }}>
        <LinearProgress sx={{ borderRadius: 100, width: 'inherit' }} />
      </FlexBox>
    </FlexBox>
  </FlexBox>
);

export default Launcher;
