import { Avatar, IconButton } from '@mui/material';
import { KeyboardBackspace as BackIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { BackButton, Header as BaseHeader, Svg, parsers } from '@weasyo/react';
import { WeasyoFullLogo } from '@weasyo/react/lib/images';

export const Header = ({
  sx,
  border_color = 'primary',
  title,
  children,
  extra,
  back_button,
  scrolled,
  ...rest
}) => {
  const navigate = useNavigate();

  /**
   * Handle "back button" display.
   */
  if (back_button === true) {
    back_button = (
      <BackButton>
        <Avatar sx={{ backgroundColor: 'white.main', boxShadow: 1 }}>
          <Svg color='text.secondary' component={BackIcon} />
        </Avatar>
      </BackButton>
    );
    scrolled = scrolled ?? {};
    scrolled.back_button = scrolled.back_button ?? (
      <BackButton>
        <Svg color='text.secondary' component={BackIcon} />
      </BackButton>
    );
  } else {
    back_button =
      back_button !== undefined
        ? back_button
        : extra && (title || title !== null)
        ? null
        : false;
  }

  return (
    <BaseHeader
      extra={extra}
      back_button={back_button}
      scrolled={scrolled}
      sx={[
        {
          '& .ScrollWrapper-root': {
            borderColor: (theme) =>
              parsers.theme(theme).palette.get({ path: border_color }),
            borderStyle: 'solid',
            borderWidth: 0,
            borderBottomWidth: '0.25rem',
          },
        },
        title === undefined && { '.Header-content': { py: 0 } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      title={
        title ||
        (title !== null && (
          <IconButton onClick={() => navigate('/')}>
            <Svg
              component={WeasyoFullLogo}
              viewBox='0 0 24 12'
              width='5.5rem'
              height='2.65rem'
            />
          </IconButton>
        ))
      }
      {...rest}
    >
      {children}
    </BaseHeader>
  );
};

export default Header;
