import { useState } from 'react';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  FlexBox,
  Modal,
  PasswordInput,
  Typography,
  parsers,
  useAPI,
  useCommonState,
  useDialog,
  useStateWrapper,
} from '@weasyo/react';

import { FlexForm } from '~/src/components';

/**
 * Manages email update confirmation.
 */
export const EmailUpdate = () => {
  useCommonState({
    page_title: t('content.anyone.confirm.email-update.title'),
  });
  const { email, token } = useParams();
  const navigate = useNavigate();
  const [, setDialog] = useDialog();
  const { API } = useAPI();
  const [{ modal }] = useStateWrapper(useState({ modal: { open: false } }));
  const onSubmit = (data, form) =>
    API.patch({
      path: 'anonymous/demands/email',
      data: { ...data, email, token },
      is_token_related: true,
      with_authorization: false,
      onError: (api_response) => {
        /**
         * Set form errors messages otherwise keep throwing exception.
         */
        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            formik,
            violations: api_response.error.data?.violations,
            t,
          });

          if (errors.all.token) {
            setDialog({
              severity: 'warning',
              open: true,
              message: t(
                'content.anyone.confirm.email-update.dialogs.expired-demand.message'
              ),
              label: t(
                'content.anyone.confirm.email-update.dialogs.expired-demand.label'
              ),
              onClick: () => {
                setDialog({ open: false });
                navigate('/profile/edit/email');
              },
            });

            return;
          }

          if (Object.entries(errors.out_scope).length == 0) return;
        }

        throw api_response;
      },
    })
      .then(() => {
        setDialog({
          severity: 'success',
          open: true,
          message: t(
            'content.anyone.confirm.email-update.dialogs.success.message'
          ),
        });

        navigate('/');
      })
      .finally(() => form.setSubmitting(false));

  const formik = useFormik({
    onSubmit,
    initialValues: { password: '' },
    validationSchema: Yup.object().shape({
      // i18next-extract-disable
      password: Yup.string()
        .required(t('glossary.required'))
        .min(8, t('validation.length.min', { count: 8 }))
        .matches(/[0-9]+/, t('validation.regex./[0-9]+/')),
      // i18next-extract-enable
    }),
  });

  return (
    <>
      <Modal {...modal} />

      <Typography variant='h1' paragraph>
        {t('content.anyone.confirm.email-update.first-text')}
      </Typography>

      <FlexBox sx={{ width: 1 }}>
        <Typography paragraph>
          {t('content.anyone.confirm.email-update.second-text')}
        </Typography>

        <FlexForm sx={{ width: 1 }} onSubmit={formik.handleSubmit}>
          <PasswordInput
            id='password-input'
            autoComplete='email'
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            label={t('glossary.password')} // i18next-extract-disable-line
            name='password'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.errors.password && formik.touched.password}
            helperText={formik.errors.password}
          />

          <Button
            sx={{ my: 2 }}
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            fullWidth
            size='large'
            type='submit'
            label={t(
              'content.anyone.confirm.email-update.buttons.submit.label.is-in-progress',
              { context: formik.isSubmitting ? 'true' : 'false' }
            )}
          />
        </FlexForm>
      </FlexBox>
    </>
  );
};

export default EmailUpdate;
