import { useState } from 'react';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import {
  EmailToButton,
  FlexBox,
  Modal,
  Typography,
  useDevice,
  useProduct,
  useStateWrapper,
  useNavigationBlocker,
} from '@weasyo/react';

import { SubscriptionCard } from '~/src/components';

/**
 * TODO: move this logical into {@weasyo/react}.
 */
export const SubscriptionProduct = ({
  callback_uris,
  subscription: {
    highlighted,
    product,
    date_switch,
    is_active,
    is_purchaseable,
    required_platform,
  },
  ...rest
}) => {
  const navigate = useNavigate();
  const navigation_blocker = useNavigationBlocker();
  const device = useDevice();
  const [product_ctx] = useProduct();
  const [modal, setModal] = useStateWrapper(
    useState({ open: false, onClose: () => setModal({ open: false }) })
  );

  /**
   * Handle subscription logical.
   */
  const handleOnClick = () => {
    //  i18next-extract-disable
    let i18next_key = 'modals.subscription-product';

    if (is_active) {
      if (!date_switch) {
        i18next_key = `${i18next_key}.active-as-user-subscription`;

        if (product?.status === 'published') {
          i18next_key = `${i18next_key}.already-subscribed`;
        } else {
          i18next_key = `${i18next_key}.outdated`;
        }

        setModal({
          open: true,
          title: t(`${i18next_key}.title`),
          description: t(`${i18next_key}.description`),
          sub_description: t(`${i18next_key}.sub-description`),
          primary: {
            label: t(`${i18next_key}.primary.label`),
            onClick: () => navigate('/profile/subscription'),
          },
          secondary: { label: t(`${i18next_key}.secondary.label`) },
        });

        return;
      }
    }

    if (!is_purchaseable) {
      if (required_platform) {
        i18next_key = `${i18next_key}.active-as-user-subscription`;

        switch (required_platform) {
          case 'web':
          case 'internal':
            i18next_key = `${i18next_key}.related-to-external-platform`;

            setModal({
              open: true,
              title: t(`${i18next_key}.title`),
              description: (
                <Trans
                  i18nKey={`${i18next_key}.description`}
                  components={{
                    C_A: <Typography color='text.secondary' gutterBottom />,
                    C_B: <Typography gutterBottom />,
                  }}
                />
              ),
              sub_description: (
                <FlexBox width={1}>
                  <EmailToButton
                    variant='text'
                    color='text.primary'
                    context={t(
                      'emails.to-support.change-user-subscription.context'
                    )}
                    subject={t(
                      'emails.to-support.change-user-subscription.subject'
                    )}
                    debug={{
                      subscription: {
                        product: product.slug,
                        date_switch,
                        is_active,
                        is_purchaseable,
                        required_platform,
                      },
                    }}
                  />
                </FlexBox>
              ),
              secondary: { label: t(`${i18next_key}.secondary.label`) },
              primary: (
                <EmailToButton
                  fullWidth
                  variant='text'
                  sx={{ p: 2 }}
                  context={t(
                    'emails.to-support.change-user-subscription.context'
                  )}
                  subject={t(
                    'emails.to-support.change-user-subscription.subject'
                  )}
                  label={t('emails.to-support.change-user-subscription.label')}
                  debug={{
                    subscription: {
                      product: product.slug,
                      date_switch,
                      is_active,
                      is_purchaseable,
                      required_platform,
                    },
                  }}
                />
              ),
            });

            return;

          default:
            i18next_key = `${i18next_key}.related-to-another-platform`;

            setModal({
              open: true,
              title: t(`${i18next_key}.title`),
              description: t(`${i18next_key}.description`, {
                platform: required_platform,
              }),
              primary: {
                label: t(`${i18next_key}.primary.label`),
                onClick: () => setModal({ open: false }),
              },
              secondary: undefined,
            });

            return;
        }
      }
      i18next_key = `${i18next_key}.outdated`;

      setModal({
        open: true,
        title: t(`${i18next_key}.title`),
        description: t(`${i18next_key}.description`),
        primary: {
          label: t(`${i18next_key}.primary.label`),
          onClick: () => setModal({ open: false }),
        },
        secondary: undefined,
      });

      return;
    }

    switch (device?.os?.name?.toLowerCase()) {
      case 'ios':
      case 'android':
        navigation_blocker.unblock();

        window.location.replace(
          product_ctx.helpers.subscribeURI({
            ...callback_uris,
            product: product.slug,
          })
        );

        break;
    }
    // i18next-extract-enable
  };

  return (
    <>
      <Modal {...modal} />

      <SubscriptionCard
        onClick={handleOnClick}
        highlighted={highlighted}
        product={product}
        is_active={is_active}
        with_details={true}
        {...rest}
      />
    </>
  );
};

export default SubscriptionProduct;
