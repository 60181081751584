import { css } from '@emotion/react';
import { t } from 'i18next';
import { useCommonState, withTemplateCheckpoint } from '@weasyo/react';

import { Header, Router } from '~/src/components';
import girl_on_swiss_ball_picture_url from '~/src/assets/images/pictures/girl_on_swiss_ball.webp';
import Subscription from './Subscription';
import Success from './Success';

/**
 * Routes available for authenticated user.
 */
export const Routes = () => {
  useCommonState({
    page_title: t('content.authenticated.profile.subscription.routes.title'),
  });

  return (
    <Router>
      <Subscription id='/' />
      <Subscription id='/:action' />
      <Subscription id='/:action/:data' />
      <Success id='/purchase/success' />
    </Router>
  );
};
export default withTemplateCheckpoint(
  Routes,
  { inheritance: 'default' },
  {
    header: (
      <Header
        back_button
        title={t('content.authenticated.profile.subscription.routes.title')}
      />
    ),
    content_style: css`
      background: transparent;
    `,
    page_style: css`
      ::before {
        background-image: url(${girl_on_swiss_ball_picture_url});
        background-position: calc(30%);
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        height: 92%;
        max-width: inherit;
        position: absolute;
        bottom: 2rem;
        width: 100%;
      }
    `,
  }
);
