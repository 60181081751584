import { useCommonState } from '@weasyo/react';
import { t } from 'i18next';

import { Router } from '~/src/components';
import Index from './Index';
import Form from './Form';

export const Routes = () => {
  useCommonState({
    page_title: t('content.anonymous.sign-up.routes.title'),
  });

  return (
    <Router>
      <Index path='/' />
      <Form path='/form' />
    </Router>
  );
};

export default Routes;
