import { Svg, BottomNavigation } from '@weasyo/react';
import { HomeIcon, ProgramsIcon, ProfileIcon } from '@weasyo/react/lib/images';
import { useTranslation } from 'react-i18next';

const WrappedHomeIcon = <Svg component={HomeIcon} />;
const WrappedProgramsIcon = <Svg component={ProgramsIcon} />;
const WrappedProfileIcon = <Svg component={ProfileIcon} />;

export const Navigation = () => {
  const { t, i18n } = useTranslation();

  const mapping = (value) => [
    {
      is_selected: value == '/',
      value: '/',
      to: '/',
      label: t('components.navigation.buttons.home.label'),
      icon: WrappedHomeIcon,
    },
    {
      is_selected: value.startsWith('/my-programs'),
      value: '/my-programs',
      to: '/my-programs/lists/in-progress',
      label: t('components.navigation.buttons.my-programs.label'),
      icon: WrappedProgramsIcon,
    },
    {
      is_selected: value.startsWith('/profile'),
      value: '/profile',
      to: '/profile',
      label: t('components.navigation.buttons.profile.label'),
      icon: WrappedProfileIcon,
    },
  ];

  return <BottomNavigation key={i18n.resolvedLanguage} mapping={mapping} />;
};

export default Navigation;
