import {
  Redirect,
  withDestinationURLParamHandler,
  withServiceWorkerUpdateCheck,
  withSoftwareUpdateCheck,
  withTemplateCheckpoint,
} from '@weasyo/react';

import { Router } from '~/src/components';
import Page404 from '~/src/content/error/404';
import Index from './Index';
import Login from './Login';
import OAuthResponse from './OAuthResponse';
import SignUp from './sign-up/Routes';

/**
 * Make routes "aware" of service worker and software updates.
 */
const SignUpWithNotifications = withSoftwareUpdateCheck(
  withServiceWorkerUpdateCheck(SignUp)
);
const LoginWithNotifications = withSoftwareUpdateCheck(
  withServiceWorkerUpdateCheck(Login)
);
const IndexWithNotifications = withSoftwareUpdateCheck(
  withServiceWorkerUpdateCheck(Index)
);

/**
 * Make 404 redirecting to "/" page
 * with a "destination" URL param set to the unsuccessful page URL.
 */
const NotFoundWithDestinationHandler = withDestinationURLParamHandler(Page404, {
  redirect_to: '/',
});

/**
 * Routes available for anonymous user.
 */
export const RoutesWithCheckpoint = withTemplateCheckpoint(
  () => (
    <Router>
      <NotFoundWithDestinationHandler path='*' />
      <LoginWithNotifications path='login' />
      <Redirect from='/logout' to='/' replace />
      <SignUpWithNotifications path='sign-up/*' />
    </Router>
  ),
  { inheritance: 'default' }
);

export const Routes = () => (
  <Router>
    <OAuthResponse path='oauth' />
    <RoutesWithCheckpoint path='*' />
    <IndexWithNotifications path='/' />
  </Router>
);

export default Routes;
