import {
  Header,
  Svg,
  withProductModal,
  withSubscriptions,
  withTemplateCheckpoint,
} from '@weasyo/react';
import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { css } from '@emotion/react';

import List from './List';
import { Router } from '~/src/components';
import cover_image_url from '~/src/assets/images/pictures/list_subscriptions_cover.webp';

const ListWithSubscriptionsAndProductModal = withProductModal(
  withSubscriptions(List, { fresh_data: true })
);

/**
 * Routes available for authenticated user.
 */
export const Routes = () => (
  <Router>
    <ListWithSubscriptionsAndProductModal id='/' />
    <ListWithSubscriptionsAndProductModal id='/:product/purchase/:action' />
    <ListWithSubscriptionsAndProductModal id='/:product/purchase/:action/:data' />
  </Router>
);

export default withTemplateCheckpoint(
  Routes,
  { inheritance: 'default' },
  {
    cover: {
      background: 'lightGrey',
      image: cover_image_url,
      height:
        'calc(min(var(--viewport-width), var(--app-max-width)) / calc(16 / 9))',
    },
    header: (
      <Header
        back_button={false}
        sx={{ position: 'absolute', zIndex: 'front_of_content' }}
        extra={
          <IconButton aria-label='close' disabled>
            <Svg color='text.secondary' component={CloseIcon} width={'2rem'} />
          </IconButton>
        }
      />
    ),
    content_style: css`
      padding: 0;
    `,
  }
);
