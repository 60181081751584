import { Router } from '~/src/components';
import Index from './Index';
import From from './from/Routes';

export const Routes = () => (
  <Router>
    <Index path=':email/:token' />
    <From path='from/*' />
  </Router>
);

export default Routes;
