import { useStorage, useAPI } from '@weasyo/react';

import { Template } from '~/src/components';
import Authenticated from '~/src/content/authenticated/Routes';
import Anonymous from '~/src/content/anonymous/Routes';
import Anyone from '~/src/content/anyone/Routes';

/**
 * Return routes depending on authentication.
 */
const Content = () => {
  const { authenticated } = useAPI();

  /**
   * @deprecated, handled by {@weasyo/react} under "core" data.
   * TODO remove when container dev will handled update.
   */
  useStorage({
    type: 'local',
    method: 'set',
    args: ['is_authenticated', authenticated],
  });

  return (
    <Template>
      <Anyone>
        {authenticated ? <Authenticated path='*' /> : <Anonymous path='*' />}
      </Anyone>
    </Template>
  );
};

export default Content;
