import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { t } from 'i18next';
import {
  parsers,
  useAPI,
  useDialog,
  useStateWrapper,
  withTemplateCheckpoint,
} from '@weasyo/react';

import {
  Header as DefaultHeader,
  RadioButtonsListQuestion,
} from '~/src/components';

const PhysicalActivity = () => {
  const navigate = useNavigate();
  const [, setDialog] = useDialog();
  const query_client = useQueryClient();
  const { API } = useAPI();
  const [{ question, is_submitted, selection }, setState] = useStateWrapper(
    useState({}),
    ['data', 'question']
  );

  /**
   * Fetch onboarding questions.
   */
  const { data: questions } = useQuery(
    ['questions', { 'question.item_type': 'onboarding' }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions', filters }).then(
        ({ data }) => data?.['hydra:member']
      )
  );

  /**
   * Fetch onboarding questions responses.
   */
  const { data: questions_responses } = useQuery(
    ['questions_responses', { 'question.item_type': 'onboarding' }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions_responses', filters }).then(
        ({ data }) => data?.['hydra:member']
      )
  );

  /**
   * Define question.
   */
  useEffect(() => {
    if (!questions) return;

    questions.map((question) => {
      if (question.slug != 'sport-frequence-activity') return;

      setState({ question: question });
    });
  }, [questions]);

  /**
   * Define current selection.
   */
  useEffect(() => {
    if (!questions_responses) return;
    questions_responses.map((question_response) => {
      if (question_response.question.slug != 'sport-frequence-activity') return;

      setState({ selection: question_response.response });
    });
  }, [questions_responses]);

  /**
   * Update response.
   */
  useEffect(() => {
    if (!is_submitted || !selection) return;

    API.post({
      path: 'questions_responses',
      data: { question: parsers.getId(question), response: selection },
    })
      .then(() => {
        setDialog({
          severity: 'success',
          open: true,
          message: t(
            'content.authenticated.profile.edit.physical-activity.dialogs.success.message'
          ),
        });
        navigate('/profile');
      })
      .then(() => query_client.invalidateQueries('questions_responses'));
  }, [is_submitted, selection]);

  const onChange = (selection) => setState({ selection, is_submitted: true });

  return (
    <RadioButtonsListQuestion
      label={t('questions.sport-frequence-activity.question')}
      value={selection}
      question={question}
      onChange={onChange}
    />
  );
};

const Header = () => (
  <DefaultHeader
    back_button
    overtitle={t(
      'content.authenticated.profile.edit.physical-activity.header.overtitle'
    )}
    title={t(
      'content.authenticated.profile.edit.physical-activity.header.title'
    )}
    scrolled={{
      text: t(
        'content.authenticated.profile.edit.physical-activity.header.scrolled.text'
      ),
    }}
  />
);

export default withTemplateCheckpoint(
  PhysicalActivity,
  { inheritance: 'default' },
  { header: <Header /> }
);
