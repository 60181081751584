import {
  Typography,
  FlexBox,
  CardItem,
  useAPI,
  useDevice,
  useCore,
} from '@weasyo/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const ExternalSubscriptionCard = ({ highlighted, sx, ...rest }) => {
  const { t } = useTranslation();
  const { API } = useAPI();
  const device = useDevice();
  const [{ container }] = useCore();

  const handleOnClick = () => {
    let os = device?.os?.name?.toLowerCase();
    os = ['android', 'ios'].includes(os) ? os : undefined;
    const data = JSON.stringify({
      origin: window.location.toString(),
      user: { email: profile.email },
    });
    const url = new URL(
      // i18next-extract-disable-next-line
      t('components.external-subscription-card.href', { context: os })
    );
    url.searchParams.set('referrer_data', data);

    const adjust = container?.adjust ?? null;

    Object.entries({
      utm_campaign: adjust?.tracker_name,
      utm_content: 'external_subscription',
      // eslint-disable-next-line no-undef
      utm_medium: `${process.env.W_APP_ID}-${container?.mode ?? 'website'}`,
      utm_source: adjust?.network,
    }).map(
      ([key, value]) =>
        !url.searchParams.has(key) && url.searchParams.set(key, value)
    );

    const location = url.toString();

    if (!window.open(location, '_blank')) window.location.assign(location);
  };

  /**
   * Fetch {profile}.
   */
  const { data: profile } = useQuery(['profile'], () =>
    API.get({ path: 'profile' }).then(({ data }) => data)
  );

  return (
    <CardItem
      arrow='center'
      {...(highlighted
        ? { border: 0, background: 'primary', color: 'white' }
        : { border: 0.75, color: 'primary' })}
      sx={[{ borderRadius: 2, width: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
      onClick={handleOnClick}
      {...rest}
    >
      <FlexBox
        sx={{
          alignItems: 'start',
          height: 1,
          p: 2,
          textAlign: 'left',
          width: 1,
        }}
      >
        <Typography
          align='inherit'
          variant='h4'
          letter_spacing={0.5}
          sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          {t('components.external-subscription-card.first-text')}
        </Typography>

        <Typography align='inherit' size={16}>
          {t('components.external-subscription-card.second-text')}
        </Typography>
      </FlexBox>
    </CardItem>
  );
};

export default ExternalSubscriptionCard;
