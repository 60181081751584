import { OAuthResponseHandler, useCommonState } from '@weasyo/react';
import { t } from 'i18next';

import { Loader } from '~/src/components';

const OAuthResponse = () => {
  useCommonState({ page_title: t('content.anonymous.oauth-response.title') });

  return (
    <OAuthResponseHandler>
      <Loader />
    </OAuthResponseHandler>
  );
};

export default OAuthResponse;
