import { useState } from 'react';
import {
  KeyboardArrowDownRounded as ArrowDownIcon,
  KeyboardArrowUpRounded as ArrowUpIcon,
} from '@mui/icons-material';
import { Button, FlexBox, Svg } from '@weasyo/react';

export const ListItems = ({
  min_listed = 3,
  items = [{}, {}, {}, {}],
  ...rest
}) => {
  const [full_listing, setFullListing] = useState(false);
  const handleOnClick = () => setFullListing((value) => !value);

  /**
   * Listing logical.
   * Define first & last element indexes to display.
   */
  const total_elements = items.length;

  let last_listed_index = total_elements - 1;
  let first_listed_index = 0;

  if (!full_listing && total_elements > min_listed) {
    const target_item_index = items.findIndex((workout) =>
      ['available', 'todo'].includes(workout?.props?.status)
    );
    const current_index = target_item_index != -1 ? target_item_index : 0;

    last_listed_index =
      current_index + 1 < total_elements ? current_index + 1 : current_index;

    first_listed_index =
      last_listed_index - min_listed < 0 ? 0 : last_listed_index - min_listed;

    while (
      last_listed_index - first_listed_index < min_listed - 1 &&
      last_listed_index < total_elements - 1
    ) {
      last_listed_index++;
    }

    while (last_listed_index - first_listed_index > min_listed - 1) {
      first_listed_index++;
    }
  }

  return (
    <FlexBox sx={{ width: 1, rowGap: 0.5 }} {...rest}>
      {items.map((item, index) => {
        if (index < first_listed_index || index > last_listed_index)
          return null;

        return (
          <FlexBox
            key={index}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.3, damping: 0 }}
            sx={{ width: 'inherit' }}
          >
            {item}
          </FlexBox>
        );
      })}

      {total_elements > min_listed && (
        <FlexBox sx={{ width: 'inherit' }}>
          <Button
            background='lightGrey.lightest'
            color='midGrey'
            data-purpose='toggle_list_display'
            fullWidth
            onClick={handleOnClick}
            sx={{ maxWidth: 1 }}
            variant={'text'}
          >
            <Svg
              color='lightGrey'
              component={full_listing ? ArrowUpIcon : ArrowDownIcon}
            />
          </Button>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default ListItems;
