import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { t } from 'i18next';
// import Confetti from 'react-confetti';
import {
  BackNavBlocker,
  Button,
  FlexBox,
  Svg,
  Typography,
  formatters,
  parsers,
  useAPI,
  useCommonState,
  withTemplateCheckpoint,
  useAudio,
} from '@weasyo/react';
import { MedalIcon } from '@weasyo/react/lib/images';

import { getRandomSentence } from '~/src/utilities';

const PatientWorkoutCompleted = ({ patient_program, onNavigate }) => {
  const { player: background_audio_player } = useAudio('common');
  const { API } = useAPI();
  const [motivation_sentence] = useState(
    getRandomSentence({
      type: 'congratulations',
      progression: 100,
      sentences: t('common.motivational-phrases', { returnObjects: true }),
    })
  );

  useCommonState({
    page_title: t(
      'content.authenticated.programs.workout-player.patient-workout-completed.title'
    ),
  });

  /**
   * Reduces progressively background audio volume on {unmount}.
   */
  useEffect(() => {
    background_audio_player.instance?.fade(1, 0, 3000);

    return () => background_audio_player.stop();
  }, [background_audio_player.instance]);

  /**
   * Fetch {profile}.
   */
  const { data: profile } = useQuery(['profile'], () =>
    API.get({ path: 'profile' }).then(({ data }) => data)
  );

  return (
    <>
      <BackNavBlocker mode='disabled' onBackNav={onNavigate} />

      {/* <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={500}
      /> */}

      <FlexBox
        sx={{
          flexGrow: 1,
          justifyContent: 'space-between',
          mt: 3,
          p: 2,
          width: 1,
        }}
      >
        <FlexBox>
          <Typography
            gutterBottom
            sx={{ textAlign: 'center', textTransform: 'capitalize' }}
            variant='h1'
          >
            {/* i18next-extract-mark-context-next-line ["with-user-name"] */}
            {t(
              'content.authenticated.programs.workout-player.patient-workout-completed.first-text',
              {
                context: profile?.first_name && 'with-user-name',
                user_name: profile?.first_name,
              }
            )}
          </Typography>

          <Typography sx={{ textAlign: 'center' }} size={16} gutterBottom>
            {t(
              'content.authenticated.programs.workout-player.patient-workout-completed.second-text'
            )}
          </Typography>
        </FlexBox>

        <Svg
          component={MedalIcon}
          height='30vh'
          sx={{
            'path:not(:first-of-type)': {
              fill: (theme) =>
                parsers.theme(theme).palette.get({
                  path: formatters.slugify(patient_program.program.category),
                }),
            },
          }}
        />
      </FlexBox>

      <FlexBox
        sx={{
          flexGrow: 1,
          justifyContent: 'space-evenly',
          p: 2,
          width: 1,
        }}
      >
        <FlexBox sx={{ my: 2 }}>
          <Typography size={18}>{motivation_sentence}</Typography>
        </FlexBox>

        <Button
          data-purpose='continue'
          fullWidth
          label={t(
            'content.authenticated.programs.workout-player.patient-workout-completed.buttons.continue.label'
          )}
          onClick={onNavigate}
          size='large'
        />
      </FlexBox>
    </>
  );
};

export default withTemplateCheckpoint(
  PatientWorkoutCompleted,
  { inheritance: 'default' },
  { header: null }
);
