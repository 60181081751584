{
  "src": [
    "./src/assets/audio/generated/common/audio.mp3"
  ],
  "sprite": {
    "silence": [
      0,
      5000,
      true
    ],
    "workout_background_music": [
      8000,
      591165.1473922902
    ]
  }
}