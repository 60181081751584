import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { LinearProgress } from '@mui/material';
import { t } from 'i18next';
import {
  formatters,
  parsers,
  useAPI,
  useCommonState,
  useStateWrapper,
  withAudio,
  withTemplateCheckpoint,
} from '@weasyo/react';

import { WorkoutPlayer } from '~/src/components';
import { queries } from '~/src/utilities';

import Quiz from './Quiz';
import PatientProgramStepCompleted from './PatientProgramStepCompleted';
import PatientWorkoutCompleted from './PatientWorkoutCompleted';

const SHOW_QUIZZ = 'quizz';
const SHOW_PATIENT_PROGRAM_STEP_COMPLETED = 'patient_program_step_completed';
const SHOW_PATIENT_WORKOUT_COMPLETED = 'patient_workout_step_completed';

const Index = () => {
  useCommonState({
    page_title: t('content.authenticated.programs.workout-player.index.title'),
  });
  const navigate = useNavigate();
  const { patient_program_id, patient_program_step_id, patient_workout_id } =
    useParams();
  const [
    { show, next_patient_workout_todo, patient_program_already_completed },
    setState,
  ] = useStateWrapper(useState({}));
  const { API } = useAPI();
  const query_client = useQueryClient();

  /**
   * Fetch {patient_program}.
   */
  const { data: patient_program } = useQuery(
    [queries.names.patient_programs, { id: patient_program_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({ path: 'patient/programs/${id}', data: { id } }).then(
        ({ data }) => data
      ),
    /**
     * TODO : remove this "staleTime: 0".
     * TODO : check if it still an issue since v2 upgrade.
     * - Added because of error after queries invalidation on iOS.
     */
    { staleTime: 0 }
  );

  /**
   * Fetch {patient_workout}.
   */
  const { data: patient_workout } = useQuery(
    [queries.names.patient_workouts, { id: patient_workout_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({ path: 'patient/workouts/${id}', data: { id } }).then(
        ({ data }) => data
      )
  );

  /**
   * Fetch {patient_program_steps}.
   */
  const { data: patient_program_steps } = useQuery(
    [queries.names.patient_program_steps_of, { id: patient_program_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({
        path: 'patient/program_steps',
        filters: { patient_program: id },
      }).then(({ data }) => data['hydra:member'])
  );

  const { data: patient_workout_exercises } = useQuery(
    ['patient_workout_exercises_of', { id: patient_workout_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({
        path: 'patient/workout_exercises',
        filters: { patient_workout: id, itemsPerPage: '100' },
      }).then(({ data }) => data['hydra:member'])
  );

  useEffect(() => {
    if (patient_program_already_completed != undefined || !patient_program)
      return;

    setState({
      patient_program_already_completed: patient_program.status == 'completed',
    });
  }, [patient_program]);

  if (!(patient_program && patient_workout && patient_workout_exercises))
    return <LinearProgress />;

  /**
   * Define next {program_step}.
   */
  const current_step_index = patient_program_steps?.findIndex(
    (patient_program_step) =>
      parsers.getId(patient_program_step, true) === patient_program_step_id
  );

  const next_patient_patient_step =
    (patient_program_steps ?? [])?.[current_step_index + 1] ?? null;

  const can_show_program_step_completed =
    !patient_program?.program?.professional &&
    patient_workout?.is_last &&
    !patient_program_already_completed;

  const navigateToNextPage = () => {
    const final_destination = `/my-programs/${patient_program_id}`;

    let intermediate_destination;

    /**
     * Should we redirect to "/subscriptions" or "/share" ?
     */
    if (next_patient_workout_todo?.is_restricted) {
      intermediate_destination = '/subscriptions';
    } else if (
      /**
       * "/share" is "triggered" "randomly" after the first completed workout.
       */
      patient_workout.position > 1 &&
      Math.ceil(Math.random() * 5) > 3
    ) {
      intermediate_destination = '/share';
    }

    /**
     * Redirect to {intermediate_destination} then {final_destination}.
     */
    if (intermediate_destination) {
      return navigate(intermediate_destination, {
        state: { next_path: final_destination },
        replace: true,
      });
    }

    /**
     * Redirect to {final_destination}.
     */
    navigate(final_destination, { replace: true });
  };

  switch (show) {
    case SHOW_PATIENT_WORKOUT_COMPLETED:
      return (
        <PatientWorkoutCompleted
          patient_program={patient_program}
          onNavigate={() => {
            if (patient_program.program.category === 'health') {
              return setState({ show: SHOW_QUIZZ });
            }

            if (can_show_program_step_completed) {
              return setState({ show: SHOW_PATIENT_PROGRAM_STEP_COMPLETED });
            }

            navigateToNextPage();
          }}
        />
      );

    case SHOW_QUIZZ:
      return (
        <Quiz
          patient_workout_id={patient_workout_id}
          onNavigate={() => {
            if (can_show_program_step_completed) {
              setState({ show: SHOW_PATIENT_PROGRAM_STEP_COMPLETED });
              return;
            }

            navigateToNextPage();
          }}
        />
      );

    case SHOW_PATIENT_PROGRAM_STEP_COMPLETED:
      return (
        <PatientProgramStepCompleted
          next_position={next_patient_patient_step?.position}
          onNavigate={navigateToNextPage}
        />
      );

    default:
      return (
        <WorkoutPlayer
          back_nav_path={`/my-programs/${parsers.getId(
            patient_program,
            true
          )}/steps/${parsers.getId(
            patient_workout.patient_program_step,
            true
          )}/workouts/${parsers.getId(patient_workout, true)}`}
          items={patient_workout_exercises}
          color={formatters.slugify(patient_program.program.category)}
          onEnd={() =>
            API.patch({
              path: 'patient/workouts/${id}',
              data: { id: patient_workout_id, status: 'completed' },
            }).then(async ({ data: patient_workout }) => {
              queries.invalidateQueriesOf({
                query_client,
                patient_program,
                patient_workout,
              });

              const updated_patient_program =
                await queries.fetchPatientPrograms({
                  query_client,
                  patient_program,
                });

              if (patient_workout.is_last) {
                patient_program.patient_program_steps.map(
                  (patient_program_step) => {
                    queries.invalidateQueriesOf({
                      query_client,
                      patient_program_step,
                    });
                  }
                );
              }

              setState({
                show: SHOW_PATIENT_WORKOUT_COMPLETED,
                next_patient_workout_todo:
                  updated_patient_program?.next_patient_workout_todo,
              });
            })
          }
        />
      );
  }
};

export default withTemplateCheckpoint(
  withAudio(Index),
  { inheritance: 'default' },
  { header: null }
);
