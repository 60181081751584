import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import {
  Button,
  FlexBox,
  PasswordInput,
  Typography,
  parsers,
  useAPI,
  useCommonState,
  useDialog,
  useURLParams,
} from '@weasyo/react';

import { FlexForm, Terms } from '~/src/components';

export const Index = () => {
  useCommonState({
    page_title: t(
      'content.anyone.confirm.sign-up.from.professional.index.title'
    ),
  });
  const navigate = useNavigate();
  const { email, token } = useParams();
  const { setParams: setURLParams } = useURLParams();
  const [, setDialog] = useDialog();
  const { API } = useAPI();

  const onSubmit = (data, form) =>
    API.patch({
      path: 'anonymous/demands/sign-up/from/invitation',
      is_token_related: true,
      with_authorization: false,
      data: { email, token, ...data },
      onError: (api_response) => {
        /**
         * Set form errors messages otherwise keep throwing exception.
         */
        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            formik,
            violations: api_response.error.data?.violations,
            t,
          });

          if (errors.all.status?.data?.value == '"enabled"') {
            setDialog({
              open: true,
              message: t(
                'content.anyone.confirm.sign-up.from.professional.index.dialogs.account-already-enabled.message',
                { email }
              ),
              label: t(
                'content.anyone.confirm.sign-up.from.professional.index.dialogs.account-already-enabled.label'
              ),
              onClick: () => {
                setDialog({ open: false });

                setURLParams({
                  destination: '/my-programs',
                  web_app: { prefill: { page: '/login', data: { email } } },
                });
              },
            });

            return;
          }

          if (errors.all.token) {
            setDialog({
              severity: 'warning',
              open: true,
              message: t(
                'content.anyone.confirm.sign-up.from.professional.index.dialogs.expired-demand.message'
              ),
              label: t(
                'content.anyone.confirm.sign-up.from.professional.index.dialogs.expired-demand.label'
              ),
              onClick: () => {
                setDialog({ open: false });

                setURLParams({
                  web_app: {
                    prefill: {
                      page: '/demands/account/activation',
                      data: { email },
                    },
                  },
                });

                navigate('/demands/account/activation');
              },
            });

            return;
          }

          if (Object.entries(errors.out_scope).length == 0) return;
        }

        throw api_response;
      },
    })
      .then(() => {
        setDialog({
          severity: 'success',
          open: true,
          message: t(
            'content.anyone.confirm.sign-up.from.professional.index.dialogs.success.message',
            { email }
          ),
        });

        navigate('/my-programs');
      })
      .finally(() => form.setSubmitting(false));

  const formik = useFormik({
    initialValues: {
      email,
      first_name: '',
      last_name: '',
      new_password: '',
      confirm_new_password: '',
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      // i18next-extract-disable
      first_name: Yup.string()
        .required(t('glossary.required'))
        .matches(/[a-zA-Z]+/, t('validation.regex./[a-zA-Z]+/')),
      last_name: Yup.string()
        .required(t('glossary.required'))
        .matches(/[a-zA-Z]+/, t('validation.regex./[a-zA-Z]+/')),
      new_password: Yup.string()
        .required(t('glossary.required'))
        .min(8, t('validation.length.min', { count: 8 })),
      // .matches(/[0-9]+/, t('validation.regex./[0-9]+/')),
      confirm_new_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], t('validation.do-not-match'))
        .required(t('glossary.required')),
      // i18next-extract-enable
    }),
  });

  return (
    <>
      <Typography variant='h1' paragraph>
        {t('content.anyone.confirm.sign-up.from.professional.index.first-text')}
      </Typography>

      <FlexForm sx={{ my: 2, width: 1 }} onSubmit={formik.handleSubmit}>
        {/* i18next-extract-disable */}

        <TextField
          id='email-input'
          label={t('glossary.email')}
          type='email'
          name='email'
          fullWidth
          margin='normal'
          helperText={formik.errors.email}
          error={formik.errors.email && formik.touched.email}
          value={formik.values.email}
          disabled={true}
        />

        <TextField
          id='first-name-input'
          autoComplete='given-name'
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          label={t('glossary.first-name')}
          type='text'
          name='first_name'
          fullWidth
          margin='normal'
          helperText={formik.errors.first_name}
          error={formik.errors.first_name && formik.touched.first_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.first_name}
        />

        <TextField
          id='last-name-input'
          autoComplete='family-name'
          label={t('glossary.last-name')}
          type='text'
          name='last_name'
          fullWidth
          margin='normal'
          helperText={formik.errors.last_name}
          error={formik.errors.last_name && formik.touched.last_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.last_name}
        />

        <PasswordInput
          id='new_password-input'
          autoComplete='new-password'
          label={t('glossary.password')}
          name='new_password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.new_password}
          error={formik.errors.new_password && formik.touched.new_password}
          helperText={formik.errors.new_password}
        />

        <PasswordInput
          id='confirm_new_password-input'
          autoComplete='new-password'
          label={t('common.confirm-password')}
          name='confirm_new_password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirm_new_password}
          error={
            formik.errors.confirm_new_password &&
            formik.touched.confirm_new_password
          }
          helperText={formik.errors.confirm_new_password}
        />

        {/* i18next-extract-enable */}

        <Button
          sx={{ my: 2 }}
          disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
          fullWidth
          type='submit'
          size='large'
          label={t(
            'content.anyone.confirm.sign-up.from.professional.index.buttons.submit.label.is-in-progress',
            { context: formik.isSubmitting ? 'true' : 'false' }
          )}
        />
      </FlexForm>

      <FlexBox sx={{ my: 2 }}>
        <Terms />
      </FlexBox>
    </>
  );
};

export default Index;
