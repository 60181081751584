import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { FlexBox, Models, parsers, useAPI } from '@weasyo/react';
import model_back_picture_url from '@weasyo/react/lib/images/pictures/model_back.webp';
import model_front_picture_url from '@weasyo/react/lib/images/pictures/model_front.webp';

import { ZoneCard } from '~/src/components';
import { fillZonesChildren } from '~/src/utilities';

export const BodySelect = ({
  selected_zone,
  selected_parent_zone,
  onZoneChange,
  onParentZoneChange,
  sx,
  ...rest
}) => {
  const { API } = useAPI();
  const [current_selected_parent_zone, setParentZoneSlug] = useState();
  const onZoneClick = (zone) => {
    if (zone.children && zone.children.length > 0) {
      setParentZoneSlug(zone.slug);
      onParentZoneChange && onParentZoneChange(zone);
      return;
    }

    onZoneChange && onZoneChange(zone);
  };

  /**
   * Fetch {zones}.
   */
  const { data: zones } = useQuery(['zones'], () =>
    API.get({ path: 'zones', filters: { itemsPerPage: 100 } }).then(
      ({ data }) => fillZonesChildren(data['hydra:member'])
    )
  );

  /**
   * Sync passed "selected parent zone".
   */
  useEffect(() => {
    if (current_selected_parent_zone != selected_parent_zone) {
      setParentZoneSlug(selected_parent_zone);
    }
  }, [selected_parent_zone]);

  useEffect(() => {
    if (!selected_zone || !zones || current_selected_parent_zone) return;

    zones.find(
      (zone) =>
        zone.slug == selected_zone &&
        zones.find((parent_zone) => {
          if (parsers.getId(parent_zone) == parsers.getId(zone.parent)) {
            setParentZoneSlug(parent_zone.slug);
            return true;
          }
          return false;
        })
    );
  }, [zones, selected_zone]);

  const sub_zones = !zones
    ? [null, null]
    : ((zones ?? []).find((x) => x.slug == current_selected_parent_zone) ?? {})
        .children;

  if (current_selected_parent_zone && sub_zones && sub_zones?.length) {
    return (
      <FlexBox
        sx={[
          {
            flexDirection: 'row',
            flexWrap: 'wrap',
            height: 1,
            justifyContent: 'space-evenly',
            width: 1,
            columnGap: 2,
            rowGap: 2,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
      >
        {sub_zones.map((zone, index) => (
          <ZoneCard
            key={index}
            zone={zone}
            onClick={zone ? () => onZoneChange(zone) : undefined}
          />
        ))}
      </FlexBox>
    );
  }

  return (
    <Models
      zones={zones?.filter((zone) => !zone.parent)}
      selected_zones={[selected_zone, current_selected_parent_zone].filter(
        (zone) => zone
      )}
      onZoneClick={onZoneClick}
      back_image={model_back_picture_url}
      front_image={model_front_picture_url}
    />
  );
};

export default BodySelect;
