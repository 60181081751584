import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import * as Yup from 'yup';
import {
  Button,
  FlexBox,
  PasswordInput,
  Typography,
  parsers,
  useAPI,
  useDialog,
  withTemplateCheckpoint,
} from '@weasyo/react';

import { FlexForm, Header as DefaultHeader } from '~/src/components';

const PasswordUpdate = () => {
  const { API } = useAPI();
  const navigate = useNavigate();
  const [, setDialog] = useDialog();
  const [demand_password_reset_in_progress, demandPasswordReset] =
    useState(false);

  const onSubmit = (data, form) =>
    !Promise.resolve()
      .then(
        async () =>
          !demand_password_reset_in_progress &&
          (await API.patch({
            path: 'profile/password',
            data,
            onError: (api_response) => {
              if (api_response?.error?.response?.status == 422) {
                const errors = parsers.violations({
                  formik,
                  violations: api_response.error.data?.violations,
                  t,
                });

                if (Object.entries(errors.out_scope).length == 0) return;
              }

              throw api_response;
            },
          }).then(() => {
            setDialog({
              open: true,
              severity: 'success',
              message: t(
                'content.authenticated.profile.edit.password.dialogs.update.success.message'
              ),
            });

            navigate('/profile');
          }))
      )
      .finally(() => form.setSubmitting(false));

  const formik = useFormik({
    onSubmit,
    initialValues: {
      password: '',
      new_password: '',
      new_password_confirmation: '',
    },
    // i18next-extract-disable
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(t('glossary.required'))
        .min(8, t('validation.length.min', { count: 8 }))
        .matches(/[0-9]+/, t('validation.regex./[0-9]+/')),
      new_password: Yup.string()
        .required(t('glossary.required'))
        .min(8, t('validation.length.min', { count: 8 }))
        .matches(/[0-9]+/, t('validation.regex./[0-9]+/')),
      new_password_confirmation: Yup.string()
        .oneOf([Yup.ref('new_password'), null], t('validation.do-not-match'))
        .required(t('glossary.required')),
    }),
    // i18next-extract-enable
  });

  /**
   * Send reset demand.
   */
  useEffect(() => {
    if (!demand_password_reset_in_progress) return;

    API.get({ path: 'profile' })
      .then(({ data }) =>
        API.post({
          path: 'anonymous/demands/password',
          data: { email: data.email },
        }).then(() => data)
      )
      .then(({ email }) => {
        setDialog({
          open: true,
          message: t(
            'content.authenticated.profile.edit.password.dialogs.update.email-sended.message',
            { email }
          ),
        });
        navigate('/profile');
      })
      .finally(() => demandPasswordReset(false));
  }, [demand_password_reset_in_progress]);

  return (
    <>
      <Typography>
        {t('content.authenticated.profile.edit.password.first-text')}
      </Typography>

      <FlexForm
        sx={{ textAlign: 'left', width: 1 }}
        onSubmit={formik.handleSubmit}
      >
        {/* i18next-extract-disable */}

        <PasswordInput
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          id='password-input'
          label={t('common.current-password')}
          name='password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.errors.password && formik.touched.password}
          helperText={formik.errors.password}
        />

        <FlexBox sx={{ width: 1, alignItems: 'flex-end' }}>
          <Button
            variant='link'
            color='text.secondary'
            onClick={() => demandPasswordReset(true)}
            label={t(
              'content.authenticated.profile.edit.password.buttons.reset-password.label',
              'mot de passe oublié ?'
            )}
          />
        </FlexBox>

        <PasswordInput
          sx={{ mt: 2 }}
          id='new-password-input'
          label={t('common.new-password')}
          autoComplete='new-password'
          name='new_password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.new_password}
          error={formik.errors.new_password && formik.touched.new_password}
          helperText={formik.errors.new_password}
        />

        <PasswordInput
          sx={{ mt: 2 }}
          id='new-password-confirmation-input'
          label={t('glossary.confirmation')}
          name='new_password_confirmation'
          autoComplete='new-password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.new_password_confirmation}
          error={
            formik.errors.new_password_confirmation &&
            formik.touched.new_password_confirmation
          }
          helperText={formik.errors.new_password_confirmation}
        />

        <Button
          sx={{ my: 2 }}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          fullWidth
          type='submit'
          size='large'
          label={t('common.is-saving', {
            context: formik.isSubmitting ? 'true' : 'false',
          })}
        />

        {/* i18next-extract-enable */}
      </FlexForm>
    </>
  );
};

const Header = () => (
  <DefaultHeader
    back_button
    overtitle={t(
      'content.authenticated.profile.edit.password.header.overtitle'
    )}
    title={t('content.authenticated.profile.edit.password.header.title')}
    scrolled={{
      text: t(
        'content.authenticated.profile.edit.password.header.scrolled.text'
      ),
    }}
  />
);

export default withTemplateCheckpoint(
  PasswordUpdate,
  { inheritance: 'default' },
  { header: <Header /> }
);
