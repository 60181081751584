import { css } from '@emotion/react';
import { FlexBox } from '@weasyo/react';

export { CircularProgress } from '@mui/material';

const Circle = ({ sx, ...props }) => (
  <FlexBox
    border={1.5}
    color='inherit'
    sx={[
      {
        animation: 'ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite',
        borderRadius: 100,
        position: 'absolute',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);

const ripple_keyframes = css`
  @keyframes ripple {
    0% {
      top: 45%;
      left: 45%;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 45%;
      left: 45%;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 45%;
      left: 45%;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 90%;
      height: 90%;
      opacity: 0;
    }
  }
`;

export const RippleLoader = ({ sx, ...rest }) => (
  <FlexBox
    width={'4rem'}
    height={'4rem'}
    color='primary'
    sx={[{ position: 'relative' }, ...(Array.isArray(sx) ? sx : [sx])]}
    css={ripple_keyframes}
    {...rest}
  >
    <Circle />

    <Circle sx={{ animationDelay: '-0.5s' }} />
  </FlexBox>
);

export default RippleLoader;
