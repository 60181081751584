import { useEffect } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import {
  CloseRounded as CloseIcon,
  HelpOutlineRounded as HelpIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Svg,
  FlexBox,
  Typography,
  useCounter,
  useInterval,
  parsers,
} from '@weasyo/react';

import { ProgressBar, Capsule } from '~/src/components';

export const TopButtons = ({ onClose, onHelp, with_text }) => {
  const { t } = useTranslation();

  return (
    <>
      {onHelp && (
        <FlexBox
          sx={[
            with_text && { pt: 1.2, px: 1.5 },
            {
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 'front_of_content',
              '& > button.help-button': { display: 'flex' },
            },
          ]}
        >
          {with_text && (
            <Button
              className='help-button'
              data-purpose='display_help'
              variant='text'
              onClick={onHelp}
              endIcon={<Svg component={HelpIcon} fontSize='large' />}
            >
              <Typography varian='caption'>
                {t(
                  'components.workout-player.dashboard.buttons.explanations.label'
                )}
              </Typography>
            </Button>
          )}

          {!with_text && (
            <IconButton data-purpose='display_help' onClick={onHelp}>
              <Svg component={HelpIcon} fontSize='large' />
            </IconButton>
          )}
        </FlexBox>
      )}

      {onHelp && (
        <FlexBox
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 'front_of_content',
          }}
        >
          <IconButton
            aria-label='close'
            data-purpose='close_workout'
            onClick={onClose}
          >
            <Svg component={CloseIcon} fontSize='large' />
          </IconButton>
        </FlexBox>
      )}
    </>
  );
};

export const BottomInfos = ({
  advise,
  item_index,
  items,
  duration,
  color,
  pause,
  onTimerChange,
  ...rest
}) => {
  const remaining_time = Math.ceil(
    useCounter({ pause, initial_value: duration, add: -1 })
  );

  const progression = Math.max(
    ((item_index +
      Math.min(useInterval({ pause }) / 1000, duration) / duration) /
      items.length) *
      100,
    ((remaining_time - duration) * 100) / duration
  );

  useEffect(() => {
    onTimerChange && onTimerChange(remaining_time);
  }, [remaining_time]);

  return (
    <FlexBox {...rest}>
      <FlexBox sx={{ width: 1, mb: 1, flexDirection: 'row' }}>
        <FlexBox sx={{ flexBasis: '75%', alignItems: 'flex-start' }}>
          {advise && (
            <Capsule
              sx={{ borderRadius: 20, px: 4, py: 2 }}
              background='dark.lightest'
            >
              <Typography sx={{ fontWeight: 'bold' }}>{advise}</Typography>
            </Capsule>
          )}
        </FlexBox>

        <FlexBox sx={{ flexBasis: '25%', alignItems: 'flex-end' }}>
          <Typography size={38} sx={{ fontWeight: 'bold' }}>
            {new Date(Math.max(remaining_time, 0) * 1000)
              .toISOString()
              .substr(14, 5)}
          </Typography>
        </FlexBox>
      </FlexBox>

      <FlexBox sx={{ position: 'relative', width: 1 }}>
        <ProgressBar
          color={color}
          percent={Math.min(progression, 100)}
          background={'white'}
        />

        <ProgressBar
          color={color}
          percent={((item_index + 1) / items.length) * 100}
          background={'white'}
          sx={{ position: 'absolute', top: 0, left: 0, opacity: 0.35 }}
        />
      </FlexBox>
    </FlexBox>
  );
};

export const BeReadyTimer = ({
  duration,
  pause,
  color,
  onTimerChange,
  sx,
  ...rest
}) => {
  const { t } = useTranslation();
  const elapsed_rounded_seconds = Math.round(
    useCounter({ pause, initial_value: 0 })
  );

  let progression = Math.ceil(
    ((useInterval({ pause }) / 1000) * 100) / duration
  );

  /**
   * Progression can be late due request animation frame / screen "off" logical.
   */
  progression = Math.max(
    progression,
    (elapsed_rounded_seconds * 100) / duration - 10
  );

  const max = Math.min(Math.floor(progression / 10), elapsed_rounded_seconds);

  useEffect(() => {
    onTimerChange?.(Math.min(max, duration));
  }, [max]);

  return (
    <FlexBox
      sx={[
        {
          height: '6.6rem',
          justifyContent: 'center',
          position: 'relative',
          width: '6.6rem',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      color='white'
      {...rest}
    >
      <CircularProgress
        variant='determinate'
        color='inherit'
        size={'6.5rem'}
        thickness={2}
        value={100}
      />

      <CircularProgress
        variant='determinate'
        sx={{
          color: (theme) => parsers.theme(theme).palette.get({ path: color }),
          position: 'absolute',
          left: 0,
          svg: { strokeLinecap: 'round' },
        }}
        size={'inherit'}
        thickness={2.5}
        value={Math.min(progression, 100)}
      />

      <FlexBox
        sx={{
          height: 1,
          justifyContent: 'center',
          left: 0,
          position: 'absolute',
          top: 0,
          width: 1,
        }}
      >
        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
          {t('components.workout-player.dashboard.be-ready-timer.label')}
        </Typography>
      </FlexBox>
    </FlexBox>
  );
};
