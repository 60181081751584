import { useEffect, useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { TextField } from '@mui/material';
import {
  Button,
  DatePicker,
  FlexBox,
  Redirect,
  SelectInput,
  Thumbnail,
  formatters,
  parsers,
  useAPI,
  useDialog,
  withTemplateCheckpoint,
} from '@weasyo/react';

import { Loader, FlexForm, Header as DefaultHeader } from '~/src/components';
import avatar_cover_url from '~/src/assets/images/pictures/avatar_cover.webp';

var max_birth_date = new Date();
max_birth_date.setFullYear(new Date().getFullYear() - 15);

const Header = () => (
  <DefaultHeader
    back_button
    overtitle={t('content.authenticated.profile.edit.index.header.overtitle')}
    title={t('content.authenticated.profile.edit.index.header.title')}
    scrolled={{
      text: t('content.authenticated.profile.edit.index.header.scrolled.text'),
    }}
  />
);

const Index = () => {
  const [, setDialog] = useDialog();
  const query_client = useQueryClient();
  const { API } = useAPI();
  const [should_redirect, shouldRedirect] = useState();

  const onSubmit = (data, form) =>
    API.patch({
      path: 'profile',
      data: {
        ...data,
        date_birth: data.date_birth != '' ? data.date_birth : null,
      },
      onError: (api_response) => {
        /**
         * Set form errors messages otherwise keep throwing exception.
         */
        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            formik,
            violations: api_response.error.data?.violations,
            t,
          });

          if (Object.entries(errors.out_scope).length == 0) return;
        }

        throw api_response;
      },
    })
      .then(() => query_client.invalidateQueries('profile'))
      .then(() => {
        setDialog({
          severity: 'success',
          open: true,
          message: t(
            'content.authenticated.profile.edit.index.dialogs.success.message'
          ),
        });
        shouldRedirect({ should_redirect: true });
      })
      .finally(() => form.setSubmitting(false));

  const formik = useFormik({
    onSubmit,
    // i18next-extract-disable
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .required(t('glossary.required'))
        .matches(/[a-zA-Z]+/, t('validation.regex./[a-zA-Z]+/')),
      last_name: Yup.string()
        .required(t('glossary.required'))
        .matches(/[a-zA-Z]+/, t('validation.regex./[a-zA-Z]+/')),
      gender: Yup.string(),
      date_birth: Yup.date(),
    }),
    // i18next-extract-enable
    initialValues: {
      first_name: '',
      last_name: '',
      gender: undefined,
      date_birth: '',
    },
  });

  /**
   * Fetch {profile}.
   */
  const { data: profile } = useQuery(['profile'], () =>
    API.get({ path: 'profile' }).then(({ data }) => data)
  );

  useEffect(() => {
    if (!profile) return;

    let data = profile;

    data.date_birth = data.date_birth
      ? formatters.date(profile.date_birth)
      : data.date_birth;

    Object.keys(formik.values).map(
      (key) => data[key] !== undefined && formik.setFieldValue(key, data[key])
    );
  }, [profile]);

  if (!profile) return <Loader />;

  if (should_redirect) return <Redirect to='/profile' />;

  return (
    <>
      <FlexBox
        background='white'
        color='lightGrey'
        sx={{ borderRadius: 2, border: '1px solid', p: 0.5 }}
      >
        <Thumbnail
          key={avatar_cover_url}
          sx={{ borderRadius: 2, height: '6rem', width: '6rem' }}
          background='white'
          image={{ src: avatar_cover_url }}
        />
      </FlexBox>

      <FlexForm sx={{ width: 1, mt: 2 }} onSubmit={formik.handleSubmit}>
        {/* i18next-extract-disable */}

        <TextField
          id='email-input'
          label={t('glossary.email')}
          type='text'
          name='email'
          fullWidth
          margin='normal'
          value={profile.email}
          disabled={true}
        />

        <TextField
          id='first-name-input'
          label={t('glossary.first-name')}
          type='text'
          name='first_name'
          fullWidth
          margin='normal'
          error={formik.errors.first_name && formik.touched.first_name}
          helperText={formik.errors.first_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.first_name}
          disabled={formik.isSubmitting}
        />

        <TextField
          id='last-name-input'
          label={t('glossary.last-name')}
          type='text'
          name='last_name'
          fullWidth
          margin='normal'
          error={formik.errors.last_name && formik.touched.last_name}
          helperText={formik.errors.last_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.last_name}
          disabled={formik.isSubmitting}
        />

        <DatePicker
          sx={{ my: 2, width: 1 }}
          label={t('glossary.birth-date')}
          onChange={(date) => formik.setFieldValue('date_birth', date)}
          value={formik.values.date_birth}
          maxDate={max_birth_date}
          disabled={formik.isSubmitting}
          error={formik.errors.date_birth && formik.touched.date_birth}
        />

        <SelectInput
          sx={{ my: 2, width: 1 }}
          label={t('glossary.gender')}
          name='gender'
          onChange={formik.handleChange}
          value={formik.values.gender}
          disabled={formik.isSubmitting}
          error={formik.errors.gender && formik.touched.gender}
          helperText={formik.errors.gender}
          variant='outlined'
          options={[
            {
              value: 'woman',
              label: t('common.gender', { context: 'woman' }),
            },
            { value: 'man', label: t('common.gender', { context: 'man' }) },
            {
              value: 'other',
              label: t('common.gender', { context: 'other' }),
            },
          ]}
        />

        <Button
          sx={{ my: 2, width: 1 }}
          data-purpose='submit'
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          fullWidth
          type='submit'
          size='large'
          label={t('common.is-saving', {
            context: formik.isSubmitting ? 'true' : 'false',
          })}
        />

        {/* i18next-extract-disable */}
      </FlexForm>
    </>
  );
};

export default withTemplateCheckpoint(
  Index,
  { inheritance: 'default' },
  { header: <Header /> }
);
