import { t } from 'i18next';
import {
  FlexBox,
  Typography,
  InputGuesser,
  CheckableInput,
} from '@weasyo/react';

import { QuestionTemplate } from './';

export const EmailCommunicationQuestion = ({ onChange, value, question }) => {
  /**
   * Override question help.
   */
  question?.proposal.forEach((data, index) => {
    if (data.value != 1) return;

    question.proposal[index].help = true;
  });

  return (
    <QuestionTemplate
      content={
        <>
          <FlexBox mb={3}>
            <Typography variant='h2' sx={{ textAlign: 'center' }}>
              {t('components.email-communication-question.first-text')}
            </Typography>
          </FlexBox>

          <Typography sx={{ textAlign: 'center' }} paragraph>
            {t('components.email-communication-question.second-text')}
          </Typography>

          <Typography sx={{ textAlign: 'center' }}>
            {t('components.email-communication-question.third-text')}
          </Typography>
        </>
      }
      form={
        <InputGuesser
          data={question}
          value={value}
          onChange={onChange}
          placeholder={<CheckableInput />}
        />
      }
    />
  );
};

export default EmailCommunicationQuestion;
