import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
import {
  Button,
  FlexBox,
  Svg,
  Typography,
  useCommonState,
  withTemplateCheckpoint,
} from '@weasyo/react';

import { ErrorEmailToButton } from '~/src/components';

const Page404 = () => {
  useCommonState({ page_title: t('content.error.404.title') });
  const navigate = useNavigate();

  return (
    <>
      <Svg component={BlockIcon} color='primary' width='6rem' />

      <Typography variant='h1'>{t('content.error.404.first-text')}</Typography>

      <Typography variant='subtitle1'>
        {t('content.error.404.second-text')}
      </Typography>

      <Button
        size='large'
        fullWidth
        onClick={() => navigate('/')}
        data-purpose='navigate_to_home'
        data-details='from_page_error_404'
        label={t('content.error.404.buttons.navigate-to-home.label')}
      />

      <FlexBox>
        <Typography>{t('content.error.404.third-text')}</Typography>

        <Typography>
          <ErrorEmailToButton debug={{ error: '404' }} />
        </Typography>
      </FlexBox>
    </>
  );
};

export default withTemplateCheckpoint(Page404, { inheritance: 'default' });
