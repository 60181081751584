import { FlexBox, formatters, CardItem, Typography } from '@weasyo/react';
import { Trans, useTranslation } from 'react-i18next';

import { Capsule } from '~/src/components';

export const SubscriptionCard = ({
  highlighted,
  is_active,
  onClick,
  product,
  with_details,
  sx,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  /**
   * TODO add new {product.resolved_translation} prop logical into {useSubscriptions}.
   * Retrieve data related to first "highlighted subscription" matching current language.
   */
  const resolved_translation = (product?.translations ?? []).find(
    ({ language }) => language.code_alpha_2 === i18n.resolvedLanguage
  );

  const is_product_available = product?.status == 'published';
  const unlimited_months = 999;

  const capsule_string =
    product && with_details
      ? is_product_available
        ? resolved_translation?.pricing?.discount
        : // i18next-extract-mark-context-next-line ["is-unavailable"]
          t('components.subscription-card.capsule', {
            context: 'is-unavailable',
          })
      : null;

  const catch_phrase =
    with_details && resolved_translation?.description?.catch_phrase
      ? resolved_translation?.description.catch_phrase
      : !with_details
      ? t('components.subscription-card.catch-phrase')
      : null;

  const trial_period = resolved_translation?.pricing?.days_trial_period
    ? t('components.subscription-card.trial-period', {
        count: resolved_translation?.pricing.days_trial_period,
      })
    : null;

  return (
    <FlexBox
      sx={{
        position: 'relative',
        pt: capsule_string ? 2 : undefined,
        width: 1,
      }}
    >
      {capsule_string && (
        <Capsule
          background={is_product_available ? 'cta' : 'midGrey'}
          color='white'
          sx={{
            alignItems: 'baseline',
            borderRadius: 100,
            flexDirection: 'row',
            justifyContent: 'center',
            mr: 2,
            position: 'absolute',
            px: 2,
            py: 0.25,
            right: 0,
            top: with_details && is_product_available ? '-0.25rem' : 0,
            zIndex: 'front_of_content',
          }}
        >
          <Typography
            component='span'
            color='cta.contrastText'
            size={with_details && is_product_available ? 26 : 16}
            sx={{ fontWeight: 'bold' }}
          >
            {capsule_string.replace('%', '')}
          </Typography>

          {capsule_string.indexOf('%') != -1 && (
            <Typography
              component='span'
              color='cta.contrastText'
              size={14}
              sx={{ fontWeight: 'bold' }}
            >
              {'%'}
            </Typography>
          )}
        </Capsule>
      )}

      <CardItem
        sx={[{ borderRadius: 2, width: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
        arrow='center'
        onClick={onClick}
        {...{
          'data-purpose': 'click_on_product',
          'data-details': product?.slug,
        }}
        {...(highlighted
          ? {
              border: 0,
              background: 'primary',
              color: 'white',
            }
          : { border: 0.75, color: 'primary' })}
        {...(!product
          ? { background: 'midGrey', border: 0, color: 'lightGrey' }
          : {})}
        {...rest}
      >
        <FlexBox
          color={highlighted ? 'white' : 'primary'}
          sx={{
            alignItems: 'start',
            height: 1,
            p: 2,
            textAlign: 'left',
            width: 1,
          }}
        >
          {(catch_phrase ?? product === undefined) && (
            <FlexBox sx={{ mb: 0.5 }}>
              <Typography
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                size={18}
                letter_spacing={0.5}
                placeholder='10rem'
              >
                {catch_phrase}
              </Typography>
            </FlexBox>
          )}

          <Typography
            sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            size={16}
            letter_spacing={0.3}
            placeholder='12rem'
          >
            {resolved_translation?.description?.name}
          </Typography>

          {with_details && (
            <>
              {(trial_period ?? product === undefined) && (
                <Typography
                  sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  variant='h4'
                  letter_spacing={0.5}
                  placeholder='14rem'
                >
                  {trial_period}
                </Typography>
              )}

              <FlexBox
                sx={{
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <Trans
                  i18nKey='components.subscription-card.explained-pricing'
                  values={{
                    price: resolved_translation?.pricing?.price ?? '',
                    currency:
                      resolved_translation?.pricing?.currency_symbol ?? '',
                    duration_months: product?.duration_months ?? 1,
                    monthly_price: product
                      ? formatters.price(
                          resolved_translation?.pricing?.price /
                            product.duration_months
                        )
                      : 1,
                    pricing_ctx:
                      product?.duration_months >= unlimited_months
                        ? 'single-direct-debit'
                        : 'periodic-direct-debit',
                    days_trial_period:
                      resolved_translation?.pricing?.days_trial_period ?? '',
                    introductory_duration_months:
                      resolved_translation?.pricing?.introductory
                        ?.duration_months ?? 1,
                    introductory_price:
                      resolved_translation?.pricing?.introductory?.price ?? '',
                    context: [
                      resolved_translation?.pricing?.introductory?.price &&
                        'introductory-offer',
                      resolved_translation?.pricing?.days_trial_period &&
                        'trial-period',
                    ]
                      .filter((x) => x)
                      .sort()
                      .join('-and-')
                      .replace(/(.+)/, 'with-$1'),
                  }}
                  components={{
                    C_A: (
                      <Typography
                        transform={false}
                        size={16}
                        component='span'
                        sx={{ mr: 0.25 }}
                      />
                    ),
                    C_B: <Typography transform={false} size={13} />,
                  }}
                />
              </FlexBox>
            </>
          )}

          {is_active && (
            <FlexBox sx={{ width: 1, alignItems: 'flex-start', mt: 2 }}>
              <Capsule
                {...(highlighted
                  ? { background: 'white', color: 'primary' }
                  : { background: 'primary', color: 'white' })}
                // i18next-extract-mark-context-next-line ["is-enabled"]
                text={t('components.subscription-card.capsule', {
                  context: 'is-enabled',
                })}
              />
            </FlexBox>
          )}
        </FlexBox>
      </CardItem>
    </FlexBox>
  );
};

export default SubscriptionCard;
