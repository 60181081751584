import { Router } from '~/src/components';
import Password from './Password';
import AccountActivation from './AccountActivation';

export const Routes = () => (
  <Router>
    <Password path='password' />
    <AccountActivation path='account/activation' />
  </Router>
);

export default Routes;
