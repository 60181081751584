import { CardItem, Thumbnail, Typography, FlexBox } from '@weasyo/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const ZoneCard = ({
  zone,
  width = '8.5rem',
  height = '8.5rem',
  onZoneChange,
  sx,
  ...rest
}) => {
  const { i18n } = useTranslation();
  const ref = useRef();

  return (
    <CardItem
      {...(!zone ? { background: 'midGrey' } : {})}
      sx={[
        { borderRadius: 2, boxShadow: 1 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-purpose='navigate_to_zones_results'
      data-details={zone?.slug}
      arrow='none'
      onClick={onZoneChange}
      {...rest}
      ref={ref}
    >
      <FlexBox p={1}>
        <Thumbnail
          color='lightGrey'
          background='white'
          border={1.5}
          sx={{ borderRadius: 2, height, width }}
          image={{ src: zone?.thumbnail.url, require_authentication: true }}
        />

        <FlexBox
          sx={{
            alignItems: 'flex-start',
            maxWidth: width,
            minWidth: width,
            mt: 1,
            width,
          }}
        >
          <Typography
            variant='subtitle1'
            sx={{ fontWeight: 'bold', textAlign: 'left' }}
            placeholder={'90%'}
          >
            {
              zone?.translations?.find(
                (tr) => tr.language.code_alpha_2 === i18n.resolvedLanguage
              )?.name
            }
          </Typography>
        </FlexBox>
      </FlexBox>
    </CardItem>
  );
};

export default ZoneCard;
