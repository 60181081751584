import { useEffect } from 'react';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  parsers,
  useAPI,
  useCommonState,
  useDialog,
  useURLParams,
} from '@weasyo/react';

import { Loader } from '~/src/components';

export const Confirm = () => {
  useCommonState({
    page_title: t('content.anyone.confirm.sign-up.title'),
  });
  const navigate = useNavigate();
  const { email, token } = useParams();
  const { setParams: setURLParams } = useURLParams();
  const [, setDialog] = useDialog();
  const { API } = useAPI();

  useEffect(() => {
    API.patch({
      path: 'anonymous/demands/status',
      data: { email, token },
      is_token_related: true,
      with_authorization: false,
      onError: (api_response) => {
        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            violations: api_response.error.data?.violations,
            t,
          });

          if (errors.all.status?.data?.value == '"enabled"') {
            setDialog({
              open: true,
              message: t(
                'content.anyone.confirm.sign-up.index.dialogs.account-already-enabled.message',
                { email }
              ),
              label: t(
                'content.anyone.confirm.sign-up.from.professional.index.dialogs.account-already-enabled.label'
              ),
            });

            setURLParams({
              web_app: { prefill: { page: '/login', data: { email } } },
            });

            return;
          }

          if (errors.all.token) {
            setDialog({
              severity: 'warning',
              open: true,
              message: t(
                'content.anyone.confirm.sign-up.index.dialogs.expired-demand.message'
              ),
              label: t(
                'content.anyone.confirm.sign-up.index.dialogs.expired-demand.label'
              ),
              onClick: () => {
                setDialog({ open: false });

                setURLParams({
                  web_app: {
                    prefill: {
                      page: '/demands/account/activation',
                      data: { email },
                    },
                  },
                });

                navigate('/demands/account/activation');
              },
            });

            setURLParams({
              web_app: { prefill: { page: '/login', data: { email } } },
            });

            return;
          }
        }

        throw api_response;
      },
    }).then(() => {
      setDialog({
        severity: 'success',
        duration: 5000,
        open: true,
        message: t(
          'content.anyone.confirm.sign-up.index.dialogs.success.message',
          { email }
        ),
      });
    });
  }, []);

  return <Loader />;
};

export default Confirm;
