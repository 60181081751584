import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  FlexBox,
  Typography,
  parsers,
  useAPI,
  useTemplate,
  withTemplateCheckpoint,
  BackNavBlocker,
} from '@weasyo/react';

import { ProgramCard, Header as DefaultHeader } from '~/src/components';
import { fillZonesChildren } from '~/src/utilities';

const Header = (props) => (
  <DefaultHeader
    back_button
    overtitle={t('common.ellipse')} // i18next-extract-disable-line
    title={null}
    scrolled={{
      text: t('content.authenticated.zones.result.header.scrolled.text'),
    }}
    {...props}
  >
    <FlexBox p={2} pt={0}>
      <Typography align='center' variant='h1'>
        {t('content.authenticated.zones.result.header.content.title')}
      </Typography>

      <Typography align='center' variant='subtitle1'>
        {t('content.authenticated.zones.result.header.content.subtitle')}
      </Typography>
    </FlexBox>
  </DefaultHeader>
);

const Result = () => {
  const { i18n } = useTranslation();
  const { parent_zone_slug, child_zone_slug } = useParams();
  const setTemplate = useTemplate();
  const { API } = useAPI();

  /**
   * Fetch {zones}.
   */
  const { data: zones = [] } = useQuery(['zones'], () =>
    API.get({ path: 'zones', filters: { itemsPerPage: 100 } }).then(
      ({ data }) => fillZonesChildren(data['hydra:member'])
    )
  );

  const child_zone = zones.find((zone) => zone.slug === child_zone_slug);
  const parent_zone = zones.find(
    (zone) => parsers.getId(zone) === child_zone.parent ?? null
  );

  /**
   * Fetch {programs}.
   */
  const { data: programs } = useQuery(
    ['programs', { zones: [parsers.getId(child_zone)] }],
    ({ queryKey: [, filters] }) =>
      child_zone
        ? API.get({ path: 'programs', filters }).then(
            ({ data }) => data['hydra:member']
          )
        : undefined
  );

  /**
   * Update {header}.
   */
  useEffect(() => {
    if (!(parent_zone && child_zone)) return;

    const parent_zone_name =
      parent_zone.translations.find(
        (tr) => tr.language.code_alpha_2 === i18n.resolvedLanguage
      )?.name ?? null;

    const child_zone_name =
      child_zone.translations.find(
        (tr) => tr.language.code_alpha_2 === i18n.resolvedLanguage
      )?.name ?? null;

    setTemplate({
      header: (
        <Header
          overtitle={t('content.authenticated.zones.result.header.overtitle', {
            parent_zone_name,
            child_zone_name,
          })}
        />
      ),
    });
  }, [parent_zone, child_zone]);

  return (
    <>
      <BackNavBlocker mode='deviation' path={`/zones/${parent_zone_slug}`} />

      {(programs ?? [undefined, undefined, undefined]).map((program, index) => (
        <ProgramCard sx={{ mb: 2 }} key={index} program={program} />
      ))}
    </>
  );
};

export default withTemplateCheckpoint(
  Result,
  { inheritance: 'default' },
  { header: <Header /> }
);
