import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { t } from 'i18next';
import {
  Delete as DeleteIcon,
  Share as ShareIcon,
  Clear as ClearIcon,
  Description as DescriptionIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';
import { IconButton, Avatar } from '@mui/material';
import {
  FlexBox,
  Modal,
  Pane,
  Svg,
  Typography,
  formatters,
  parsers,
  useAPI,
  useCommonState,
  useDialog,
  usePrefill,
  useStateWrapper,
  useTemplate,
} from '@weasyo/react';
import { CaduceusIcon } from '@weasyo/react/lib/images';

import {
  CTAButton,
  Header,
  ProgramStepsList,
  QuarterCircleIllustration,
  ResumeItem,
  Share,
} from '~/src/components';
import { queries, getRandomSentence } from '~/src/utilities';
import professional_program_cover_url from '~/src/assets/images/pictures/professional_program_cover.webp';

const RestartButton = ({ program, ...rest }) => {
  const navigate = useNavigate();
  const { API } = useAPI();
  const query_client = useQueryClient();
  const [modal, setState] = useStateWrapper(
    useState({
      open: false,
      title: t('modals.reset-patient-program.title'),
      description: t('modals.reset-patient-program.description'),
      sub_description: t('modals.reset-patient-program.sub-description'),
      secondary: { label: t('modals.reset-patient-program.secondary.label') },
      primary: {
        label: t('modals.reset-patient-program.primary.label'),
        onClick: () => {
          setState({ open: false });

          queries
            .createPatientProgram({ API, program, query_client })
            .then((patient_program) =>
              navigate(`/my-programs/${parsers.getId(patient_program, true)}`)
            );
        },
      },
      onClose: () => setState({ open: false }),
    })
  );

  const { data: patient_program_in_progress } = useQuery(
    [
      queries.names.patient_programs_of,
      { id: parsers.getId(program, true), last_in_progress: true },
    ],
    ({ queryKey: [, { id }] }) =>
      API.get({
        path: 'patient/programs',
        filters: { program: id, status: 'in progress' },
      }).then(({ data }) => {
        const patient_program = data['hydra:member'][0] ?? null;

        if (!patient_program) return patient_program;

        queries.setQueriesData({
          API,
          query_client,
          patient_program,
          clear: false,
        });

        queries.preFetchRelatedOf({
          API,
          query_client,
          patient_program,
        });

        return patient_program;
      }),
    { staleTime: 10 * 60 * 1000 }
  );

  if (patient_program_in_progress) return null;

  return (
    <>
      <Modal {...modal} />

      <FlexBox width={1} {...rest}>
        <CTAButton
          onClick={() => setState({ open: true })}
          data-purpose={'navigate_to_new_patient_program'}
          label='je recommence'
        />
      </FlexBox>
    </>
  );
};

const Resume = ({ program, patient_program, ...rest }) => {
  const [{ motivation_sentence }, setCommonState] = useCommonState();
  const capsule_content =
    program &&
    program['@id'] === motivation_sentence?.id &&
    motivation_sentence.sentence
      ? motivation_sentence.sentence
      : null;
  const progression = program?.professional
    ? null
    : patient_program
    ? (patient_program.nb_patient_workouts_completed /
        patient_program.nb_patient_workouts) *
      100
    : 0;

  useEffect(() => {
    if (
      !program ||
      isNaN(progression) ||
      (motivation_sentence?.id === program['@id'] &&
        motivation_sentence?.progression === progression)
    )
      return;

    const sentence = program?.professional
      ? t(
          'content.authenticated.programs.patient-program.resume.professional-program.sentence'
        )
      : patient_program && program
      ? getRandomSentence({
          type: formatters.slugify(program.category),
          progression,
          sentences: t('common.motivational-phrases', { returnObjects: true }),
        })
      : null;

    setCommonState({
      motivation_sentence: { id: program['@id'], sentence, progression },
    });
  }, [program, progression]);

  return (
    <ResumeItem
      program={program}
      progression={progression}
      capsule_content={capsule_content}
      hat_content={
        (program?.professional && (
          <Svg component={CaduceusIcon} height='90%' />
        )) || (
          <FlexBox sx={{ alignItems: 'baseline', flexDirection: 'row' }}>
            <Typography
              color='text.primary'
              size={24}
              placeholder='2rem'
              sx={{ fontWeight: 'bold' }}
            >
              {`${parseInt(progression)}`}
            </Typography>

            <Typography
              color='text.primary'
              size={12}
              placeholder='2rem'
              sx={{ fontWeight: 'bold' }}
            >
              {'%'}
            </Typography>
          </FlexBox>
        )
      }
      {...rest}
    >
      {patient_program?.status == 'completed' && (
        <RestartButton sx={{ mt: 2 }} program={program} />
      )}
    </ResumeItem>
  );
};

export const PatientProgram = () => {
  useCommonState({
    page_title: t('content.authenticated.programs.patient-program.title'),
  });
  const navigate = useNavigate();
  const query_client = useQueryClient();
  const setTemplate = useTemplate();
  const { patient_program_id, email } = useParams();
  const [, setDialog] = useDialog();
  const { API } = useAPI();
  const { set: prefill } = usePrefill();
  const [{ delete_modal, is_share_clicked, open_pane }, setState] =
    useStateWrapper(
      useState({
        delete_modal: { open: false },
        is_share_clicked: false,
        open_pane: false,
      })
    );

  const { data: patient_program } = useQuery(
    [queries.names.patient_programs, { id: patient_program_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({
        path: 'patient/programs/${id}',
        data: { id },
        onError: ({ error }) => {
          if (error.response.status == 404) {
            /**
             * Expected user for the patient program doesn't match the logged one.
             * Can occur with external link and user isn't logged with the expected account.
             */
            email &&
              API.get({ path: 'profile' }).then(({ data: profile }) => {
                if (profile.email == email) return;

                setDialog({
                  open: true,
                  message: t(
                    'content.authenticated.programs.patient-program.dialogs.unexpected-owner.message',
                    {
                      owner_email: email,
                      logged_email: profile.email,
                    }
                  ),
                  label: t(
                    'content.authenticated.programs.patient-program.dialogs.unexpected-owner.label'
                  ),
                  onClick: () => {
                    setDialog({
                      open: true,
                      message: t(
                        'content.authenticated.programs.patient-program.dialogs.login-with-account.message',
                        { email }
                      ),
                    });

                    prefill({ identifier: '/login', data: { email } });

                    navigate(
                      `/logout?destination=/my-programs/${patient_program_id}/email/${email}`
                    );
                  },
                });
              });

            navigate('/404_not_found', { replace: true });
            return;
          }
        },
      }).then(({ data: patient_program }) => {
        queries.setQueriesData({
          API,
          query_client,
          patient_program,
          clear: false,
        });

        queries.preFetchRelatedOf({
          API,
          query_client,
          patient_program,
        });

        return patient_program;
      })
  );

  const program = patient_program?.program ?? null;
  const color = program ? formatters.slugify(program.category) : 'midGrey';

  /**
   * Pane items.
   */
  let pane_lists_items = {
    '': {
      share: {
        label: t(
          'content.authenticated.programs.patient-program.options.share.label'
        ),
        Icon: ShareIcon,
        'data-purpose': 'open_share_api',
        onClick: () => setState({ is_share_clicked: true }),
      },
      description: {
        'data-purpose': 'navigate_to_ description',
        Icon: DescriptionIcon,
        label: t(
          'content.authenticated.programs.patient-program.options.description.label'
        ),
        onClick: () => navigate(`/programs/${parsers.getId(program, true)}`),
      },
      delete: {
        label: t(
          'content.authenticated.programs.patient-program.options.delete.label'
        ),
        Icon: DeleteIcon,
        'data-purpose': 'delete_program',
        onClick: () =>
          setState({
            open_pane: false,
            delete_modal: {
              open: true,
              title: t('modals.delete-patient-program.title'),
              description: t(
                'modals.delete-patient-program.description.is-in-progress',
                {
                  context:
                    patient_program?.status == 'in progress' ? 'true' : 'false',
                }
              ),
              sub_description: t(
                'modals.delete-patient-program.sub-description'
              ),
              primary: {
                label: t('modals.delete-patient-program.primary.label'),
                onClick: () => setState({ delete_modal: { open: false } }),
              },
              secondary: {
                label: t('modals.delete-patient-program.secondary.label'),
                onClick: () =>
                  API.patch({
                    path: 'patient/programs/${id}',
                    data: { id: patient_program_id, status: 'deleted' },
                  })
                    .then(() => navigate('/my-programs', { replace: true }))
                    .then(() => {
                      queries.removeQueriesOf({
                        query_client,
                        patient_program,
                      });

                      queries.invalidateQueriesOf({
                        query_client,
                        patient_program,
                      });
                    }),
              },
              onClose: () => setState({ delete_modal: { open: false } }),
            },
          }),
      },
      close: {
        Icon: ClearIcon,
        label: t(
          'content.authenticated.programs.patient-program.options.cancel.label'
        ),
        'data-purpose': 'close_pane',
        onClick: () => setState({ open_pane: false }),
      },
    },
  };

  /**
   * Remove "description" for "professional" program .
   */
  if (program?.professional) {
    delete pane_lists_items[''].description;
  }

  /**
   * Update template {header}.
   */
  useEffect(() => {
    if (!program) return;

    setTemplate(({ cover }) => ({
      cover: {
        ...cover,
        image: !program?.professional
          ? {
              src: program?.cover?.url,
              require_authentication: true,
            }
          : professional_program_cover_url,
        children: <QuarterCircleIllustration size={1.15} color={color} />,
      },
      header: (
        <Header
          back_button
          title={null}
          sx={{ position: 'absolute', top: 0 }}
          color='white'
          border_color={color}
          extra={
            <IconButton
              data-purpose='open_pane'
              onClick={() => setState({ open_pane: true })}
              size='large'
            >
              <Avatar sx={{ backgroundColor: 'white.main', boxShadow: 1 }}>
                <Svg color='text.secondary' component={MoreHorizIcon} />
              </Avatar>
            </IconButton>
          }
          scrolled={{
            color: 'text.primary',
            extra: (
              <IconButton
                color='inherit'
                data-purpose='open_pane'
                onClick={() => setState({ open_pane: true })}
                size='large'
              >
                <Svg color='text.secondary' component={MoreHorizIcon} />
              </IconButton>
            ),
            children: <Typography trunc>{program.name}</Typography>,
          }}
        />
      ),
    }));
  }, [program?.['@id']]);

  return (
    <>
      <Modal {...delete_modal} />

      {(patient_program && !patient_program.program.professional && (
        <Share
          url={`${window.location.origin}/programs/${parsers.getId(
            patient_program.program,
            true
          )}`}
          open={is_share_clicked}
          onEnd={() => setState({ is_share_clicked: false, open_pane: false })}
          text={t('content.authenticated.programs.share.program.text')}
          title={t('content.authenticated.programs.share.program.title')}
        />
      )) || (
        <Share
          url={window.location.origin}
          open={is_share_clicked}
          onEnd={() => setState({ is_share_clicked: false, open_pane: false })}
        />
      )}

      <Pane
        icon={null}
        anchor='bottom'
        back_button={false}
        lists={pane_lists_items}
        open={open_pane}
        onToggleOpen={() =>
          setState(({ open_pane }) => ({ open_pane: !open_pane }))
        }
      />

      <Resume
        patient_program={patient_program}
        program={program}
        sx={{ mb: 2 }}
      />

      <FlexBox sx={{ px: 2, width: 1, alignItems: 'flex-start' }}>
        <ProgramStepsList
          program={program}
          patient_program={patient_program}
          title={t(
            'content.authenticated.programs.patient-program.program-step-list.first-text'
          )}
          sx={{ mb: 2 }}
        />
      </FlexBox>
    </>
  );
};

export default PatientProgram;
