import { Link, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Box } from '@mui/material';
import { Button, FlexBox, OAuthForm, Typography } from '@weasyo/react';

import { Terms } from '~/src/components';
import { Trans } from 'react-i18next';

/**
 * List required oauth ids.
 */
const oauth_ids = {
  // eslint-disable-next-line no-undef
  app: process.env.W_APP_ID,
  // eslint-disable-next-line no-undef
  ...JSON.parse(process.env.W_OAUTH_CLIENTS_IDS),
};

export const Index = () => {
  const navigate = useNavigate();

  return (
    <>
      <Typography
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          type: 'spring',
          damping: 15,
          stiffness: 100,
        }}
        variant='h1'
        paragraph
      >
        {t('content.anonymous.sign-up.index.first-text', {
          defaultValue: "s'inscrire gratuitement",
        })}
      </Typography>

      <FlexBox>
        <FlexBox width={1} height='14rem'>
          <OAuthForm
            is_sign_up={true}
            identifiers={oauth_ids}
            // eslint-disable-next-line no-undef
            api_base_url={process.env.W_API_URL}
          />
        </FlexBox>

        <Typography gutterBottom={false}>
          {t('content.anonymous.sign-up.index.second-text')}
        </Typography>

        <Button
          sx={{ my: 2 }}
          data-purpose='navigate_to_sign-up_form'
          fullWidth
          label={t(
            'content.anonymous.sign-up.index.buttons.navigate-to-sign-up.label'
          )}
          onClick={() => navigate('/sign-up/form')}
          size='large'
          type='submit'
        />

        <Typography paragraph variant='body2'>
          <Trans
            i18nKey='content.anonymous.sign-up.index.third-text'
            components={{
              link_to_login: (
                <Link to='/login' data-purpose='navigate_to_login' />
              ),
              link_to_account_activation_demand: (
                <Link
                  to='/demands/account/activation'
                  data-purpose='navigate_to_demands_account_activation'
                />
              ),
            }}
          />
        </Typography>
      </FlexBox>

      <Box my={2}>
        <Terms />
      </Box>
    </>
  );
};

export default Index;
