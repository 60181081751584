import { Share as ShareBase, useCore } from '@weasyo/react';

export const Share = (props) => {
  const [{ container }] = useCore();
  const adjust = container?.adjust ?? null;
  const default_conf = {
    url: window.location.origin,
    utm_list: {
      utm_campaign: adjust?.tracker_name ?? adjust?.trackerName,
      utm_content: 'share_button',
      // eslint-disable-next-line no-undef
      utm_medium: `${process.env.W_APP_ID}-${container?.mode ?? 'website'}`,
      utm_source: adjust?.network,
      pt: window.location.origin,
      mt: 'share_button',
      ct: 'application_share',
    },
  };

  const { url, utm_list, ...rest } = { ...default_conf, ...props };
  const final_url = new URL(url);

  Object.entries(utm_list ?? default_conf.utm_list).map(([key, value]) => {
    final_url.searchParams.set(key, value);
  });

  return <ShareBase url={final_url.toString()} {...rest} />;
};

export default Share;
