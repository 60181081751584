import { css, keyframes } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import {
  Button,
  FlexBox,
  Typography,
  useCommonState,
  withTemplateCheckpoint,
  parsers,
} from '@weasyo/react';

import girl_on_swiss_ball_picture_url from '~/src/assets/images/pictures/girl_on_swiss_ball.webp';

const Login = () => {
  useCommonState({ page_title: t('content.anonymous.index.title') });
  const navigate = useNavigate();

  return (
    <FlexBox sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
      <FlexBox sx={{ flexGrow: 1, justifyContent: 'flex-start' }}>
        <Typography paragraph variant='h1'>
          {t('content.anonymous.index.first-text')}
        </Typography>

        <Typography paragraph>
          {t('content.anonymous.index.second-text')}
        </Typography>
      </FlexBox>

      <FlexBox sx={{ my: 2, width: 1 }}>
        <Button
          data-purpose='navigate_to_sign_up'
          fullWidth
          label={t('content.anonymous.index.buttons.navigate-to-sign-up.label')}
          onClick={() => navigate('/sign-up')}
          size='large'
          type='submit'
        />

        <Button
          sx={{ my: 2 }}
          background='white'
          color='text.primary'
          data-purpose='navigate_to_login'
          fullWidth
          label={t('content.anonymous.index.buttons.navigate-to-login.label')}
          onClick={() => navigate('/login')}
          size='large'
          type='submit'
        />
      </FlexBox>
    </FlexBox>
  );
};

const bounce = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export default withTemplateCheckpoint(
  Login,
  { inheritance: 'default' },
  {
    content_style: css`
      background-color: rgba(255, 255, 255, 0);
    `,
    page_style: (theme) => css`
      background-color: rgba(255, 255, 255, 0);
      ::before {
        animation: ${bounce} 0.5s ease-in;
        background: white
          linear-gradient(45deg, rgb(0 0 0 / 9%) 0%, rgb(255 255 255) 100%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        inset: 0px;
        margin: 0 auto;
        max-width: var(--app-max-width);
        opacity: 1;
        position: absolute;
        z-index: ${parsers.theme(theme).getZIndex({ level: 'behind_content' })};
      }
      ::after {
        animation: ${bounce} 0.5s ease-in;
        background: url(${girl_on_swiss_ball_picture_url}) center 115% /
          calc(var(--viewport-height)) no-repeat;
        content: '';
        inset: 0px;
        margin: 0px auto;
        max-width: var(--app-max-width);
        opacity: 1;
        position: absolute;
        z-index: ${parsers.theme(theme).getZIndex({ level: 'behind_content' })};
      }
    `,
  }
);
