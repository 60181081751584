import { css } from '@emotion/react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { t } from 'i18next';
import {
  FlexBox,
  TabPanel,
  Typography,
  useAPI,
  useCommonState,
  withTemplateCheckpoint,
} from '@weasyo/react';

import {
  Header as DefaultHeader,
  Navigation,
  PatientProgramCard,
} from '~/src/components';
import { queries } from '~/src/utilities';

const tab_style = css`
  text-transform: none;
`;

const Header = () => (
  <DefaultHeader
    back_button={null}
    title={t('content.authenticated.programs.patient-programs.header.title')}
  />
);

export const PatientPrograms = () => {
  const navigate = useNavigate();
  const { API } = useAPI();
  const query_client = useQueryClient();
  const { status } = useParams();
  const tabs_map_index = ['in-progress', 'completed'];
  let tab_index = tabs_map_index.indexOf(status);
  tab_index = tab_index == -1 ? 0 : tab_index;
  useCommonState({
    page_title: t('content.authenticated.programs.patient-programs.title'),
  });

  const { data: in_progress_patient_programs } = useQuery(
    [queries.names.patient_programs, { status: 'in progress' }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'patient/programs', filters }).then(({ data }) =>
        data['hydra:member'].map((patient_program) => {
          queries.setQueriesData({
            API,
            query_client,
            patient_program,
            clear: false,
          });

          return patient_program;
        })
      )
  );

  const { data: finished_patient_programs } = useQuery(
    [queries.names.patient_programs, { status: 'completed' }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'patient/programs', filters }).then(
        ({ data }) => data['hydra:member']
      )
  );

  const tab_one_data = in_progress_patient_programs ?? [null, null, null];
  const tab_two_data = finished_patient_programs ?? [null, null, null];

  const handleChange = (event, index) =>
    navigate(`/my-programs/lists/${tabs_map_index[index]}`);

  return (
    <FlexBox
      sx={{
        alignItems: 'stretch',
        flexGrow: 1,
        justifyContent: 'flex-start',
        width: 1,
      }}
    >
      <Tabs
        value={tab_index}
        onChange={handleChange}
        aria-label='simple tabs example'
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
      >
        <Tab
          data-purpose='navigate_to_in_progress_tab'
          css={tab_style}
          label={t(
            'content.authenticated.programs.patient-programs.tabs.is-in-progress.label'
          )}
        />

        <Tab
          data-purpose='navigate_to_completed_tab'
          css={tab_style}
          label={t(
            'content.authenticated.programs.patient-programs.tabs.completed.label'
          )}
        />
      </Tabs>

      <FlexBox sx={{ width: 1, py: 2 }}>
        <TabPanel
          value={tab_index}
          index={0}
          sx={{ rowGap: 1, scrollSnapType: 'y proximity', width: 1 }}
        >
          {tab_one_data.map((patient_program, index) => (
            <FlexBox
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: index * 0.5, damping: 0 }}
              sx={{
                width: 1,
                py: 1,
                scrollSnapAlign:
                  index == tab_one_data.length - 1 ? 'end' : 'start',
              }}
            >
              <PatientProgramCard
                patient_program={patient_program}
                prefetch={index < 3}
              />
            </FlexBox>
          ))}

          {in_progress_patient_programs?.length == 0 && (
            <Typography variant='subtitle1' paragraph placeholder='80%'>
              {t(
                'content.authenticated.programs.patient-programs.tabs.is-in-progress.empty'
              )}
            </Typography>
          )}
        </TabPanel>

        <TabPanel
          value={tab_index}
          index={1}
          sx={{ rowGap: 1, scrollSnapType: 'y proximity', width: 1 }}
        >
          {(finished_patient_programs ?? [null, null, null]).map(
            (patient_program, index) => (
              <FlexBox
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? '100%' : '-100%' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: index * 0.5, damping: 0 }}
                sx={{
                  width: 1,
                  py: 1,
                  scrollSnapAlign:
                    index == tab_two_data.length - 1 ? 'end' : 'start',
                }}
              >
                <PatientProgramCard
                  patient_program={patient_program}
                  prefetch={index < 3}
                />
              </FlexBox>
            )
          )}

          {finished_patient_programs?.length == 0 && (
            <Typography variant='subtitle1' paragraph placeholder='80%'>
              {t(
                'content.authenticated.programs.patient-programs.tabs.completed.empty'
              )}
            </Typography>
          )}
        </TabPanel>
      </FlexBox>
    </FlexBox>
  );
};

export default withTemplateCheckpoint(
  PatientPrograms,
  { inheritance: 'default' },
  { header: <Header />, footer: <Navigation /> }
);
