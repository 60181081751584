import { css } from '@emotion/react';
import {
  Template as BaseTemplate,
  withTemplateCheckpoint,
  useCommonState,
} from '@weasyo/react';
import { template as template_styles } from '@weasyo/react/lib/styles';
import { useEffect, useRef } from 'react';

import { Loader, Header } from '~/src/components';

const Wrapper = ({ children }) => children;

const DefaultTemplateCheckpoint = withTemplateCheckpoint(
  Wrapper,
  { id: 'default' },
  {
    Loader: <Loader />,
    content_style: template_styles.content,
    cover: null,
    footer: null,
    header: <Header />,
    page_style: template_styles.page,
  }
);

/**
 * TODO make it works.
 * Purpose : have hidden input that can be listened on change.
 * Could be use for sharing some data to native containers.
 *
 * Info: "change" event doesn't seem to work.
 */
// eslint-disable-next-line no-unused-vars
const InputHidden = ({ name, value }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      console.log({ ref: ref.current, value });
      ref.current.dispatchEvent(new Event('change', { bubbles: true }));
      ref.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }, [value]);

  return (
    <input
      ref={ref}
      css={css`
        display: none;
      `}
      type='text'
      className='InputHidden-root'
      id={`input-hidden-${name}`}
      name={name}
      onChange={(data) => console.log({ data })}
      value={JSON.stringify(value)}
    />
  );
};

export const Template = ({ children }) => {
  const [common_state] = useCommonState();

  /**
   * Update page title.
   */
  useEffect(() => {
    // TODO handle '-' as seperator to use uppercase.
    window.document.querySelector('title').innerHTML = `Weasyo${
      common_state.page_title
        ? ` - ${
            common_state.page_title.charAt(0).toUpperCase() +
            common_state.page_title.slice(1)
          }`
        : ''
    }`;
  }, [common_state.page_title]);

  return (
    <BaseTemplate>
      <DefaultTemplateCheckpoint>{children}</DefaultTemplateCheckpoint>

      {/* {Object.entries(common_state).map(([name, value]) => (
        <InputHidden key={name} value={value} name={name} />
      ))} */}
    </BaseTemplate>
  );
};

export default Template;
