import { useEffect } from 'react';
import { t } from 'i18next';
import { Navigate } from 'react-router-dom';
import { useAPI, useCommonState } from '@weasyo/react';

import { Loader } from '~/src/components';

const Logout = () => {
  useCommonState({
    page_title: t('content.anyone.logout.title'),
  });
  const { API, authenticated } = useAPI();

  /**
   * Request for logout.
   */
  useEffect(
    () => authenticated && API.post({ path: 'logout', is_token_related: true }),
    []
  );

  if (!authenticated) return <Navigate to='/login' replace />;

  return <Loader />;
};

export default Logout;
