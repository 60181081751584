import { Button, Typography } from '@weasyo/react';

export const CTAButton = ({ label, sx, ...props }) => (
  <Button
    background='cta'
    color='cta.contrastText'
    fullWidth={true}
    sx={[{ py: 2, boxShadow: 4 }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}
  >
    <Typography
      gutterBottom={false}
      variant='body2'
      weight='bold'
      color='inherit'
      transform='uppercase'
    >
      {label}
    </Typography>
  </Button>
);

export default CTAButton;
