import { CircularProgress, useTheme } from '@mui/material';
import { Typography, FlexBox, formatters } from '@weasyo/react';
import { useEffect, useState } from 'react';

import { Capsule, Category } from './';

export const ResumeItem = ({
  program,
  capsule_content,
  hat_content,
  progression,
  duration,
  children,
  size = 1,
  padding = 0.5,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const size_as_px = theme.spacing(size * 15);
  const padding_as_px = theme.spacing(padding);
  const color = program ? formatters.slugify(program.category) : 'midGrey';
  const [final_progression, setProgression] = useState(0);

  useEffect(() => progression && setProgression(progression), [progression]);

  return (
    <FlexBox sx={[{ width: 1 }, ...(Array.isArray(sx) ? sx : [sx])]} {...rest}>
      <FlexBox sx={{ position: 'relative', width: 1, pt: 4, px: 2 }}>
        {hat_content !== undefined || progression !== undefined ? (
          <FlexBox
            color={program ? formatters.slugify(program.category) : 'midGrey'}
            background='white'
            sx={[
              {
                p: padding,
                pb: 0,
                borderTopRightRadius: 100,
                borderTopLeftRadius: 100,
                justifyContent: 'flex-end',
                position: 'absolute',
              },
              {
                top: `calc(calc(${size_as_px} / -2) - 1em)`,
                width: size_as_px,
                height: `calc(${size_as_px} / 2)`,
              },
            ]}
          >
            {
              <CircularProgress
                size={`calc(${size_as_px} - ${padding_as_px} * 2)`}
                thickness={2}
                variant='determinate'
                value={parseInt(final_progression / 2)}
                sx={[
                  {
                    top: padding_as_px,
                    left: padding_as_px,
                  },
                  {
                    color: 'inherit',
                    position: 'absolute',
                    transform: 'rotate(180deg) !important',
                    svg: { strokeLinecap: 'round' },
                    'svg circle': { transitionDuration: '2000ms' },
                  },
                ]}
              />
            }

            {hat_content && (
              <FlexBox sx={{ justifyContent: 'flex-end', height: 1 }}>
                {hat_content}
              </FlexBox>
            )}
          </FlexBox>
        ) : null}

        {duration ?? null}

        <Capsule
          background={color}
          sx={{ position: 'absolute', px: 2, py: 0.5, top: '-1rem' }}
        >
          {(typeof capsule_content == 'string' || capsule_content === null) && (
            <Typography
              variant='subtitle2'
              placeholder='10rem'
              color='white'
              sx={{ textAlign: 'center' }}
            >
              {capsule_content}
            </Typography>
          )}

          {typeof capsule_content != 'string' && capsule_content}
        </Capsule>

        <Typography variant='h1' align='center' placeholder='20rem'>
          {program?.name}
        </Typography>

        <FlexBox sx={{ mt: 2 }}>
          <Category name={program?.category} variant='body2' />
        </FlexBox>

        {children}
      </FlexBox>

      <FlexBox
        sx={{ width: 1, height: '0.5rem', mt: 2, mb: 3 }}
        background={color}
      />
    </FlexBox>
  );
};

export default ResumeItem;
