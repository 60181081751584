import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import { t } from 'i18next';
import * as Yup from 'yup';
import {
  Button,
  PasswordInput,
  Typography,
  parsers,
  useAPI,
  useDialog,
  withTemplateCheckpoint,
} from '@weasyo/react';

import { FlexForm, Header as DefaultHeader } from '~/src/components';

const EmailUpdate = () => {
  const { API } = useAPI();
  const navigate = useNavigate();
  const [, setDialog] = useDialog();
  const onSubmit = (data, form) =>
    API.post({
      path: 'profile/demands/email',
      data,
      onError: (api_response) => {
        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            formik,
            violations: api_response.error.data?.violations,
            t,
          });

          if (Object.entries(errors.out_scope).length == 0) return;
        }

        throw api_response;
      },
    })
      .then(() => {
        setDialog({
          open: true,
          message: t(
            'content.authenticated.profile.edit.email.dialogs.update.email-sended.message',
            { email: data.new_email }
          ),
        });
        navigate('/profile');
      })
      .finally(() => form.setSubmitting(false));

  const formik = useFormik({
    initialValues: { password: '', new_email: '', confirm_new_email: '' },
    onSubmit,
    // i18next-extract-disable
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(t('glossary.required'))
        .min(8, t('validation.length.min', { count: 8 }))
        .matches(/[0-9]+/, t('validation.regex./[0-9]+/')),
      new_email: Yup.string().email().required(t('glossary.required')),
      confirm_new_email: Yup.string()
        .oneOf([Yup.ref('new_email'), null], t('validation.do-not-match'))
        .required(t('glossary.required')),
    }),
    // i18next-extract-enable
  });

  return (
    <>
      <Typography>
        {t('content.authenticated.profile.edit.email.first-text')}
      </Typography>

      <FlexForm
        sx={{ textAlign: 'left', width: 1 }}
        onSubmit={formik.handleSubmit}
      >
        {/* i18next-extract-disable */}
        <PasswordInput
          id='password-input'
          label={t('glossary.password')}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          name='password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.errors.password && formik.touched.password}
          helperText={formik.errors.password}
        />

        <TextField
          id='new_email-input'
          label={t('common.new-email')}
          type='email'
          name='new_email'
          autoComplete='new-password'
          fullWidth
          margin='normal'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.new_email}
          error={formik.errors.new_email && formik.touched.new_email}
          helperText={formik.errors.new_email}
        />

        <TextField
          id='confirm_new_email-input'
          label={t('common.confirm-email')}
          type='email'
          name='confirm_new_email'
          autoComplete='off'
          fullWidth
          margin='normal'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirm_new_email}
          error={
            formik.errors.confirm_new_email && formik.touched.confirm_new_email
          }
          helperText={formik.errors.confirm_new_email}
        />

        <Button
          sx={{ my: 2 }}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          fullWidth
          type='submit'
          size='large'
          label={t('common.is-saving', {
            context: formik.isSubmitting ? 'true' : 'false',
          })}
        />
        {/* i18next-extract-enable */}
      </FlexForm>
    </>
  );
};

const Header = () => (
  <DefaultHeader
    back_button
    overtitle={t('content.authenticated.profile.edit.email.header.overtitle')}
    title={t('content.authenticated.profile.edit.email.header.title')}
    scrolled={{
      text: t('content.authenticated.profile.edit.email.header.scrolled.text'),
    }}
  />
);

export default withTemplateCheckpoint(
  EmailUpdate,
  { inheritance: 'default' },
  { header: <Header /> }
);
