import { Typography, FlexBox, formatters } from '@weasyo/react';
import { t } from 'i18next';

import Capsule from './Capsule';

export const Category = ({ name, sx, ...rest }) => (
  <FlexBox sx={{ flexDirection: 'row' }}>
    <Capsule
      background={name ? formatters.slugify(name) : 'midGrey'}
      sx={[
        { height: '0.75rem', mr: 1, width: '1.5rem', px: 0 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />

    <Typography
      sx={{ fontWeight: 'bold' }}
      variant='caption'
      placeholder={'3em'}
      {...rest}
    >
      {/* i18next-extract-disable-next-line */}
      {name && t('common.category', { context: name })}
    </Typography>
  </FlexBox>
);
export default Category;
