import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { t } from 'i18next';
import {
  parsers,
  useAPI,
  useDialog,
  useStateWrapper,
  withTemplateCheckpoint,
} from '@weasyo/react';

import {
  EmailCommunicationQuestion,
  Header as DefaultHeader,
} from '~/src/components';

const Communication = () => {
  const navigate = useNavigate();
  const [, setDialog] = useDialog();
  const query_client = useQueryClient();
  const { API } = useAPI();
  const [{ is_submitted, selection }, setState] = useStateWrapper(useState({}));

  /**
   * Fetch "user_settings" questions.
   */
  const { data: question } = useQuery(
    ['questions', { slug: 'is-communication-email-accepted' }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions', filters }).then(
        ({ data }) => data?.['hydra:member'][0]
      )
  );

  /**
   * Fetch "user_settings" questions responses.
   */
  const { data: question_response } = useQuery(
    [
      'questions_responses',
      { 'question.slug': 'is-communication-email-accepted' },
    ],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions_responses', filters }).then(
        ({ data }) => data?.['hydra:member'][0]
      )
  );

  /**
   * Update response.
   */
  useEffect(() => {
    if (!is_submitted) return;

    API.post({
      path: 'questions_responses',
      data: { question: parsers.getId(question), response: selection },
    })
      .then(() => {
        setDialog({
          severity: 'success',
          open: true,
          message: t(
            'content.authenticated.profile.edit.communication.dialogs.success.message'
          ),
        });
        navigate('/profile');
      })
      .then(() =>
        query_client.invalidateQueries('questions_responses', {
          'question.slug': 'is-communication-email-accepted',
        })
      );
  }, [is_submitted, selection]);

  const onChange = (selection) => setState({ selection, is_submitted: true });

  const current_response =
    selection != undefined ? selection : question_response?.response;

  return (
    <EmailCommunicationQuestion
      value={current_response}
      question={question}
      onChange={onChange}
    />
  );
};

const Header = () => (
  <DefaultHeader
    back_button
    overtitle={t(
      'content.authenticated.profile.edit.communication.header.overtitle'
    )}
    title={t('content.authenticated.profile.edit.communication.header.title')}
    scrolled={{
      text: t(
        'content.authenticated.profile.edit.password.email.scrolled.text'
      ),
    }}
  />
);

export default withTemplateCheckpoint(
  Communication,
  { inheritance: 'default' },
  { header: <Header /> }
);
