import { Router as BaseRouter } from '@weasyo/react';

import { Loader } from '~/src/components';
import Page404 from '~/src/content/error/404';

const NotFound = <Page404 path='*' />;
const BaseLoader = <Loader />;

/**
 * Add default "404" page and loader.
 */
export const Router = ({ children, routes, Loader }) => (
  <BaseRouter routes={[NotFound, routes]} Loader={Loader ?? BaseLoader}>
    {children}
  </BaseRouter>
);

export default Router;
