import { parsers } from '@weasyo/react';

/**
 * Populate {zones.children}.
 * TODO move this logical into API.
 */
export const fillZonesChildren = (zones) =>
  zones.map((zone) => {
    if (!zone.children) return zone;

    return {
      ...zone,
      children: zone.children
        .map((ref) => zones.find((zone) => parsers.getId(zone) === ref))
        .filter((x) => x),
    };
  });

export const getRandomSentence = ({
  type = 'global',
  progression,
  sentences,
}) => {
  let list;
  const part = sentences[type] ?? sentences['global'];

  Object.keys(part).forEach(
    (key) => (list = progression >= key ? part[key] : list)
  );

  return list[Math.floor(Math.random() * list.length)];
};
