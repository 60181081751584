{
  "src": [
    "./src/assets/audio/generated/fr/audio.mp3"
  ],
  "sprite": {
    "silence": [
      0,
      5000,
      true
    ],
    "bell_effect": [
      8000,
      1714.2857142857135
    ],
    "be_ready_sentence": [
      13000,
      2063.673469387755
    ],
    "countdown_sentence": [
      19000,
      2756.9841269841254
    ]
  }
}