import {
  Redirect,
  Router,
  device as device_data,
  useCore,
  useDevice,
  withTemplateCheckpoint,
} from '@weasyo/react';

import Demands from './demands/Routes';
import Confirm from './confirm/Routes';
import Apps from './apps/Routes';
import Logout from './Logout';

/**
 * !! Make sure to wrap route with "default" template.
 * This will prevent unexpected template update.
 */
const AppsWithDefaultTemplate = withTemplateCheckpoint(Apps, {
  inheritance: 'default',
});
const DemandsWithDefaultTemplate = withTemplateCheckpoint(Demands, {
  inheritance: 'default',
});
const LogoutWithDefaultTemplate = withTemplateCheckpoint(Logout, {
  inheritance: 'default',
});
const ConfirmWithDefaultTemplate = withTemplateCheckpoint(Confirm, {
  inheritance: 'default',
});

/**
 * Routes available for anybody, (authenticated or not).
 */
export const Routes = ({ children }) => {
  const [core] = useCore();
  const device = useDevice();
  let anyone_routes = [
    <LogoutWithDefaultTemplate path='/logout' key='/logout' />,
    <DemandsWithDefaultTemplate path='demands/*' key='demands/*' />,
    <ConfirmWithDefaultTemplate path='confirm/*' key='confirm/*' />,
    <AppsWithDefaultTemplate path='apps/*' key='apps/*' />,
  ];

  /**
   * Handles stores routes.
   * Use redirection on "/" & "/login" route on "production".
   */
  if (!core.container?.mode) {
    switch (device.os.name) {
      /**
       * Android redirection.
       */
      case device_data.os.android:
        // eslint-disable-next-line no-undef
        if ((core.web_app?.env ?? process.env.W_ENV) != 'prod') {
          break;
        }

        anyone_routes.push(
          <Redirect from='/' to='/apps/install/android' replace />,
          <Redirect from='login' to='/apps/install/android' replace />
        );

        break;

      /**
       * iOS redirection.
       */
      case device_data.os.ios:
        // eslint-disable-next-line no-undef
        if ((core.web_app?.env ?? process.env.W_ENV) != 'prod') {
          break;
        }

        anyone_routes.push(
          <Redirect from='/' to='/apps/install/ios' replace />,
          <Redirect from='login' to='/apps/install/ios' replace />
        );

        break;
    }
  }

  return <Router routes={anyone_routes}>{children}</Router>;
};

export default Routes;
