import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { t } from 'i18next';
import { Avatar, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  Share as ShareIcon,
  TimerOutlined as TimerIcon,
} from '@mui/icons-material';
import {
  BackNavBlocker,
  FlexBox,
  Svg,
  Typography,
  formatters,
  parsers,
  useAPI,
  useCommonState,
  useStateWrapper,
  useTemplate,
  Duration,
} from '@weasyo/react';
import {
  CaduceusIcon,
  DumbbellsIcon,
  ElasticIcon,
  MatIcon,
  TowelIcon,
} from '@weasyo/react/lib/images';

import {
  Block,
  CTAButton,
  Header,
  QuarterCircleIllustration,
  ResumeItem,
  Share,
  WorkoutExercisesList,
} from '~/src/components';
import { queries } from '~/src/utilities';
import professional_program_cover_url from '~/src/assets/images/pictures/professional_program_cover.webp';

const equipments_icons = {
  'ground sheet': MatIcon,
  elastic: ElasticIcon,
  towel: TowelIcon,
  dumbbell: DumbbellsIcon,
};

const Equipments = ({ program, patient_workout, sx, ...rest }) => {
  const rows = !patient_workout
    ? [null, null, null]
    : patient_workout.workout.equipments.filter((equipment) => !!equipment);

  if (rows.length == 0) return null;

  return (
    <FlexBox
      sx={[
        { alignItems: 'inherit', width: 1 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant='h3' color='midGrey' sx={{ textAlign: 'left' }}>
          {t(
            'content.authenticated.programs.patient-workout.equipments.first-text'
          )}
        </Typography>
      </Box>

      <FlexBox
        color='midGrey'
        sx={[
          {
            columnGap: 2,
            alignItems: 'flex-start',
            flexDirection: 'row',
            overflowX: 'auto',
            overflowY: 'overlay',
            scrollSnapType: 'x proximity',
            width: 1,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {rows.map((equipment, index) => (
          <Block
            key={index}
            color={program ? formatters.slugify(program.category) : 'midGrey'}
            value={
              equipment && (
                <Svg
                  component={equipments_icons[equipment.item]}
                  color='inherit'
                  width='3rem'
                />
              )
            }
            label={
              equipment?.item &&
              t(
                'content.authenticated.programs.patient-workout.equipments.bloc.label',
                { equipment: equipment?.item }
              )
            }
            sx={{ scrollSnapAlign: index == rows.length - 1 ? 'end' : 'start' }}
          />
        ))}
      </FlexBox>
    </FlexBox>
  );
};

const StartPatientWorkoutButton = ({
  patient_program,
  patient_program_step,
  patient_workout,
  ...rest
}) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(
      `/my-programs/${parsers.getId(
        patient_program,
        true
      )}/steps/${parsers.getId(
        patient_program_step,
        true
      )}/workouts/${parsers.getId(patient_workout, true)}/video`,
      { replace: true }
    );
  };

  return (
    <FlexBox width={1} {...rest}>
      <CTAButton
        disabled={!(patient_program && patient_program_step && patient_workout)}
        onClick={onClickHandler}
        data-purpose='navigate_to_patient_workout'
        label={t(
          'content.authenticated.programs.start-patient-workout-button.label'
        )}
      />
    </FlexBox>
  );
};

const Resume = ({
  patient_program,
  patient_program_step,
  patient_workout,
  ...rest
}) => {
  const program = patient_program?.program ?? null;

  let capsule_content = null;
  if (patient_program && patient_program_step && patient_workout) {
    // i18next-extract-mark-context-next-line ["default", "of-professional-program"]
    capsule_content = t(
      'content.authenticated.programs.patient-workout.resume.first-text',
      {
        position: patient_workout.position,
        patient_program_step_position: patient_program_step.position,
        patient_program_steps_length:
          patient_program.patient_program_steps.length,
        context: program?.professional ? 'of-professional-program' : 'default',
      }
    );
  }

  return (
    <ResumeItem
      program={program}
      hat_content={
        (program?.professional && (
          <Svg component={CaduceusIcon} height='90%' />
        )) || (
          <FlexBox>
            <Svg component={TimerIcon} fontSize='small' />

            <Typography
              component='span'
              size='20'
              placeholder='2em'
              sx={{ fontWeight: 'bold' }}
            >
              <Duration
                data={patient_workout?.workout.duration ?? {}}
                is_abbreviation
              />
            </Typography>
          </FlexBox>
        )
      }
      capsule_content={
        capsule_content ? (
          <FlexBox sx={{ px: 3 }}>
            <Typography
              variant='subtitle2'
              color='white'
              placeholder='10em'
              sx={{ textAlign: 'center' }}
            >
              {capsule_content}
            </Typography>
          </FlexBox>
        ) : null
      }
      {...rest}
    >
      <StartPatientWorkoutButton
        sx={{ mt: 2 }}
        {...{ patient_program, patient_program_step, patient_workout }}
      />
    </ResumeItem>
  );
};

const PatientWorkoutExercises = ({ patient_workout, color, sx, ...rest }) => (
  <FlexBox
    sx={[
      { alignItems: 'inherit', width: 1 },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
  >
    <Box sx={{ mb: 2 }}>
      <Typography variant='h3' color='midGrey' sx={{ textAlign: 'left' }}>
        {t(
          'content.authenticated.programs.patient-workout.patient-workout-exercises.first-text'
        )}
      </Typography>
    </Box>

    <WorkoutExercisesList
      with_tag={true}
      color={color}
      patient_workout={patient_workout}
    />
  </FlexBox>
);

export const PatientWorkout = () => {
  useCommonState({
    page_title: t('content.authenticated.programs.patient-workout.title'),
  });
  const { patient_program_id, patient_program_step_id, patient_workout_id } =
    useParams();
  const query_client = useQueryClient();
  const setTemplate = useTemplate();
  const { API } = useAPI();
  const [{ is_share_clicked }, setState] = useStateWrapper(
    useState({ is_share_clicked: false })
  );

  /**
   * Fetch {patient_program}.
   */
  const { data: patient_program } = useQuery(
    [queries.names.patient_programs, { id: patient_program_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({ path: 'patient/programs/${id}', data: { id } }).then(
        ({ data }) => data
      )
  );

  /**
   * Fetch {patient_workout}.
   */
  const { data: patient_workout } = useQuery(
    [queries.names.patient_workouts, { id: patient_workout_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({ path: 'patient/workouts/${id}', data: { id } }).then(
        ({ data }) => data
      )
  );

  /**
   * Fetch {patient_program_step}.
   */
  const { data: patient_program_step } = useQuery(
    [queries.names.patient_program_steps, { id: patient_program_step_id }],
    ({ queryKey: [, { id }] }) =>
      API.get({ path: 'patient/program_steps/${id}', data: { id } }).then(
        ({ data }) => data
      )
  );

  const program = patient_program?.program ?? null;

  const color = program ? formatters.slugify(program.category) : 'midGrey';

  /**
   * Pre-fetch {patient_program} related data.
   */
  useEffect(() => {
    queries.fetchPatientPrograms({
      API,
      query_client,
      patient_program: patient_program_id,
    });
  }, []);

  /**
   * Update template {header}.
   */
  useEffect(() => {
    if (!program) return;

    setTemplate(({ cover }) => ({
      cover: {
        ...cover,
        image: !program?.professional
          ? {
              src: program?.cover?.url,
              require_authentication: true,
            }
          : professional_program_cover_url,
        children: <QuarterCircleIllustration size={1.15} color={color} />,
      },
      header: (
        <Header
          back_button
          title={null}
          sx={{ zIndex: 'front_of_content', position: 'absolute', top: 0 }}
          color='white'
          border_color={color}
          extra={
            <IconButton
              color='inherit'
              data-purpose='share'
              onClick={() => setState({ is_share_clicked: true })}
            >
              <Avatar sx={{ backgroundColor: 'white.main', boxShadow: 1 }}>
                <Svg color='text.secondary' component={ShareIcon} />
              </Avatar>
            </IconButton>
          }
          scrolled={{
            color: 'text.primary',
            extra: (
              <IconButton
                color='inherit'
                data-purpose='share'
                onClick={() => setState({ is_share_clicked: true })}
              >
                <Svg color='text.secondary' component={ShareIcon} />
              </IconButton>
            ),
            children: <Typography trunc>{program.name}</Typography>,
          }}
        />
      ),
    }));
  }, [program?.['@id']]);

  return (
    <>
      <BackNavBlocker
        mode='deviation'
        path={`/my-programs/${patient_program_id}`}
      />

      {program && (
        <Share
          url={`${window.location.origin}/programs/${parsers.getId(
            program,
            true
          )}`}
          text={t('content.authenticated.programs.share.program.text')}
          title={t('content.authenticated.programs.share.program.title')}
          open={is_share_clicked}
          onEnd={() => setState({ is_share_clicked: false, open_pane: false })}
        />
      )}

      <Resume
        {...{ patient_program, patient_program_step, patient_workout }}
        sx={{ mb: 2 }}
      />

      <FlexBox sx={{ px: 2, width: 1, alignItems: 'flex-start' }}>
        <Equipments {...{ program, patient_workout }} sx={{ mb: 3 }} />

        <PatientWorkoutExercises
          patient_workout={patient_workout}
          color={color}
          sx={{ mb: 3 }}
        />

        <StartPatientWorkoutButton
          {...{ patient_program, patient_program_step, patient_workout }}
          sx={{ mb: 3 }}
        />
      </FlexBox>
    </>
  );
};

export default PatientWorkout;
