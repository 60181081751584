import { useEffect } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  CheckRounded as DoneIcon,
  StarRounded as StarIcon,
} from '@mui/icons-material';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from 'react-query';
import { t } from 'i18next';
import {
  CardItem,
  FlexBox,
  Svg,
  Typography,
  formatters,
  parsers,
  useAPI,
} from '@weasyo/react';
import { CaduceusIcon } from '@weasyo/react/lib/images';

import { Category, Capsule, ProgressBar } from '~/src/components';
import { queries } from '~/src/utilities';

export const PatientProgramCard = ({ patient_program, prefetch }) => {
  const { ref, inView: is_in_view } = useInView({ triggerOnce: true });
  const { API } = useAPI();
  const query_client = useQueryClient();
  const navigate = useNavigate();

  patient_program = is_in_view ? patient_program : undefined;
  const category_color = patient_program
    ? formatters.slugify(patient_program.program.category)
    : 'lightGrey';
  const status = patient_program?.status;
  const professional = patient_program?.program.professional;
  const has_progression = !professional;
  const finished_workouts = patient_program?.nb_patient_workouts_completed;
  const total_workouts = patient_program?.nb_patient_workouts;
  const total_workouts_todo_today =
    patient_program?.nb_patient_workouts_todo_today;
  const date_creation = professional
    ? format(new Date(patient_program?.date_creation), 'dd/MM/yyyy')
    : '';
  const progression = patient_program
    ? total_workouts > 0
      ? Math.round((finished_workouts / total_workouts) * 100)
      : 0
    : 0;

  /**
   * Prefetch related data.
   */
  useEffect(() => {
    if (!prefetch || !patient_program) return;

    queries.fetchPatientPrograms({ patient_program, query_client, API });
  }, [prefetch, patient_program]);

  /**
   * Define status sentence.
   */
  let status_string = patient_program
    ? // i18next-extract-disable-next-line
      t('components.patient-program-card.status', { context: 'completed' })
    : null;

  if (status === 'in progress') {
    status_string = t(
      'components.patient-program-card.nb-patient-workouts-to-do',
      { count: total_workouts_todo_today }
    );
  }

  return (
    <CardItem
      arrow='center'
      arrow_color={total_workouts_todo_today ? 'midGrey' : 'lightGrey'}
      color='lightGrey'
      data-purpose='navigate_to_patient_program'
      ref={ref}
      onClick={() =>
        patient_program &&
        navigate(`/my-programs/${parsers.getId(patient_program, true)}`)
      }
      sx={{
        borderRadius: 2,
        boxShadow: status != 'completed' && total_workouts_todo_today ? 2 : 0,
        border:
          status == 'completed' || !total_workouts_todo_today
            ? (theme) =>
                `${theme.spacing(0.1)} solid ${theme.palette.grey[300]}`
            : undefined,
        maxWidth: '23rem',
        minWidth: '21rem',
        width: 1,
      }}
      {...(professional ? { background: `${category_color}.light` } : {})}
    >
      <FlexBox
        color={'text.primary'}
        sx={{
          alignItems: 'start',
          height: 1,
          justifyContent: 'space-evenly',
          minHeight: '11rem',
          pl: 2,
          py: 2,
          width: 1,
        }}
      >
        <FlexBox sx={{ pb: 0.5, alignItems: 'inherit' }}>
          <Category name={patient_program?.program.category} />
        </FlexBox>

        {professional && (
          <>
            <FlexBox
              color='white'
              background={category_color}
              sx={{
                borderRadius: 2,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                height: '2.75rem',
                justifyContent: 'center',
                position: 'absolute',
                right: 0,
                top: 0,
                width: '2.75rem',
              }}
            >
              <Svg component={CaduceusIcon} height='75%' />
            </FlexBox>

            <FlexBox sx={{ py: 0.5, alignItems: 'inherit' }}>
              <Typography
                color={category_color}
                variant='body2'
                sx={{ fontWeight: 400, textAlign: 'left' }}
              >
                {/* i18next-extract-disable-next-line */}
                {t('components.patient-program-card.program.developed-by', {
                  jobs: (professional.jobs ?? ['professionnel de santé'])
                    // i18next-extract-disable-next-line
                    ?.map((job) => t('common.job', { context: job }))
                    .join(' / '),
                })}
              </Typography>
            </FlexBox>

            <FlexBox sx={{ py: 0.5, alignItems: 'inherit' }}>
              <Typography sx={{ textAlign: 'left' }} variant='caption'>
                {t('components.patient-program-card.program.creation', {
                  last_name: professional.last_name,
                  first_name: professional.first_name,
                  date_creation,
                })}
              </Typography>
            </FlexBox>
          </>
        )}

        <FlexBox sx={{ py: 0.5, alignItems: 'inherit' }}>
          <Typography
            variant='body2'
            placeholder='12rem'
            sx={{ fontWeight: 'bold', textAlign: 'left' }}
          >
            {patient_program?.program.name}
          </Typography>
        </FlexBox>

        <FlexBox
          color={category_color}
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            py: 0.5,
            width: 1,
          }}
        >
          {status == 'completed' ? (
            <FlexBox sx={{ flexDirection: 'row' }}>
              <Svg component={StarIcon} fontSize='small' />

              <FlexBox sx={{ ml: 1 }} color='text.primary'>
                <Typography variant='caption'>{status_string}</Typography>
              </FlexBox>
            </FlexBox>
          ) : (
            <Capsule
              sx={{ borderRadius: 100, py: 1, px: 2 }}
              border={0.5}
              background='white'
              color={
                total_workouts_todo_today == 0 ? 'lightGrey' : 'currentColor'
              }
            >
              <Typography
                sx={{ textAlign: 'center' }}
                variant='body2'
                placeholder='7rem'
              >
                {status_string}
              </Typography>
            </Capsule>
          )}

          {has_progression && (
            <FlexBox sx={{ flexDirection: 'row' }}>
              <Svg component={DoneIcon} fontSize='small' />

              <FlexBox sx={{ ml: 1 }} color='text.primary'>
                <Typography variant='caption' placeholder={'2rem'}>
                  {total_workouts !== undefined
                    ? `${finished_workouts} / ${total_workouts}`
                    : null}
                </Typography>
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>

        {has_progression && (
          <FlexBox sx={{ pt: 0.5, width: 1 }}>
            <ProgressBar color={category_color} percent={progression} />
          </FlexBox>
        )}
      </FlexBox>
    </CardItem>
  );
};

export default PatientProgramCard;
