import {
  Duration,
  FlexBox,
  Svg,
  Thumbnail,
  Typography,
  formatters,
  parsers,
  useAPI,
} from '@weasyo/react';
import { t } from 'i18next';
import { useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import {
  PlayCircleOutlineRounded as PlayIcon,
  TimerOutlined as TimerIcon,
} from '@mui/icons-material';

import { QuarterCircleIllustration, Tag } from '~/src/components';
import { queries } from '~/src/utilities';

export const ProgramCard = ({
  program,
  width = '18rem',
  height = '20.5rem',
  sx,
  ...rest
}) => {
  const { ref, inView: is_in_view } = useInView({ triggerOnce: true });
  program = is_in_view ? program : undefined;
  const query_client = useQueryClient();
  const { API } = useAPI();
  const navigate = useNavigate();
  const color = program ? formatters.slugify(program.category) : 'midGrey';
  const access_tags = program?.tags.filter((tag) => tag.type == 'access') ?? [
    null,
  ];
  const tags = program?.tags.filter(
    (tag) => ['rank', 'informative'].indexOf(tag.type) != -1
  ) ?? [null];

  const handleOnClick = async () => {
    /**
     * Pre-fetch related data.
     */
    queries.fetchPatientPrograms({
      query_client,
      API,
      program,
      last_in_progress: true,
    });

    navigate(`/programs/${parsers.getId(program, true)}`);
  };

  return (
    <Thumbnail
      data-purpose='navigate_to_program_details'
      image={{ src: program?.thumbnail.url, require_authentication: true }}
      onClick={program ? handleOnClick : undefined}
      ref={ref}
      sx={[
        {
          borderRadius: 2,
          boxShadow: 2,
          height: height,
          m: 0.5,
          maxHeight: height,
          maxWidth: width,
          minHeight: height,
          minWidth: width,
          width: width,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <QuarterCircleIllustration color={color} />

      <FlexBox sx={{ justifyContent: 'space-between', height: 1, width: 1 }}>
        <FlexBox sx={{ alignItems: 'flex-end', p: 1.5, width: 1 }}>
          {access_tags.map((tag, index) => (
            <Tag
              resource='program'
              key={index}
              tag={tag}
              sx={{ boxShadow: 1, mr: 1 }}
            />
          ))}
        </FlexBox>

        <FlexBox width={1}>
          <FlexBox
            sx={{ alignItems: 'flex-start', p: 1.5, width: 1 }}
            color='white'
            background='linear-gradient(
              0deg,
                rgba(0,0,0,0.20) 0%,
                rgba(0,0,0,0.15) 70%,
                rgba(0,0,0,0) 100%
            )'
          >
            <Typography
              sx={{ textAlign: 'left', color: 'inherit', fontWeight: 'medium' }}
              variant='h2'
              placeholder='13rem'
            >
              {program?.name}
            </Typography>

            <FlexBox
              sx={{ mt: 1, flexDirection: 'row', width: 1, columnGap: 1 }}
            >
              {tags.map((tag, index) => (
                <Tag resource='program' key={index} tag={tag} color={color} />
              ))}
            </FlexBox>
          </FlexBox>

          <FlexBox
            background={color}
            color='white'
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              px: 1.5,
              py: 1,
              width: 1,
            }}
          >
            <FlexBox sx={{ flexDirection: 'row' }}>
              <Svg component={PlayIcon} />

              <FlexBox ml={0.5}>
                <Typography
                  variant='caption'
                  placeholder={'3rem'}
                  color='inherit'
                >
                  {program
                    ? t('components.program-card.nb-workouts', {
                        nb: program.nb_workouts,
                      })
                    : null}
                </Typography>
              </FlexBox>
            </FlexBox>

            {(!program || program.duration) && (
              <FlexBox sx={{ flexDirection: 'row' }}>
                <Svg component={TimerIcon} />

                <FlexBox ml={0.5}>
                  <Typography
                    variant='caption'
                    placeholder={'3rem'}
                    color='inherit'
                  >
                    <Duration data={program?.duration ?? {}} is_abbreviation />
                  </Typography>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Thumbnail>
  );
};

export default ProgramCard;
