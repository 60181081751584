import { Box } from '@mui/material';
import parse from 'html-react-parser';

const default_style = {
  '*': { fontSize: '1rem' },
  '> ul, > ol': { p: 0, m: 0 },
  'ul li::before': {
    content: '"✔"',
    color: (theme) => theme.palette.primary.main,
    mr: 0.75,
  },
  'ul li': { listStyleType: 'none', display: 'flex', alignItems: 'center' },
  'ul li *': { p: 0, m: 0 },
  li: { textAlign: 'left' },
};

export const HtmlParser = ({ data, className, sx, ...options }) => (
  <Box
    className={className}
    sx={[default_style, ...(Array.isArray(sx) ? sx : [sx])]}
  >
    {parse(data, options)}
  </Box>
);

export default HtmlParser;
