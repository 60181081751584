import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import {
  Button,
  CardItem,
  FlexBox,
  Redirect,
  Svg,
  Typography,
  useSubscriptions,
  withSubscriptions,
} from '@weasyo/react';
import { WeasyoFullLogo } from '@weasyo/react/lib/images';

import FiestaIllustration from 'jsx:/src/assets/images/svg/illustrations/fiesta.svg';
import { Loader } from '~/src/components';
import { Trans } from 'react-i18next';

const IconWithText = ({ children }) => (
  <FlexBox sx={{ py: 3, flexDirection: 'row', columnGap: 1 }}>
    <Svg
      component={WeasyoFullLogo}
      viewBox='0 0 24 12'
      width='5.5rem'
      height='2.65rem'
      color='primary'
    />

    <Typography
      size={19}
      sx={{
        fontWeight: 'bold',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Typography>
  </FlexBox>
);

const Success = () => {
  const navigate = useNavigate();
  const { actives, is_fresh_data } = useSubscriptions();

  if (!is_fresh_data) return <Loader />;

  if (actives.length == 0) return <Redirect to='/404_not_found' replace />;

  return (
    <FlexBox sx={{ justifyContent: 'center' }}>
      <CardItem
        sx={{
          boxShadow: 1,
          backgroundColor: 'white.main',
          opacity: 0.9,
          p: 2,
          mb: 6,
        }}
      >
        <FlexBox>
          <Svg height='15vh' component={FiestaIllustration} />

          <FlexBox sx={{ my: 2 }}>
            <Typography variant='h2'>
              {t(
                'content.authenticated.profile.subscription.success.first-text'
              )}
            </Typography>
          </FlexBox>

          <Trans
            i18nKey='content.authenticated.profile.subscription.success.second-text'
            components={{
              C_A: <Typography gutterBottom />,
              C_B: <IconWithText />,
            }}
          />
        </FlexBox>
      </CardItem>

      <Button
        fullWidth
        type='submit'
        size='large'
        onClick={() => navigate('/my-programs', { replace: true })}
        label={t(
          'content.authenticated.profile.subscription.success.buttons.continue'
        )}
      />
    </FlexBox>
  );
};

export default withSubscriptions(Success, { fresh_data: true });
