import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import {
  CloseRounded as CloseIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
} from '@mui/icons-material';
import {
  parsers,
  useAPI,
  Thumbnail,
  Svg,
  FlexBox,
  VideoPlayer,
  BackNavBlocker,
} from '@weasyo/react';
import { IconButton } from '@mui/material';

export const ExerciseThumbnail = ({
  exercise,
  is_restricted,
  width = '9rem',
  height = '9rem',
  sx,
  ...rest
}) => {
  const { API } = useAPI();
  const navigate = useNavigate();
  const [display_player, displayPlayer] = useState();

  const handleOnClose = () => displayPlayer(false);

  const handleOnClick = () => {
    if (is_restricted) {
      navigate('/subscriptions');
      return;
    }

    displayPlayer(true);
  };

  /**
   * Fetch "explain exercise" video resource.
   */
  const { data: video_url } = useQuery(
    [
      'video',
      { id: display_player ? parsers.getId(exercise?.video, true) : null },
    ],
    ({ queryKey: [, { id }] }) =>
      id
        ? API.get({ path: 'media/${id}', data: { id } }).then(
            ({ data }) => data.url
          )
        : null,
    { enabled: display_player, staleTime: 50 * 60 * 1000 }
  );

  return (
    <>
      <Thumbnail
        color='white'
        image={{ src: exercise?.thumbnail?.url, require_authentication: true }}
        sx={[
          {
            maxHeight: height,
            maxWidth: width,
            minHeight: height,
            minWidth: width,
            width: width,
            boxShadow: 2,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...(is_restricted ? { layer_back: 'dark.light' } : {})}
        {...(exercise ? { onClick: handleOnClick } : {})}
        {...rest}
      >
        <Svg
          component={PlayCircleFilledIcon}
          width='35%'
          height='35%'
          sx={{ opacity: 0.9 }}
        />
      </Thumbnail>

      {video_url && display_player && (
        <VideoPlayer
          autoplay
          controls
          mode='fullscreen'
          onEnded={handleOnClose}
          sources={[video_url]}
        >
          <BackNavBlocker mode='disabled' onBackNav={handleOnClose} />

          <FlexBox
            color='white'
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 'front_of_content',
            }}
          >
            <IconButton
              color='inherit'
              aria-label='close'
              onClick={handleOnClose}
            >
              <Svg component={CloseIcon} fontSize='large' />
            </IconButton>
          </FlexBox>
        </VideoPlayer>
      )}
    </>
  );
};

export default ExerciseThumbnail;
