import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import {
  BackNavBlocker,
  Button,
  FlexBox,
  Svg,
  Typography,
  useTemplate,
  withTemplateCheckpoint,
} from '@weasyo/react';

import share_picture_url from '~/src/assets/images/pictures/share.webp';
import { Header, Share as ShareComponent } from '~/src/components';

const Share = () => {
  const { state: location_state } = useLocation();
  const setTemplate = useTemplate();
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();
  const handleOnClose = () => navigate(-1);

  useEffect(() => {
    setTemplate({
      header: (
        <Header
          extra={
            <IconButton aria-label='close' onClick={handleOnClose} size='large'>
              <Svg color='lightGrey' component={CloseIcon} width={'2rem'} />
            </IconButton>
          }
        />
      ),
    });
  }, []);

  return (
    <>
      {location_state?.next_path && (
        <BackNavBlocker mode='deviation' path={location_state.next_path} />
      )}

      <ShareComponent open={clicked} onEnd={handleOnClose} />

      <FlexBox
        sx={{
          my: 3,
          width: '100%',
          height: '40vh',
          backgroundImage: `url(${share_picture_url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      />

      <FlexBox
        sx={{
          flexGrow: 1,
          justifyContent: 'space-evenly',
          textAlign: 'center',
        }}
      >
        <Typography paragraph>
          {t('content.authenticated.share.first-text')}
        </Typography>

        <Typography size={18} paragraph>
          {t('content.authenticated.share.second-text')}
        </Typography>

        <Button
          sx={{ my: 2 }}
          aria-label='share'
          data-purpose='share'
          fullWidth
          size='large'
          onClick={() => setClicked(true)}
          label={t('content.authenticated.share.buttons.share.label')}
        />
      </FlexBox>
    </>
  );
};

export default withTemplateCheckpoint(
  Share,
  { inheritance: 'default', id: 'share' },
  { header: false }
);
