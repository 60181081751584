import { FlexBox, Svg, Typography, parsers, useAPI } from '@weasyo/react';
import LockIcon from '@mui/icons-material/Lock';
import { useQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';

import { ExerciseThumbnail, Tag } from './';

const Exercise = ({ row, with_tag, color, sx, ...rest }) => {
  const { ref, inView: is_in_view } = useInView({ triggerOnce: true });
  row = is_in_view ? row : undefined;

  return (
    <FlexBox
      ref={ref}
      sx={[{ alignItems: 'inherit' }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      <ExerciseThumbnail
        exercise={row?.exercise}
        is_restricted={row?.is_restricted ?? true}
      />

      <FlexBox sx={{ flexDirection: 'row', width: 1, mt: 0.5 }}>
        {(row?.is_restricted ?? true) && (
          <FlexBox sx={{ mr: 0.25 }}>
            <Svg component={LockIcon} width='1rem' color='midGrey' />
          </FlexBox>
        )}

        <Typography variant='caption' align='left' placeholder={'7rem'}>
          {row?.exercise?.name}
        </Typography>
      </FlexBox>

      {with_tag && row?.status == 'new' && (
        <Tag
          resource='exercise'
          tag={row ? { slug: 'new' } : null}
          color='white'
          background={color}
        />
      )}
    </FlexBox>
  );
};

export const WorkoutExercisesList = ({
  workout,
  patient_workout,
  with_tag,
  color,
  sx,
  ...rest
}) => {
  const { API } = useAPI();
  const { data: workout_exercises = [] } = useQuery(
    [
      'workout_exercises_of',
      { id: !patient_workout && workout ? parsers.getId(workout, true) : null },
    ],
    ({ queryKey: [, { id }] }) =>
      id
        ? API.get({
            path: 'workout_exercises',
            filters: { workout: id, itemsPerPage: '100' },
          }).then(({ data }) => data['hydra:member'])
        : null
  );

  const { data: patient_workout_exercises } = useQuery(
    [
      'patient_workout_exercises_of',
      {
        id:
          !workout && patient_workout
            ? parsers.getId(patient_workout, true)
            : null,
      },
    ],
    ({ queryKey: [, { id }] }) =>
      id
        ? API.get({
            path: 'patient/workout_exercises',
            filters: { patient_workout: id, itemsPerPage: '100' },
          }).then(({ data }) => data['hydra:member'])
        : null
  );

  /**
   * Avoid duplicate exercise.
   */
  let unique_data = {};

  (patient_workout_exercises ?? workout_exercises)?.forEach((row) => {
    unique_data[row.exercise['@id']] = row;
  });

  /**
   * Sort by {status}.
   */
  const rows = (
    Object.keys(unique_data).length ? Object.values(unique_data) : [{}, {}, {}]
  ).sort((a, b) => (a.status ? 0 : 1) - (b.status ? 0 : 1));

  return (
    <FlexBox
      sx={[
        {
          alignItems: 'inherit',
          overflowX: 'auto',
          overflowY: 'overlay',
          scrollSnapType: 'x proximity',
          width: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <FlexBox
        sx={{
          alignItems: 'inherit',
          columnGap: 1,
          flexDirection: 'row',
          overflowX: 'auto',
          overflowY: 'overlay',
          width: 1,
          zIndex: 'front_of_content',
        }}
      >
        {rows.map((row, index) => (
          <Exercise
            key={index}
            row={row}
            with_tag={with_tag}
            color={color}
            sx={{
              pl: index == 0 ? 0.4 : 0,
              pr: index == rows.length - 1 ? 0.4 : 0,
            }}
          />
        ))}
      </FlexBox>
    </FlexBox>
  );
};

export default WorkoutExercisesList;
