import { t } from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FlexBox,
  Typography,
  parsers,
  useAPI,
  useCommonState,
  useDialog,
  usePrefill,
} from '@weasyo/react';

import { FlexForm } from '~/src/components';

/**
 * Manage password reset.
 * - Display form
 * - Send email to reset password
 */
export const Password = () => {
  useCommonState({
    page_title: t('content.anyone.demands.password.title'),
  });
  const navigate = useNavigate();
  const { set: setPrefill, get: getPrefill } = usePrefill();
  const [, setDialog] = useDialog();
  const { API } = useAPI();

  const onSubmit = ({ email }, form) =>
    API.post({
      path: 'anonymous/demands/password',
      data: { email },
      onError: (api_response) => {
        /**
         * Set form errors messages otherwise keep throwing exception.
         */
        if (api_response?.error?.response?.status == 422) {
          const errors = parsers.violations({
            formik,
            violations: api_response.error.data?.violations,
            t,
          });

          if (
            errors.all.status?.data?.value ==
            '"pending_first_enabling_confirmation"'
          ) {
            setDialog({
              open: true,
              message: t(
                'content.anyone.demands.password.dialogs.account-pending-first-enabling-confirmation.message',
                { email }
              ),
              label: t(
                'content.anyone.demands.password.dialogs.account-pending-first-enabling-confirmation.label'
              ),
              onClick: () => {
                setDialog({ open: false });
                navigate('/demands/account/activation');
              },
            });

            return;
          }

          if (errors.all.email?.code == 'email-not-found') {
            setDialog({
              open: true,
              message: t(
                'content.anyone.demands.password.dialogs.email-not-found.message',
                { email }
              ),
            });

            return;
          }

          if (Object.entries(errors.out_scope).length == 0) return;
        }

        throw api_response;
      },
    })
      .then(() => {
        setDialog({
          open: true,
          message: t(
            'content.anyone.demands.password.dialogs.success.message',
            { email }
          ),
        });

        setPrefill({ identifier: '/login', data: { email } });
        navigate('/login');
      })
      .finally(() => form.setSubmitting(false));

  const validation_schema = Yup.object().shape({
    // i18next-extract-disable-next-line
    email: Yup.string().email().required(t('glossary.required')),
  });

  const formik = useFormik({
    onSubmit,
    validationSchema: validation_schema,
    initialValues: getPrefill({
      identifier: '/demands/password',
      keys: Object.keys(validation_schema.fields),
      initial: Object.fromEntries(
        Object.keys(validation_schema.fields).map((name) => [name, ''])
      ),
    }),
  });

  return (
    <>
      <Typography variant='h1' paragraph>
        {t('content.anyone.demands.password.first-text')}
      </Typography>

      <FlexBox>
        <Typography paragraph>
          {t('content.anyone.demands.password.second-text')}
        </Typography>

        <FlexForm sx={{ my: 2, width: 1 }} onSubmit={formik.handleSubmit}>
          <TextField
            id='email-input'
            autoComplete='email'
            /* i18next-extract-disable-next-line */
            label={t('glossary.email')}
            type='email'
            name='email'
            fullWidth
            margin='normal'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.email}
            error={formik.errors.email && formik.touched.email}
            value={formik.values.email}
          />

          <Button
            sx={{ my: 2 }}
            data-purpose='confirm'
            disabled={!formik.isValid || formik.isSubmitting}
            fullWidth
            size='large'
            type='submit'
            label={t(
              'content.anyone.demands.password.buttons.submit.label.is-in-progress',
              { context: formik.isSubmitting ? 'true' : 'false' }
            )}
          />
        </FlexForm>
      </FlexBox>
    </>
  );
};

export default Password;
