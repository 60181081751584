import { Typography, Svg, FlexBox } from '@weasyo/react';
import { Box } from '@mui/material';
import { t } from 'i18next';
import {
  StarRounded as StarIcon,
  NewReleases as FlashInfoIcon,
  Visibility as DiscoverIcon,
} from '@mui/icons-material';

import Capsule from './Capsule';

/**
 * Defines tag render depending on {tag.type}.
 */
const tagConfig = ({ tag }) => {
  const config = {
    access: {
      premium_access: {
        icon: {
          component: StarIcon,
          sx: { fill: (theme) => theme.palette.yellow.main },
        },
        capsule: {
          background: 'cta',
          color: 'white',
          sx: { pl: 0.5, pr: 1 },
        },
      },
      first_workout_free_access: {
        capsule: { background: 'cta', color: 'white' },
        icon: { component: DiscoverIcon },
      },
      _undefined: {
        capsule: { background: 'cta', color: 'white' },
        icon: { component: FlashInfoIcon },
      },
    },
  };

  const config_by_type = config[tag?.type] ?? {};

  return config_by_type[tag?.slug] ?? config_by_type['_undefined'] ?? {};
};

export const Tag = ({ tag, sx, resource, ...rest }) => {
  const config = tagConfig({ tag });

  return (
    <Capsule
      background={tag ? 'white' : 'midGrey'}
      {...(config.capsule ?? {})}
      sx={[{ borderRadius: 20 }, config.sx, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      <FlexBox
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: 'inherit',
          height: 'inherit',
        }}
      >
        {config.icon && (
          <Box sx={{ mr: 0.25 }}>
            <Svg height='1rem' {...config.icon} />
          </Box>
        )}

        <Typography
          variant='caption'
          sx={{ fontWeight: 'bold' }}
          placeholder='3rem'
        >
          {/* i18next-extract-disable-next-line */}
          {tag && t(`tags.${resource}`, { context: tag.slug, name: tag.name })}
        </Typography>
      </FlexBox>
    </Capsule>
  );
};

export default Tag;
