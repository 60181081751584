import { FlexBox } from '@weasyo/react';
import { useEffect, useRef } from 'react';

export const ProgressBar = ({ color, percent, sx, ...rest }) => {
  const ref = useRef();

  /**
   * Update width through JS instead of CSS, avoid useless amount of style creation.
   */
  useEffect(() => {
    if (!ref.current) return;

    ref.current.style.width = `${percent}%`;
  }, [percent]);

  return (
    <FlexBox
      background={`${color}.light`}
      sx={[
        { borderRadius: 0.5, height: '0.6rem', position: 'relative', width: 1 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <FlexBox
        ref={ref}
        background={color}
        sx={{
          borderRadius: 0.5,
          height: 'inherit',
          left: 0,
          position: 'absolute',
          top: 0,
          width: 0,
        }}
      />
    </FlexBox>
  );
};

export default ProgressBar;
