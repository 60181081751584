import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { Avatar, IconButton } from '@mui/material';
import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { t } from 'i18next';
import {
  BackNavBlocker,
  Button,
  DatePicker,
  FlexBox,
  Modal,
  PaginationBullets,
  Svg,
  Typography,
  parsers,
  useAPI,
  useStateWrapper,
  useTemplate,
  useCommonState,
  withTemplateCheckpoint,
} from '@weasyo/react';

import CompletedIllustration from 'jsx:/src/assets/images/svg/illustrations/completed.svg';

import {
  EmailCommunicationQuestion,
  Header,
  Loader,
  QuestionTemplate,
  RadioButtonsListQuestion,
} from '~/src/components';

const DateBirthQuestion = ({ onChange, value }) => {
  /**
   * Define max birth date to 15 years ago.
   */
  const max_birth_date = new Date();
  max_birth_date.setFullYear(new Date().getFullYear() - 15);

  return (
    <QuestionTemplate
      content={
        <Typography variant='h2' align='center'>
          {t('questions.birth-date.question')}
        </Typography>
      }
      form={
        <DatePicker
          label={t('glossary.birth-date')} //i18next-extract-disable-line
          onChange={onChange}
          value={value ?? max_birth_date}
          maxDate={max_birth_date}
          data-purpose='open_date_picker'
        />
      }
    />
  );
};

const QuestionsCompleted = ({ profile }) => (
  <QuestionTemplate
    content={
      <>
        <FlexBox sx={{ height: '35vh', justifyContent: 'center' }}>
          <Svg component={CompletedIllustration} height={1} />
        </FlexBox>

        <FlexBox sx={{ my: 3 }}>
          <Typography variant='h2' paragraph>
            {profile
              ? t(
                  'content.authenticated.onboarding.questions-completed.first-text',
                  { user_name: profile.first_name }
                )
              : null}
          </Typography>
        </FlexBox>

        <FlexBox sx={{ mb: 3 }}>
          <Typography sx={{ textAlign: 'center' }} paragraph>
            {t(
              'content.authenticated.onboarding.questions-completed.second-text'
            )}
          </Typography>
        </FlexBox>

        <Typography
          variant='caption'
          paragraph
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          {t('content.authenticated.onboarding.questions-completed.third-text')}
        </Typography>
      </>
    }
  />
);

/**
 * Expected questions.
 */
let expected_questions = [
  'gender',
  'birth-date',
  'sport-frequence-activity',
  'zone-painful',
  'is-onboarding-done',
  'is-communication-email-accepted',
];

const Onboarding = () => {
  const [{ keep_rgpd_thematic }, setCommonState] = useCommonState({
    page_title: t('content.authenticated.onboarding.title'),
  });
  const { step_id } = useParams();
  const navigate = useNavigate();
  const query_client = useQueryClient();
  const setTemplate = useTemplate();
  const { API } = useAPI();
  const step_id_ref = useRef(parseInt(step_id));
  const [{ step, steps, rgpd_responses, exit_modal }, setState] =
    useStateWrapper(
      useState({
        rgpd_responses: {},
        steps: [],
        exit_modal: {
          open: false,
          onClose: () => setState({ exit_modal: { open: false } }),
        },
      })
    );

  /**
   * Fetch {profile}.
   */
  const { data: profile, isLoading: is_loading_profile } = useQuery(
    ['profile'],
    () => API.get({ path: 'profile' }).then(({ data }) => data)
  );

  /**
   * Fetch {questions}.
   */
  const { data: questions, isLoading: is_loading_questions } = useQuery(
    ['questions', { slug: expected_questions }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions', filters })
        .then(({ data }) => data?.['hydra:member'])
        .then((question) => {
          expected_questions.forEach((expected_question) => {
            const match = question.find(
              ({ slug }) => slug == expected_question
            );

            if (match === undefined) {
              throw new Error(
                `Some required questions has not been retrieved (eq. "${expected_question}").`
              );
            }
          });

          return question;
        })
  );

  /**
   * Fetch {questions_responses}.
   */
  const {
    data: questions_responses,
    isLoading: is_loading_responses,
    isFetching: is_fetching_responses,
  } = useQuery(
    ['questions_responses', { 'question.slug': expected_questions }],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'questions_responses', filters }).then(
        ({ data }) => data?.['hydra:member']
      )
  );

  const is_loading =
    is_loading_profile || is_loading_questions || is_loading_responses;

  /**
   * Defines expected steps.
   */
  useEffect(() => {
    if (is_loading || is_fetching_responses) return;

    /**
     * Map {questions} by {slug}.
     */
    let mapped_questions = [];
    questions?.forEach((question) => {
      mapped_questions[question.slug] = question;
    });

    /**
     * Map {questions_responses} by {question.slug}.
     */
    let mapped_responses = [];
    questions_responses?.forEach(
      (response) =>
        (mapped_responses[response.question.slug] = response.response)
    );

    /**
     * Defines every possible steps.
     */
    let steps_thematics = {
      rgpd: {
        skip:
          mapped_responses['is-communication-email-accepted'] !== undefined &&
          !keep_rgpd_thematic,
        questions: [
          {
            key: 'is-communication-email-accepted',
            action: () =>
              API.post({
                path: 'questions_responses',
                data: {
                  question: parsers.getId(
                    mapped_questions['is-communication-email-accepted']
                  ),
                  response:
                    rgpd_responses['is-communication-email-accepted'] ?? 0,
                },
              }).then(() => {
                query_client.invalidateQueries('questions_responses');
                setCommonState({ keep_rgpd_thematic: true });
              }),
            Component: (
              <EmailCommunicationQuestion
                question={mapped_questions['is-communication-email-accepted']}
                value={
                  rgpd_responses['is-communication-email-accepted'] ??
                  mapped_responses['is-communication-email-accepted']
                }
                onChange={(response) => {
                  setState(({ rgpd_responses }) => {
                    rgpd_responses['is-communication-email-accepted'] =
                      response;

                    return { rgpd_responses };
                  });
                }}
              />
            ),
          },
        ],
      },
      onboarding: {
        skip: mapped_responses['is-onboarding-done'] == 1,
        questions: [
          {
            key: 'gender_question',
            response: profile?.gender,
            Component: (
              <RadioButtonsListQuestion
                value={profile?.gender}
                question={mapped_questions['gender']}
                onChange={(gender) =>
                  API.patch({ path: 'profile', data: { gender } })
                    .then(() =>
                      navigate(`/onboarding/${1 + step_id_ref.current}`)
                    )
                    .then(() => query_client.invalidateQueries('profile'))
                }
              />
            ),
          },
          {
            key: 'birth-date_question',
            response: profile?.date_birth,
            Component: (
              <DateBirthQuestion
                value={profile?.date_birth}
                onChange={(date) =>
                  API.patch({ path: 'profile', data: { date_birth: date } })
                    .then(() =>
                      navigate(`/onboarding/${1 + step_id_ref.current}`)
                    )
                    .then(() => query_client.invalidateQueries('profile'))
                }
              />
            ),
          },
          {
            key: 'sport-frequence-activity_question',
            response: mapped_responses['sport-frequence-activity'],
            Component: (
              <RadioButtonsListQuestion
                value={mapped_responses['sport-frequence-activity']}
                question={mapped_questions['sport-frequence-activity']}
                onChange={(level) => {
                  API.post({
                    path: 'questions_responses',
                    data: {
                      question: parsers.getId(
                        mapped_questions['sport-frequence-activity']
                      ),
                      response: level,
                    },
                  })
                    .then(() =>
                      navigate(`/onboarding/${1 + step_id_ref.current}`)
                    )
                    .then(() =>
                      query_client.invalidateQueries('questions_responses')
                    );
                }}
              />
            ),
          },
          {
            key: 'completed_step',
            Component: <QuestionsCompleted profile={profile} />,
          },
        ],
      },
    };

    /**
     * Defines expected steps.
     */
    let steps = [];
    Object.entries(steps_thematics).map(
      ([thematic, { skip, questions }]) =>
        !skip &&
        questions.map((question) => steps.push({ ...question, thematic }))
    );

    /**
     * Defines current {step}.
     */
    let current_step_id = parseInt(step_id);
    let step =
      steps?.find((step, index) => {
        if (
          (isNaN(current_step_id) && step.response === undefined) ||
          index === current_step_id
        ) {
          current_step_id = index;
          return true;
        }

        return false;
      }) ?? false;

    if (step) {
      step = {
        ...step,
        id: current_step_id,
        is_last: current_step_id == steps.length - 1,
      };
    }

    step_id_ref.current = step?.id;
    setState({ steps, step });
  }, [
    is_loading,
    is_fetching_responses,
    step_id,
    JSON.stringify(rgpd_responses),
  ]);

  /**
   * Updates template depending on current step.
   */
  useEffect(() => {
    if (!step) {
      setTemplate({ footer: undefined });
      return;
    }

    const close_button =
      !step.is_last && step.thematic != 'rgpd' ? (
        <IconButton
          aria-label='close'
          color='inherit'
          onClick={() => setState({ exit_modal: { open: true } })}
        >
          <Avatar sx={{ backgroundColor: 'white.main', boxShadow: 1 }}>
            <Svg color='text.secondary' component={CloseIcon} />
          </Avatar>
        </IconButton>
      ) : null;

    const handleOnClick =
      !step.action && step.response === undefined && !step.is_last
        ? undefined
        : () => {
            Promise.resolve()
              .then(() => {
                /**
                 * Call {action} if defined.
                 */
                if (step.action) return step.action();
              })
              .then(() => {
                /**
                 * Define "onboarding" as completed.
                 */
                if (step.is_last) {
                  /**
                   * Map {questions} by {slug}.
                   */
                  let mapped_questions = [];
                  questions?.forEach((question) => {
                    mapped_questions[question.slug] = question;
                  });

                  return API.post({
                    path: 'questions_responses',
                    data: {
                      response: 1,
                      question: parsers.getId(
                        mapped_questions['is-onboarding-done']
                      ),
                    },
                  }).then(() => {
                    setCommonState({ keep_rgpd_thematic: null });

                    query_client.invalidateQueries('questions_responses');
                  });
                }

                navigate(`/onboarding/${1 + step.id}`);
              });
          };

    setTemplate({
      header: <Header back_button={step.id != 0} extra={close_button} />,
      footer: (
        <FlexBox sx={{ width: 1, my: 2 }}>
          <Button
            data-purpose='navigate_to_next_question'
            disabled={!handleOnClick}
            fullWidth
            onClick={handleOnClick}
            label={
              // i18next-extract-mark-context-next-line ["confirm-rgpd", "start", "continue"]
              t('content.authenticated.onboarding.footer.buttons.next.label', {
                context:
                  step.thematic == 'rgpd'
                    ? 'confirm-rgpd'
                    : step.is_last
                    ? 'start'
                    : 'continue',
              })
            }
          />

          <FlexBox sx={{ mt: 2 }}>
            {!step.is_last && steps.length > 1 && (
              <PaginationBullets
                length={steps.length}
                current_index={step.id}
                radius='0.5rem'
              />
            )}
          </FlexBox>
        </FlexBox>
      ),
    });
  }, [step?.key, step?.response]);

  /**
   * Updates "exit" modal content depending on {step.thematic}".
   */
  useEffect(() => {
    if (!step) return;

    if (step.thematic == 'rgpd') {
      setState({
        exit_modal: {
          title: t('modals.quit-rgpd.title'),
          description: t('modals.quit-rgpd.description'),
          sub_description: t('modals.quit-rgpd.sub-description'),
          primary: {
            label: t('modals.quit-rgpd.primary.label'),
            onClick: () => setState({ exit_modal: { open: false } }),
          },
          secondary: undefined,
        },
      });
      return;
    }

    setState({
      exit_modal: {
        title: t('modals.quit-onboarding.title'),
        description: t('modals.quit-onboarding.description'),
        sub_description: t('modals.quit-onboarding.sub-description'),
        primary: {
          label: t('modals.quit-onboarding.primary.label'),
          onClick: () => setState({ exit_modal: { open: false } }),
        },
        secondary: {
          label: t('modals.quit-onboarding.secondary.label'),
          onClick: () => {
            /**
             * Map {questions} by {slug}.
             */
            let mapped_questions = [];
            questions?.forEach((question) => {
              mapped_questions[question.slug] = question;
            });

            /**
             * Define "onboarding" as completed.
             */
            API.post({
              path: 'questions_responses',
              data: {
                response: 1,
                question: parsers.getId(mapped_questions['is-onboarding-done']),
              },
            }).then(() =>
              query_client.invalidateQueries('questions_responses')
            );
          },
        },
      },
    });
  }, [step?.thematic]);

  /**
   * Render a "loader" during "step definition" logical.
   */
  if (step === undefined) return <Loader />;

  /**
   * Redirects to "home" if there is no one step to display.
   */
  if (!step) return <Navigate to='/' replace />;

  /**
   * Redirects to the expected step.
   */
  if (step_id_ref.current !== step.id) {
    return <Navigate to={`/onboarding/${step.id}`} replace />;
  }

  return (
    <>
      {step.id == 0 && (
        <BackNavBlocker
          mode='disabled'
          re_apply={!exit_modal.open}
          onBackNav={() => setState({ exit_modal: { open: true } })}
        />
      )}

      {step.id > 0 && (
        <BackNavBlocker
          mode='deviation'
          re_apply={true}
          path={`/onboarding/${step.id - 1}`}
        />
      )}

      <Modal {...exit_modal} />

      {step.Component}
    </>
  );
};

export default withTemplateCheckpoint(Onboarding, { inheritance: 'default' });
