import { useURLParams } from '@weasyo/react';

export const AppStoreRedirect = () => {
  const {
    params: { ct, pt, mt },
  } = useURLParams();
  const trackers = { ct, pt, mt };
  const url = new URL('https://apps.apple.com/fr/app/weasyo/id1552901420');

  Object.keys(trackers).map((key) => {
    trackers[key] && url.searchParams.set(key, trackers[key]);
  });

  window.location.replace(url.toString());

  return null;
};

export default AppStoreRedirect;
