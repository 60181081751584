import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { IconButton } from '@mui/material';
import {
  CloseRounded as CloseIcon,
  CheckRounded as CheckIcon,
} from '@mui/icons-material';
import {
  BackNavBlocker,
  EmailToButton,
  FlexBox,
  Modal,
  Svg,
  Typography,
  VideoPlayer,
  useAPI,
  useCommonState,
  useProduct,
  useSubscriptions,
  useTemplate,
} from '@weasyo/react';

import {
  ExternalSubscriptionCard,
  Header,
  HtmlParser,
  SubscriptionProduct,
} from '~/src/components';
import { version as web_app_version } from '~/package.json';

import FiestaIllustration from 'jsx:/src/assets/images/svg/illustrations/fiesta.svg';
import YogaManGreenIllustration from 'jsx:/src/assets/images/svg/illustrations/yoga_man_green.svg';
import StarsIllustration from 'jsx:/src/assets/images/svg/illustrations/stars.svg';
import SmileyWowIllustration from 'jsx:/src/assets/images/svg/illustrations/smiley_wow.svg';
import MeditationWomanIllustration from 'jsx:/src/assets/images/svg/illustrations/meditation_woman.svg';

/**
 * Callback URIs used by "product" provider as responses endpoints.
 */
const callbackURIs = (product) => ({
  // TODO fix {window.location.search} handling on container {iOS, Android} side.
  // failed_uri: `/subscriptions${window.location.search}`,
  // success_uri: `/subscriptions/${product}/purchase/handle${window.location.search}`,
  // check_uri: `/subscriptions/${product}/purchase/check_permission${window.location.search}`,
  success_uri: `/subscriptions/${product}/purchase/handle`,
  check_uri: `/subscriptions/${product}/purchase/check_permission`,
});

const List = () => {
  const { i18n, t } = useTranslation();
  useCommonState({
    page_title: t('content.authenticated.subscriptions.list.title'),
  });
  /**
   * TODO: Use {localStorage.data} logical only, instead of {data} URL parameter.
   * !! {data} doesn't respect http max length.
   * !! It currently works, thanks to Service Worker that catch the request.
   * !! Its a trick until Android app handle {localStorage} (cf require Webview instead of TWA).
   */
  const { API } = useAPI();
  const navigate = useNavigate();
  const setTemplate = useTemplate();
  const { product, action, data } = useParams();
  const { state: location_state } = useLocation();
  const [product_ctx, productDispatch] = useProduct();
  const [modal, setModal] = useState({ open: false });
  const {
    is_loading,
    internals: internal_subscriptions,
    matching_platform: matching_platform_subscriptions,
  } = useSubscriptions();

  /**
   * TODO add new {product.resolved_translation} prop logical into {useSubscriptions}.
   * Retrieve data related to first "highlighted subscription" matching current language.
   */
  const first_highlighted_subscription_data = (
    matching_platform_subscriptions?.[0]?.product.translations ?? []
  ).find(({ language }) => language.code_alpha_2 === i18n.resolvedLanguage);

  // TODO: Prefetch this query from "referrer places" to fast play purpose.
  const { data: teaser_video_url } = useQuery(
    [
      'media',
      { category: 'teaser_weasyo_app_subscription_video', itemsPerPage: 1 },
    ],
    ({ queryKey: [, filters] }) =>
      API.get({ path: 'media', filters }).then(
        ({ data }) => data['hydra:member']?.[0]?.url
      )
  );

  /**
   * TODO Remove when {data} URL parameter will be deprecated for localStorage.
   * Removes {data} from the URL.
   */
  useEffect(() => {
    if (!data) return;

    productDispatch('store_data', { data });
    window.history.replaceState(
      null,
      null,
      `/subscriptions/${product}/purchase/${action}${window.location.search}`
    );
  }, []);

  /**
   * Dispatches action.
   */
  useEffect(() => {
    if (!action || !product || !product_ctx.data || product_ctx.error) return;

    switch (action) {
      case 'check_permission':
        productDispatch('check_permission', {
          product,
          callback_uris: callbackURIs(product),
        });
        break;

      case 'handle':
        productDispatch('purchase', { product });
        break;
    }
  }, [action, product_ctx.data]);

  /**
   * Updates header video player.
   */
  useEffect(() => {
    setTemplate(({ cover }) => ({
      cover: {
        ...cover,
        children: teaser_video_url && (
          <VideoPlayer
            autoplay='muted'
            fluid
            loader={false}
            loop
            muted
            size='cover'
            sources={[teaser_video_url]}
          />
        ),
      },
      header: (
        <Header
          sx={{ position: 'absolute', top: 0 }}
          title={null}
          extra={
            <IconButton aria-label='close' onClick={() => navigate(-1)}>
              <Svg
                color='text.secondary'
                component={CloseIcon}
                width={'2rem'}
              />
            </IconButton>
          }
        />
      ),
    }));
  }, [teaser_video_url]);

  /**
   * Display a modal if no one subscription is available.
   */
  useEffect(() => {
    if (is_loading) return;

    if (
      !matching_platform_subscriptions.length &&
      !internal_subscriptions.length
    ) {
      setModal({
        open: true,
        disable_nav_blocker: true,
        onClose: () => navigate(-1),
        title: t('modals.no-one-available-subscription.title'),
        secondary: {
          label: t('modals.no-one-available-subscription.secondary.label'),
          onClick: () => navigate(-1),
        },
        primary: (
          <EmailToButton
            color='primary'
            fullWidth
            variant='text'
            subject={t(
              'emails.to-support.no-one-available-subscription.subject'
            )}
            context={t(
              'emails.to-support.no-one-available-subscription.context'
            )}
            label={t('emails.to-support.no-one-available-subscription.label')}
            sx={{ p: 2 }}
          />
        ),
        description: (
          <>
            <Trans
              i18nKey='modals.no-one-available-subscription.description'
              components={{
                C_A: (
                  <Typography
                    sx={{ textAlign: 'center' }}
                    variant='body2'
                    paragraph
                  />
                ),
                C_B: (
                  <Typography
                    sx={{ textAlign: 'center' }}
                    variant='body2'
                    gutterBottom
                  />
                ),
                button: (
                  <FlexBox color='text.primary'>
                    <EmailToButton
                      color='inherit'
                      variant='text'
                      subject={t(
                        'emails.to-support.no-one-available-subscription.subject'
                      )}
                      context={t(
                        'emails.to-support.no-one-available-subscription.context'
                      )}
                    />
                  </FlexBox>
                ),
              }}
            />
          </>
        ),
      });
    }
  }, [is_loading, matching_platform_subscriptions, internal_subscriptions]);

  /**
   * Handle subscription success.
   */
  if (product_ctx.success && product_ctx.purchase) {
    return <Navigate to='/profile/subscription/purchase/success' replace />;
  }

  /**
   * List subscriptions.
   */
  return (
    <>
      <Modal {...modal} />

      {location_state?.next_path && (
        <BackNavBlocker mode='deviation' path={location_state.next_path} />
      )}

      <FlexBox background='primary' sx={{ width: 1 }}>
        <Typography
          color='white'
          size={22}
          placeholder='70%'
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {is_loading
            ? null
            : t('content.authenticated.subscriptions.list.first-text', {
                count:
                  first_highlighted_subscription_data?.pricing
                    .days_trial_period,
              })}
        </Typography>
      </FlexBox>

      <FlexBox
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          mt: 2.5,
          width: 1,
        }}
      >
        <FlexBox
          sx={{
            flexDirection: 'row',
            height: '6rem',
            maxWidth: '11rem',
            position: 'relative',
            width: 1,
          }}
        >
          <FlexBox
            sx={{
              position: 'absolute',
              top: 0,
              right: '40%',
              transform: 'rotate(-10deg)',
            }}
          >
            <Svg component={StarsIllustration} height={'2.5rem'} />
          </FlexBox>

          <FlexBox
            sx={{
              position: 'absolute',
              top: '30%',
              right: '5%',
              transform: 'rotate(-10deg)',
            }}
          >
            <Svg component={MeditationWomanIllustration} height={'2.5rem'} />
          </FlexBox>

          <FlexBox
            sx={{
              position: 'absolute',
              bottom: 0,
              left: '5%',
              transform: 'rotate(18deg)',
            }}
          >
            <Svg component={FiestaIllustration} height={'3rem'} />
          </FlexBox>
        </FlexBox>

        <FlexBox sx={{ mx: 1, alignSelf: 'flex-start' }}>
          {is_loading ||
          first_highlighted_subscription_data?.description?.slogan ? (
            <Typography
              sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
              size={30}
              color='primary'
              placeholder='8rem'
            >
              {first_highlighted_subscription_data?.description?.slogan}
            </Typography>
          ) : (
            <Trans
              i18nKey='content.authenticated.subscriptions.list.second-text'
              components={{
                C_A: <Typography size={22} />,
                C_B: (
                  <Typography
                    sx={{ fontWeight: 900 }}
                    size={34}
                    line_height={34}
                    color='primary'
                  />
                ),
              }}
            />
          )}
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: 'row',
            height: '6rem',
            maxWidth: '11rem',
            position: 'relative',
            width: 1,
          }}
        >
          <FlexBox
            sx={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'rotate(15deg)',
            }}
          >
            <Svg component={StarsIllustration} height={'2.5rem'} />
          </FlexBox>

          <FlexBox
            sx={{
              position: 'absolute',
              top: '30%',
              left: '5%',
              transform: 'rotate(5deg)',
            }}
          >
            <Svg component={SmileyWowIllustration} height={'3rem'} />
          </FlexBox>

          <FlexBox
            sx={{
              position: 'absolute',
              bottom: 0,
              right: '5%',
              transform: 'rotate(-10deg)',
            }}
          >
            <Svg component={YogaManGreenIllustration} height={'2.5rem'} />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox sx={{ mb: 2 }}>
        <FlexBox sx={{ alignItems: 'self-start', width: 1 }}>
          {first_highlighted_subscription_data?.description?.resume ? (
            <HtmlParser
              data={first_highlighted_subscription_data?.description?.resume}
              sx={{
                '& li *': {
                  textTransform: 'uppercase',
                  fontSize: 12,
                  fontWeight: 'bold',
                },
              }}
            />
          ) : (
            t('content.authenticated.subscriptions.list.third-text', {
              returnObjects: true,
            }).map((sentence, index) => (
              <FlexBox sx={{ flexDirection: 'row' }} key={index}>
                <Box sx={{ mr: 1 }}>
                  <Svg
                    component={CheckIcon}
                    width='1rem'
                    color='primary'
                    stroke_width={3}
                  />
                </Box>

                <Typography
                  sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  letter_spacing={0.5}
                  size={12}
                  placeholder={`calc(10rem * ${index / 10 + 1})`}
                >
                  {is_loading ? null : sentence}
                </Typography>
              </FlexBox>
            ))
          )}
        </FlexBox>
      </FlexBox>

      <FlexBox sx={{ flexGrow: 1, width: 1, px: 2 }}>
        <FlexBox sx={{ flexGrow: 1, width: 1, mb: 2 }}>
          <FlexBox
            sx={{
              flexGrow: 1,
              width: 1,
              maxWidth: '25rem',
              justifyContent: 'flex-start',
            }}
          >
            {(matching_platform_subscriptions ?? [{}, {}]).map(
              (subscription, index) => (
                <SubscriptionProduct
                  key={index}
                  highlighted={index == 0}
                  sx={[{ mb: 2, width: 1 }, index == 0 && { boxShadow: 3 }]}
                  callback_uris={
                    subscription.product &&
                    callbackURIs(subscription.product.slug)
                  }
                  subscription={subscription}
                />
              )
            )}

            {(internal_subscriptions?.length ?? 0) > 0 && (
              <ExternalSubscriptionCard
                highlighted={!matching_platform_subscriptions?.length}
                sx={
                  matching_platform_subscriptions?.length == 0 && {
                    boxShadow: 3,
                  }
                }
              />
            )}
          </FlexBox>
        </FlexBox>

        <FlexBox sx={{ mt: 3, mb: 2 }}>
          <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
            {t('content.authenticated.subscriptions.list.fourth-text')}
          </Typography>
        </FlexBox>

        <FlexBox mb={1}>
          <Typography variant='h6' color='text.secondary'>
            <Trans
              i18nKey='content.authenticated.subscriptions.list.fifth-text'
              context={
                first_highlighted_subscription_data?.pricing
                  ?.days_trial_period && 'with-trial-period'
              }
              values={{
                product_name:
                  first_highlighted_subscription_data?.description.name,
                nb_days_trial_period:
                  first_highlighted_subscription_data?.pricing
                    ?.days_trial_period,
              }}
              components={{
                terms_of_service_link: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    data-purpose='navigate_to_terms_of_service'
                    href={`${window.location.origin}/${web_app_version}/docs/terms_of_service.pdf`}
                    rel='noreferrer'
                    target='_blank'
                  />
                ),
                privacy_policy_link: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    data-purpose='navigate_to_privacy_policy'
                    href={`${window.location.origin}/${web_app_version}/docs/privacy_policy.pdf`}
                    rel='noreferrer'
                    target='_blank'
                  />
                ),
                product_name: (
                  <Typography
                    variant='inherit'
                    component='span'
                    sx={{ textTransform: 'capitalize' }}
                  />
                ),
              }}
            />
          </Typography>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default List;
