import { useCommonState } from '@weasyo/react';
import { t } from 'i18next';

import { Router } from '~/src/components';
import Communication from './Communication';
import Email from './Email';
import Home from './Index';
import PainZone from './PainZone';
import Password from './Password';
import PhysicalActivity from './PhysicalActivity';

export const Routes = () => {
  useCommonState({
    page_title: t('content.authenticated.profile.edit.routes.title'),
  });

  return (
    <Router>
      <Communication path='communication' />
      <Email path='email' />
      <Home path='/' />
      <PainZone path='pain-zone' />
      <Password path='password' />
      <PhysicalActivity path='physical-activity' />
    </Router>
  );
};

export default Routes;
