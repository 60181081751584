import { useEffect, useRef, useState } from 'react';
import { FlexBox, Svg, parsers } from '@weasyo/react';
import { IconButton, Typography, ButtonBase } from '@mui/material';
import {
  VolumeUpRounded as VolumeUpIcon,
  VolumeOffRounded as VolumeOffIcon,
  NavigateBeforeRounded as NavigateBeforeIcon,
  NavigateNextRounded as NavigateNextIcon,
  PlayArrowRounded as PlayIcon,
  PauseOutlined as PauseIcon,
} from '@mui/icons-material';

import { Capsule } from '~/src/components';
import { TopButtons } from './Dashboard';

export const Menu = ({
  capsule_content,
  delay = 3500,
  is_pause_on,
  is_sound_on,
  onNext,
  onPrevious,
  onToggleOpen,
  onTogglePlay,
  onToggleSoundOn,
  onQuit,
  onHelp,
  open,
  sx,
  ...rest
}) => {
  const layer_ref = useRef();
  const play_button_ref = useRef();
  const timer = useRef();
  const [is_displayed, isDisplayed] = useState(open ?? false);

  const handleOnTouch = (event) => {
    event && event.stopPropagation();
    isDisplayed((is_displayed) => !is_displayed);
  };

  const handleOnTogglePlay = (event) => {
    event && event.stopPropagation();
    resetTimer();
    onTogglePlay && onTogglePlay({ is_pause_on });
  };

  const handleOnToggleSoundOn = (event) => {
    event && event.stopPropagation();
    resetTimer();
    onToggleSoundOn && onToggleSoundOn({ is_sound_on });
  };

  const handleOnPrevious = (event) => {
    event && event.stopPropagation();
    resetTimer();
    onPrevious && onPrevious();
  };

  const handleOnNext = (event) => {
    event && event.stopPropagation();
    resetTimer();
    onNext && onNext();
  };

  const handleOnQuit = (event) => {
    event && event.stopPropagation();
    onQuit?.();
    isDisplayed(false);
  };

  const handleOnHelp = (event) => {
    event && event.stopPropagation();
    onHelp?.();
  };
  /**
   * Reset timer before "hiding".
   */
  const resetTimer = () => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      timer.current = null;

      isDisplayed(false);
    }, delay);
  };

  /**
   * TODO handle "escape" event.
   * Handle "open" on demand.
   */
  useEffect(() => {
    if (open == is_displayed) return;

    isDisplayed(open);
  }, [open]);

  /**
   * Focus elements.
   */
  useEffect(() => {
    if (!is_displayed) layer_ref.current?.focus();

    if (is_displayed) play_button_ref.current?.focus();
  }, [is_displayed, layer_ref.current, play_button_ref.current]);

  /**
   * Init/remove timer depending on "visibility".
   */
  useEffect(() => {
    is_displayed != open && onToggleOpen && onToggleOpen(is_displayed);

    if (!is_displayed) {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }

      return;
    }

    resetTimer();
  }, [is_displayed]);

  return (
    <ButtonBase
      ref={layer_ref}
      component={FlexBox}
      disableRipple={true}
      onClick={handleOnTouch}
      sx={{
        background: (theme) =>
          is_displayed ? theme.palette.dark.light : 'rgba(255, 255, 255, 0)',
        position: 'absolute',
        width: 1,
        height: 1,
        ...(Array.isArray(sx) ? sx : [sx]),
      }}
      {...rest}
    >
      {is_displayed && (
        <>
          <TopButtons
            zIndex={'front_of_content'}
            onHelp={handleOnHelp}
            onClose={handleOnQuit}
            sx={{ p: 1.5 }}
            with_text
          />

          <FlexBox sx={{ flexBasis: 'calc(1/3 * 100%)', pt: 2 }}>
            <Capsule
              sx={{
                borderRadius: 20,
                width: '10rem',
                py: 1,
                backgroundColor: (theme) =>
                  parsers
                    .theme(theme)
                    .palette.get({ path: 'midGrey', as_object: true })
                    .alpha(0.8)
                    .toString(),
              }}
            >
              <Typography>{capsule_content}</Typography>
            </Capsule>
          </FlexBox>

          <FlexBox
            sx={{
              alignItems: 'space-between',
              flexBasis: 'calc(1/3 * 100%)',
              flexDirection: 'row',
              width: 1,
            }}
          >
            <FlexBox
              sx={{
                alignItems: 'flex-start',
                flexBasis: '100%',
                justifyContent: 'center',
                pl: 2,
              }}
            >
              {onPrevious && (
                <IconButton
                  data-purpose='go_to_previous'
                  onClick={handleOnPrevious}
                >
                  <Svg component={NavigateBeforeIcon} width={'3rem'} />
                </IconButton>
              )}
            </FlexBox>

            <FlexBox sx={{ flexBasis: 1, justifyContent: 'center' }}>
              <IconButton
                ref={play_button_ref}
                data-purpose='toggle_play'
                onClick={handleOnTogglePlay}
              >
                <Svg
                  component={is_pause_on ? PlayIcon : PauseIcon}
                  width={'3rem'}
                />
              </IconButton>
            </FlexBox>

            <FlexBox
              sx={{
                alignItems: 'flex-end',
                flexBasis: '100%',
                justifyContent: 'center',
                pr: 2,
              }}
            >
              {onNext && (
                <IconButton data-purpose='go_to_next' onClick={handleOnNext}>
                  <Svg component={NavigateNextIcon} width={'3rem'} />
                </IconButton>
              )}
            </FlexBox>
          </FlexBox>

          <FlexBox
            sx={{
              flexBasis: 'calc(1/3 * 100%)',
              justifyContent: 'flex-end',
              pb: 2,
            }}
          >
            <IconButton
              data-purpose='toggle_sound'
              onClick={handleOnToggleSoundOn}
            >
              <Svg
                component={is_sound_on ? VolumeUpIcon : VolumeOffIcon}
                fontSize='large'
              />
            </IconButton>
          </FlexBox>
        </>
      )}
    </ButtonBase>
  );
};

export default Menu;
