import { Router } from '~/src/components';
import Professional from './professional/Index';

export const Routes = () => (
  <Router>
    <Professional id='professional/:email/:token' />
  </Router>
);

export default Routes;
