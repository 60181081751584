import { css } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { withTemplateCheckpoint, BackNavBlocker } from '@weasyo/react';

import { BodySelect, Header as DefaultHeader } from '~/src/components';

const Index = () => {
  const navigate = useNavigate();
  const { parent_zone_slug } = useParams();
  const setParentZone = (zone) => navigate(`/zones/${zone.slug}`);
  const setSelectedZone = (zone) =>
    navigate(`/zones/${parent_zone_slug}/${zone.slug}`);

  return (
    <>
      <BackNavBlocker
        re_apply={!parent_zone_slug}
        mode='deviation'
        path={parent_zone_slug ? '/zones' : '/'}
      />

      <BodySelect
        selected_parent_zone={parent_zone_slug}
        onZoneChange={setSelectedZone}
        onParentZoneChange={setParentZone}
      />
    </>
  );
};

const Header = () => (
  <DefaultHeader
    back_button
    title={t('content.authenticated.zones.index.header.title')}
    subtitle={t('content.authenticated.zones.index.header.subtitle')}
    scrolled={{
      text: t('content.authenticated.zones.index.header.scrolled.text'),
    }}
  />
);

export default withTemplateCheckpoint(
  Index,
  { inheritance: 'default' },
  {
    header: <Header />,
    page_style: (theme) => css`
      background-color: ${theme.palette.health.light};
    `,
    content_style: (theme) => css`
      background-color: ${theme.palette.health.light};
    `,
  }
);
